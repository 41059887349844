import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';

import Home from './modules/survey/Home';
import Results from './modules/survey/results/Results';

function App() {
  return (
    <NotificationsProvider position="top-right">
      <Router>
        <Routes>
          {/* <Route path="/about">
          <About />
        </Route> */}

          <Route path="/" element={<Home />} />
          <Route path="/results" element={<Results />} />
        </Routes>
      </Router>
    </NotificationsProvider>
  );
}

export default App;
