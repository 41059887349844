import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ResultCell from './ResultCell';
import LineTypeCell from './LineTypeCell';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const MobileHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
}));

export default function ResultLine({ data, isEven }) {
  // console.log(data);
  const LineItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    // backgroundColor: isEven ? '#fff' : '#f5f5f5',
    height: '100%',
    // border: '1px solid #ddd',
  }));

  const theme = useTheme();
  const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Grid item xs={12} md={3}>
        <LineItem>
          <LineTypeCell description={data.type} />
        </LineItem>
      </Grid>
      {isDesktopMode || data.mandatoryCoverages.length === 0 ? null : (
        <Grid item xs={12} md={3}>
          <LineItem>
            <MobileHeader sx={{ backgroundColor: '#003399' }}>
              <Typography variant="subtitl1" gutterBottom component="div">
                COUVERTURE OBLIGATOIRE <br />
                <Typography variant="subtitle2" gutterBottom component="div">
                  Requise par la loi
                </Typography>
              </Typography>
            </MobileHeader>
          </LineItem>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <LineItem>
          <ResultCell data={data.mandatoryCoverages} />
        </LineItem>
      </Grid>
      {isDesktopMode || data.recommandedCoverages.length === 0 ? null : (
        <Grid item xs={12} md={3}>
          <LineItem>
            <MobileHeader sx={{ backgroundColor: '#00A2E8' }}>
              <Typography variant="subtitl1" gutterBottom component="div">
                COUVERTURE RECOMMANDEE
              </Typography>
            </MobileHeader>
          </LineItem>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <LineItem>
          <ResultCell data={data.recommandedCoverages} />
        </LineItem>
      </Grid>
      {isDesktopMode || data.optimalCoverages.length === 0 ? null : (
        <Grid item xs={12} md={3}>
          <LineItem>
            <MobileHeader sx={{ backgroundColor: '#40A1BC' }}>
              <Typography variant="subtitl1" gutterBottom component="div">
                COUVERTURE OPTIMALE
              </Typography>
            </MobileHeader>
          </LineItem>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <LineItem>
          <ResultCell data={data.optimalCoverages} />
        </LineItem>
      </Grid>
    </>
  );
}
