import axios from 'axios';
console.log(process.env.REACT_APP_API_SERVER);
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
});

api.interceptors.request.use((config) => {
  return config;
});

export default api;
export const baseURL = `${process.env.REACT_APP_API_SERVER}`;
