export default function process(data) {
  const lineData = {
    type: 'Assurance objets de valeurs <i class="fa-solid fa-gem"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('valueObject'))
    lineData.recommandedCoverages.push({
      descriptions: ['Tous risques'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });

  return lineData;
}
