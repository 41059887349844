import React from 'react';
import Typography from '@mui/material/Typography';
import CallToActionLink from './CallToActionLink';
import CallToActionEmail from './CallToActionEmail';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useStore from '../store';

export default function ResultBloc({ data }) {
  const setMailRequest = useStore((state) => state.setMailRequest);

  const [isSelected, setIsSelected] = React.useState(data.callToActionType === 'EMAIL');

  React.useEffect(() => {
    const details = data.descriptions.reduce((acc, v) => acc + ' ' + v);
    setMailRequest(details, isSelected);
  }, [isSelected]);

  const handleChange = (event) => {
    setIsSelected(event.target.checked);
  };
  return (
    <Typography variant="body1" gutterBottom component="div">
      {data.descriptions.map((description, index) => {
        return (
          <React.Fragment key={index}>
            <div dangerouslySetInnerHTML={{ __html: description }}></div> <br />
          </React.Fragment>
        );
      })}
      <br />
      {data.callToActionType === 'LINK' ? <CallToActionLink link={data.callToAction} /> : null}
      {/* {data.callToActionType === 'EMAIL' ? <CallToActionEmail /> : null} */}
      {/* {data.callToActionType === 'EMAIL' ? <CallToActionEmail /> : null} */}
      {data.callToActionType === 'EMAIL' ? (
        <FormGroup>
          <FormControlLabel control={<Switch checked={isSelected} onChange={handleChange} />} label="Je souhaite recevoir une offre par e-mail" />
        </FormGroup>
      ) : null}
    </Typography>
  );
}
