import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ResultHeader from './ResultHeader';
import ResultLine from './ResultLine';
import process from './processor/processor';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useStore from '../store';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';

import ResultForm from './ResultForm';

export default function Results() {
  const theme = useTheme();
  const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const result = useStore((state) => state.result);

  if (!result) {
    navigate('/');
    return null;
  }
  const results = process(result);

  return (
    <>
      {isDesktopMode ? (
        <>
          <Box sx={{ flexGrow: 1, mr: 1, ml: 1 }}>
            <Toolbar sx={{ height: 150 }} />
            <Grid container direction="row" spacing={1} alignItems="stretch" justifyContent="space-evenly">
              <ResultHeader />

              {results.map((line, index) => (
                <ResultLine key={index} data={line} isEven={index % 2 !== 0} />
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <>
          {results.map((line, index) => (
            <ResultLine key={index} data={line} isEven={index % 2 !== 0} />
          ))}
        </>
      )}

      <ResultForm />
    </>
  );
}
