import { getAge } from 'src/utils/dateUtiles';

export default function process(data) {
  const lineData = {
    type: 'Prévoyance <i class="fa-solid fa-landmark"></i>',
    icon: 'landmark',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  const userAge = getAge(new Date(data.birthday));
  if (userAge > 20 && data.occupation === 'student') {
    let callToActionLink = '';
    switch (data.location.canton) {
      case 'VD':
        callToActionLink = 'https://www.lausanne.ch/prestations/assurances-sociales/cotisations-etudiants.html';
        break;
      case 'BE':
        callToActionLink = 'https://www.akbern.ch/fr/particuliers/cotisations/etudiants/';
        break;
      case 'VS':
        callToActionLink = 'https://www.vs.ch/web/avs/etudes';
        break;
      case 'GE':
        callToActionLink = 'https://www.ocas.ch/avs/cotisations/etudiants';
        break;
      case 'NE':
        callToActionLink = 'https://www.caisseavsne.ch/fileadmin/user_upload/form_affiliation_psa_f.pdf';
        break;
      case 'FR':
        callToActionLink = 'https://www.caisseavsfr.ch/particuliers/cotisations/obligation-de-cotiser/';
        break;

      default:
        break;
    }
    lineData.mandatoryCoverages.push({
      descriptions: ['Cotisation obligatoire à l’AVS/AI/APG', 'CHF 514 par année (état 2023)'],
      callToActionType: 'LINK',
      callToAction: callToActionLink,
    });
  }

  if (data.occupation === 'employed' || data.occupation === 'selfEmployed') {
    lineData.recommandedCoverages.push({
      descriptions: ['Prévoyance <i class="fa-solid fa-landmark"></i> 3a'],
      callToActionType: 'EMAIL',
      callToAction: 'eric.reber@mcr-solutions.com',
    });
  }

  if (data.occupation === 'unemployed' || data.occupation === 'selfEmployed') {
    lineData.optimalCoverages.push({
      descriptions: ['Décès et invalidité'],
      callToActionType: 'EMAIL',
      callToAction: 'eric.reber@mcr-solutions.com',
    });
  }

  return lineData;
}
