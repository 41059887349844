export default function process(data) {
  const lineData = {
    type: 'Perte de gain par suite de maladie <i class="fa-solid fa-suitcase-medical">',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.occupation === 'selfEmployed') {
    lineData.recommandedCoverages.push({
      descriptions: ['Perte de gain maladie <i class="fa-solid fa-suitcase-medical">'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }
  return lineData;
}
