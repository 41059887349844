import React from 'react';
import { Modal, Button, Group, TextInput, Box } from '@mantine/core';
import { useState } from 'react';
import Fab from '@mui/material/Fab';
import EmailIcon from '@mui/icons-material/Email';
import { useIntersection } from '@mantine/hooks';
import { At, CircleCheck } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import useStore from '../store';
import api from 'src/api/axios';
import { showNotification, updateNotification } from '@mantine/notifications';

export default function ResultForm() {
  const mailRequest = useStore((state) => state.mailRequest);
  const result = useStore((state) => state.result);

  const [opened, setOpened] = useState(false);
  const [ref, observer] = useIntersection({
    root: null,
    threshold: 1,
  });

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail non valide'),
    },
  });

  const formSubmit = async (values) => {
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Traitement des données',
      message: 'Vos données sont en cours de traitement',
      autoClose: false,
      disallowClose: true,
    });

    await api.post('/request', { requester: values, result: result, mailRequest: mailRequest });
    setOpened(false);
    updateNotification({
      id: 'load-data',
      color: 'teal',
      title: 'Demande prise en compte',
      message: 'Votre demande a été prise en compte.',
      icon: <CircleCheck />,
      autoClose: 8000,
    });
  };

  return (
    <>
      <Fab sx={{ position: 'fixed', bottom: observer?.isIntersecting ? null : 16, right: 16 }} variant="extended" onClick={() => setOpened(true)}>
        <EmailIcon sx={{ mr: 1 }} />
        Demander l'offre
      </Fab>
      <Fab ref={ref} sx={{ visibility: 'hidden', position: 'fixed', right: 16 }} variant="extended">
        <EmailIcon sx={{ mr: 1 }} />
        Demander l'offre
      </Fab>
      <Modal opened={opened} onClose={() => setOpened(false)} withCloseButton={false}>
        <Box sx={{ maxWidth: 400 }} mx="auto">
          <form onSubmit={form.onSubmit(formSubmit)}>
            <Group position="apart" grow>
              <TextInput required label="Votre prénom" placeholder="Votre prénom" {...form.getInputProps('firstName')} />
              <TextInput required label="Votre nom" placeholder="Votre nom" {...form.getInputProps('lastName')} />
            </Group>
            <TextInput required label="Votre e-mail" placeholder="Votre e-mail" icon={<At size={14} />} {...form.getInputProps('email')} />
            <Group sx={{ marginTop: 10 }} position="right">
              <Button type="submit">Envoyer</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
