export default function process(data) {
  const lineData = {
    type: 'Libre-Passage',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.occupation === 'unemployed') {
    lineData.mandatoryCoverages.push({
      descriptions: ['Transfert de votre prestation de sortie auprès d’une fondation de libre-passage'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  return lineData;
}
