import prevoyance from './prevoyance';
import maladieAccident from './maladie_accident';
import mobilier from './mobilier';
import menage from './menage';
import garantie from './garantie';
import librePassage from './libre_passage';
import batiment from './batiment';
import rc from './rc';
import accident from './accident';
import apg_malaide from './APG_maladie';
import voyage from './voyage';
import prevoyance_independant from './prevoyance_independant';
import assurance_entreprise from './assurance_entreprise';
import velo from './velo';
import velo_electrique from './velo_electrique';
import scooter_moto from './scooter_moto';
import voiture from './voiture';
import bateau from './bateau';
import objet_valeur from './objet_valeur';

export default function process(data) {
  const results = [];

  manage(results, data, prevoyance);
  manage(results, data, maladieAccident);
  manage(results, data, mobilier);
  manage(results, data, menage);
  manage(results, data, garantie);
  manage(results, data, librePassage);
  manage(results, data, batiment);
  manage(results, data, rc);
  manage(results, data, voyage);
  manage(results, data, accident);
  manage(results, data, apg_malaide);
  manage(results, data, prevoyance_independant);
  manage(results, data, velo);
  manage(results, data, velo_electrique);
  manage(results, data, scooter_moto);
  manage(results, data, voiture);
  manage(results, data, bateau);
  manage(results, data, objet_valeur);
  manage(results, data, assurance_entreprise);
  return results;
}

function manage(results, data, processor) {
  const result = processor(data);
  if (result.mandatoryCoverages.length > 0 || result.recommandedCoverages.length > 0 || result.optimalCoverages.length > 0) {
    results.push(result);
  }
}
