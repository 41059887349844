export default function process(data) {
  const lineData = {
    type: 'Responsabilité civile <i class="fa-solid fa-scale-balanced"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whoLives.includes('noBody') && data.livingPlace !== 'parents') {
    lineData.recommandedCoverages.push({
      descriptions: ['RC <i class="fa-solid fa-scale-balanced"></i> privée personne seule'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  if ((data.whoLives.includes('friend') || data.whoLives.includes('children')) && data.livingPlace !== 'parents') {
    lineData.recommandedCoverages.push({
      descriptions: ['RC <i class="fa-solid fa-scale-balanced"></i> privée famille'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  return lineData;
}
