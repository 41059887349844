export default function process(data) {
  const lineData = {
    type: 'Assurance du mobilier contre l’incendie <i class="fa-solid fa-fire-extinguisher"></i> & dommages naturels <i class="fa-solid fa-cloud-bolt"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.location.canton === 'VD') {
    lineData.mandatoryCoverages.push({
      descriptions: ['Obligation d’assurance auprès de ECA'],
      callToActionType: 'LINK',
      callToAction: 'https://www.eca-vaud.ch/particuliers/assurer-ses-biens/mobilier-de-menage',
    });
  }

  if (data.location.canton !== 'VD') {
    lineData.recommandedCoverages.push({
      descriptions: ['Incendie <i class="fa-solid fa-fire-extinguisher"></i> & dommages naturels <i class="fa-solid fa-cloud-bolt"></i>'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  return lineData;
}
