export default function process(data) {
  const lineData = {
    type: 'Prévoyance <i class="fa-solid fa-landmark"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.occupation === 'selfEmployed') {
    lineData.recommandedCoverages.push({
      descriptions: ['Prévoyance <i class="fa-solid fa-landmark"></i> 3A'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }
  return lineData;
}
