export default function process(data) {
  const lineData = {
    type: 'Assurance bateau <i class="fa-solid fa-sailboat"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('boat')) {
    lineData.mandatoryCoverages.push({
      descriptions: ['RC pour le bateau <i class="fa-solid fa-sailboat"></i>'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  if (data.boatLeasing)
    lineData.mandatoryCoverages.push({
      descriptions: ['Casco complète'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });

  if (data.whatDoYouOwn?.includes('boat') && !data.boatLeasing)
    lineData.recommandedCoverages.push({
      descriptions: ['Casco partielle', 'Si < 5 ans casco complète'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });

  if (data.whatDoYouOwn?.includes('boat'))
    lineData.optimalCoverages.push({
      descriptions: ['Accident <i class="fa-solid fa-person-falling"></i>'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });

  return lineData;
}
