import React from 'react';
import Select from 'react-select';
import * as Survey from 'survey-react';

export default class ChildrenBirthDate extends Survey.SurveyQuestionDropdown {
  get options() {
    return this.question.visibleChoices.map((c) => {
      return { value: c.value, label: c.value };
    });
  }
  get selectedOption() {
    return this.options.filter((o) => o.value === this.question.value);
  }
  onSelectChange = (selectedOption) => {
    this.setValueCore(selectedOption.value);
  };
  renderElement() {
    if (this.isDisplayMode) {
      return (
        <div id={this.question.inputId} className={this.question.getControlClass()} disabled>
          {this.question.displayValue || this.question.optionsCaption}
        </div>
      );
    }
    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        onChange={this.onSelectChange}
        options={this.options}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector('body')}
      />
    );
  }
}
