export default function process(data) {
  const lineData = {
    type: 'Assurance Voiture  <i class="fa-solid fa-car-rear"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('car')) {
    lineData.mandatoryCoverages.push({
      descriptions: ['RC  <i class="fa-solid fa-scale-balanced"></i> du véhicule'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });
  }

  if (data.carLeasing)
    lineData.mandatoryCoverages.push({
      descriptions: ['Casco complète'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });

  if (data.whatDoYouOwn?.includes('car') && !data.carLeasing)
    lineData.recommandedCoverages.push({
      descriptions: ['Casco partielle', 'Si < 5 ans casco complète'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });

  if (data.whatDoYouOwn?.includes('car'))
    lineData.optimalCoverages.push({
      descriptions: ['Occupants, dépannage et protection juridique <i class="fa-solid fa-scale-balanced"></i> circulation'],
      callToActionType: 'LINK',
      callToAction:
        'https://www.emilia.swiss/fr/protection-juridique/demande/particuliers/produit?productCode=household&affiliate_id=fe546fe0-8152-49cb-a274-8759e6dea434',
    });

  return lineData;
}
