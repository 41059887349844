import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import ResultBloc from './ResultBloc';

export default function ResultCell({ data }) {
  //{i !== data.mandatoryCoverages.length - 1 ? <Divider sx={{ mb: 2 }} /> : null}
  if (data.length === 0) {
    return null;
  }
  return (
    <Card sx={{ height: '100%' }}>
      {/* <CardActionArea sx={{ height: '100%' }}> */}
      <CardContent>
        {data.map((e, i) => {
          return (
            <React.Fragment key={i}>
              <ResultBloc data={e} />

              {i + 1 !== data.length ? <Divider sx={{ mb: '15px' }} /> : null}
            </React.Fragment>
          );
        })}
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}
