export default function process(data) {
  const lineData = {
    type: 'Assurance bâtiment <i class="fa-solid fa-house-chimney-window"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (['FR', 'VD', 'BE', 'NE'].includes(data.location.canton) && (data.ownerType === 'individual' || data.secondaryHomeOwnerType === 'individual')) {
    let callToActionLink = '';
    switch (data.location.canton) {
      case 'VD':
        callToActionLink = 'https://www.eca-vaud.ch/particuliers/assurer-ses-biens/se-premunire-des-dangers';
        break;
      case 'BE':
        callToActionLink = 'https://gvb.ch/fr/assurances.html';
        break;
      case 'NE':
        callToActionLink = 'https://www.ecap-ne.ch/fr/Prestations/Assurer.html';
        break;
      case 'FR':
        callToActionLink = 'https://www.fr.ch/dsj/ecab';
        break;

      default:
        break;
    }
    lineData.mandatoryCoverages.push({
      descriptions: [
        'Incendie <i class="fa-solid fa-fire-extinguisher"></i> et dommages naturels <i class="fa-solid fa-cloud-bolt"></i>, obligation d’assurance auprès d’ECA',
      ],
      callToActionType: 'LINK',
      callToAction: callToActionLink,
    });
  }

  if (data.ownerType === 'individual' || data.secondaryHomeOwnerType === 'individual') {
    let desc = [];
    if (data.location.canton === 'GE' || data.location.canton === 'VS') {
      desc.push('Incendie <i class="fa-solid fa-fire-extinguisher"></i> et dommages naturels <i class="fa-solid fa-cloud-bolt"></i>');
    }
    desc.push(
      'Dégâts d’eau <i class="fa-solid fa-water"></i> + actes de malveillance, frais de changement de serrures suite à un vol <i class="fa-solid fa-person-through-window"></i>'
    );
    lineData.recommandedCoverages.push({
      descriptions: desc,
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  if (data.ownerType === 'individual' || data.secondaryHomeOwnerType === 'individual') {
    lineData.optimalCoverages.push({
      descriptions: ['Bris de glaces'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  return lineData;
}
