export default function process(data) {
  const lineData = {
    type: 'Assurance accidents <i class="fa-solid fa-person-falling"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.occupation === 'selfEmployed') {
    lineData.recommandedCoverages.push({
      descriptions: ['LAA facultative'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }
  return lineData;
}
