export default function process(data) {
  const lineData = {
    type: 'Assurance Vélo <i class="fa-solid fa-bicycle"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('bike'))
    lineData.recommandedCoverages.push({
      descriptions: ['RC <i class="fa-solid fa-scale-balanced"></i> privée', 'Vol'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });

  return lineData;
}
