import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const HeaderCell = styled(Grid)(({ theme }) => ({
  marginTop: '16px',
  marginLeft: '8px',
  marginRight: '16px',
  marginBottom: '10px',
}));
const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  height: '110px',
}));

const Header = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  marginLeft: '8px',
}));
export default function ResultHeader() {
  return (
    <AppBar position="fixed" color={'primary'} elevation={0} sx={{ backgroundColor: '#555', zIndex: 200 }}>
      <Header>
        <HeaderCell item xs={12} md={3}>
          <Item sx={{ backgroundColor: '#666' }}>
            <Typography variant="h5" gutterBottom component="div">
              QUOI
            </Typography>
          </Item>
        </HeaderCell>
        <HeaderCell item xs={12} md={3}>
          <Item sx={{ backgroundColor: '#003399' }}>
            <Typography variant="h5" gutterBottom component="div">
              COUVERTURE <br />
              OBLIGATOIRE <br />
              <Typography variant="subtitle1" gutterBottom component="div">
                Requise par la loi
              </Typography>
            </Typography>
          </Item>
        </HeaderCell>
        <HeaderCell item xs={12} md={3}>
          <Item sx={{ backgroundColor: '#00A2E8' }}>
            <Typography variant="h5" gutterBottom component="div">
              COUVERTURE <br />
              RECOMMANDEE
            </Typography>
          </Item>
        </HeaderCell>
        <HeaderCell item xs={12} md={3}>
          <Item sx={{ backgroundColor: '#40A1BC' }}>
            <Typography variant="h5" gutterBottom component="div">
              COUVERTURE <br />
              OPTIMALE
            </Typography>
          </Item>
        </HeaderCell>
      </Header>
    </AppBar>
  );
}
