import create from 'zustand';

const useStore = create((set) => {
  const setResult = (userResult) =>
    set((state) => {
      return { result: userResult };
    });
  const setMailRequest = (mailRequest, value) => {
    set((state) => {
      const newMailRequest = state.mailRequest;
      newMailRequest[mailRequest] = value;
      return { mailRequest: newMailRequest };
    });
  };

  return {
    result: undefined,
    setResult: setResult,
    mailRequest: {},
    setMailRequest: setMailRequest,
  };
});

export default useStore;
