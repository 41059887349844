export default function process(data) {
  const lineData = {
    type: 'Assurance ménage',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.livingPlace === 'location' || data.livingPlace === 'colocation' || data.livingPlace === 'owner') {
    lineData.recommandedCoverages.push({
      descriptions: ['Mobilier : vol <i class="fa-solid fa-person-through-window"></i>, dégâts d’eau <i class="fa-solid fa-water"></i>, casco ménage'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/calc/home/details?code=pd4ASnEC&sac=R0A&lang=fr',
    });
  }

  if (data.livingPlace === 'location' || data.livingPlace === 'colocation' || data.livingPlace === 'owner') {
    lineData.optimalCoverages.push({
      descriptions: ['Protection juridique <i class="fa-solid fa-scale-balanced"></i>'],
      callToActionType: 'LINK',
      callToAction:
        'https://www.emilia.swiss/fr/protection-juridique/demande/particuliers/produit?productCode=household&affiliate_id=fe546fe0-8152-49cb-a274-8759e6dea434',
    });
  }

  return lineData;
}
