import React from 'react';
import { useCallback } from 'react';
import { Survey, StylesManager, Model, ReactQuestionFactory, RendererFactory } from 'survey-react';
// Modern theme
import 'survey-react/modern.min.css';
// Default theme
// import 'survey-react/survey.min.css';
import SurveySelect from './components/SurveySelect';
import ChildrenBirthDate from './components/ChildrenBirthDate';
import { surveyJson } from './questions/questions';
import useStore from './store';
import { useNavigate } from 'react-router-dom';

var defaultThemeColors = StylesManager.ThemeColors['modern'];
defaultThemeColors['$main-color'] = '#980068';
defaultThemeColors['$progress-buttons-color'] = '#ffc9ee';
defaultThemeColors['$answer-background-color'] = 'rgba(152, 0, 104, 0.2)';
StylesManager.applyTheme('modern');

export default function Home() {
  const navigate = useNavigate();

  const setResult = useStore((state) => state.setResult);

  const survey = new Model(surveyJson);
  survey.sendResultOnPageNext = true;

  const storageName = 'assurcheck';
  function saveSurveyData(survey) {
    var data = survey.data;
    data.pageNo = survey.currentPageNo;
    window.localStorage.setItem(storageName, JSON.stringify(data));
  }
  survey.onPartialSend.add(function (survey) {
    saveSurveyData(survey);
  });

  const alertResults = useCallback((sender) => {
    console.log(sender.data);
    const results = JSON.stringify(sender.data);
    console.log(results);
    saveSurveyData(survey);
    setResult(sender.data);
    navigate(`/results`);
  }, []);

  survey.onComplete.add(alertResults);

  const prevData = window.localStorage.getItem(storageName) || null;
  if (prevData) {
    const data = JSON.parse(prevData);
    survey.data = data;
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo;
    }
  }

  ReactQuestionFactory.Instance.registerQuestion('sv-dropdown-react', (props) => {
    return React.createElement(SurveySelect, props);
  });
  RendererFactory.Instance.registerRenderer('dropdown', 'dropdown-react', 'sv-dropdown-react');

  ReactQuestionFactory.Instance.registerQuestion('sv-children-birth-date', (props) => {
    return React.createElement(ChildrenBirthDate, props);
  });
  RendererFactory.Instance.registerRenderer('checkbox', 'children-birth-date', 'sv-children-birth-date');

  return <Survey model={survey} />;
}
