import npa from './npa';
export const surveyJson = {
  locale: 'fr',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'text',
          name: 'birthday',
          title: {
            fr: 'Quelle est ta date de naissance?',
          },
          isRequired: true,
          inputType: 'date',
        },
      ],
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'dropdown',
          name: 'location',
          title: 'Où est-ce que tu habites?',
          isRequired: true,
          choices: npa,
          renderAs: 'dropdown-react',
        },
      ],
    },
    {
      name: 'page3',
      elements: [
        {
          type: 'checkbox',
          name: 'whoLives',
          title: 'Qui vit avec toi?',
          isRequired: true,
          choices: [
            {
              value: 'noBody',
              text: {
                fr: 'Personne',
              },
            },
            {
              value: 'friend',
              text: {
                fr: 'Conjoint(e) ou Ami/e',
              },
            },
            {
              value: 'children',
              text: {
                fr: 'Enfant(s)',
              },
            },
          ],
        },
        // {
        //   type: 'text',
        //   name: 'friend',
        //   title: {
        //     fr: 'Quelle est la date de naissances de ton ami(e) ?',
        //   },
        //   visibleIf: "{whoLives} contains 'friend'",
        //   isRequired: true,
        //   inputType: 'date',
        // },

        // {
        //   type: 'multipletext',
        //   name: 'children',
        //   title: 'Quelle est la date de naissances de tes enfants ?',
        //   visibleIf: "{whoLives} contains 'children'",
        //   items: [
        //     {
        //       name: 'child1',
        //       inputType: 'date',
        //       title: {
        //         fr: 'Enfant 1',
        //       },
        //     },
        //     {
        //       name: 'child2',
        //       inputType: 'date',
        //       title: {
        //         fr: 'Enfant 2',
        //       },
        //     },
        //     {
        //       name: 'child3',
        //       inputType: 'date',
        //       title: {
        //         fr: 'Enfant 3',
        //       },
        //     },
        //     {
        //       name: 'child4',
        //       inputType: 'date',
        //       title: {
        //         fr: 'Enfant 4',
        //       },
        //     },
        //   ],
        // },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          type: 'radiogroup',
          name: 'livingPlace',
          isRequired: true,
          title: {
            fr: 'Où est-ce que tu loges?',
          },
          choices: [
            {
              value: 'parents',
              text: {
                fr: 'Chez mes parents',
              },
            },
            {
              value: 'location',
              text: {
                fr: 'Je loue un appart',
              },
            },
            {
              value: 'colocation',
              text: {
                fr: 'En collocation',
              },
            },
            {
              value: 'owner',
              text: {
                fr: 'Je suis propriétaire',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'ownerType',
          isRequired: true,
          visibleIf: "{livingPlace} = 'owner'",
          title: {
            fr: 'Individuel ou en PPE?',
          },
          choices: [
            {
              value: 'individual',
              text: {
                fr: 'Individuel',
              },
            },
            {
              value: 'ppe',
              text: {
                fr: 'PPE',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'page5',
      elements: [
        {
          type: 'radiogroup',
          name: 'occupation',
          isRequired: true,
          title: {
            fr: 'Tu fais quoi dans la vie?',
          },
          choices: [
            {
              value: 'student',
              text: {
                fr: 'Je suis étudiant',
              },
            },
            {
              value: 'apprentice',
              text: {
                fr: 'Je suis apprenti',
              },
            },
            {
              value: 'unemployed',
              text: {
                fr: 'Je suis au chomage',
              },
            },
            {
              value: 'selfEmployed',
              text: {
                fr: 'Je suis indépendant',
              },
            },
            {
              value: 'retired',
              text: {
                fr: 'Je suis retraité',
              },
            },
            {
              value: 'employed',
              text: {
                fr: 'Je suis employé',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'laaCovered',
          isRequired: true,
          visibleIf: "{occupation} = 'selfEmployed'",
          title: {
            fr: 'Possèdes-tu une couverture LAA facultative',
          },
          choices: [
            {
              value: true,
              text: {
                fr: 'Oui',
              },
            },
            {
              value: false,
              text: {
                fr: 'Non',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'page6',
      elements: [
        {
          type: 'checkbox',
          name: 'whatDoYouOwn',
          title: 'Que possèdes-tu?',
          isRequired: false,
          choices: [
            {
              value: 'bike',
              text: {
                fr: 'Un vélo',
              },
            },
            {
              value: 'electricBike',
              text: {
                fr: 'Un vélo électrique',
              },
            },
            {
              value: 'scooter',
              text: {
                fr: 'Un scooter ou une moto',
              },
            },
            {
              value: 'car',
              text: {
                fr: 'Une voiture',
              },
            },
            {
              value: 'boat',
              text: {
                fr: 'Un bateau',
              },
            },
            {
              value: 'valueObject',
              text: {
                fr: 'Des objets de valeur (montres, bijoux, fourrures, instruments, matériels hi-fi, etc.) ',
              },
            },
            {
              value: 'secondaryHome',
              text: {
                fr: 'Une résidence secondaire',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'scooterLeasing',
          isRequired: true,
          visibleIf: "{whatDoYouOwn} contains 'scooter'",
          title: {
            fr: 'Est-ce que ton scooter est en leasing?',
          },
          choices: [
            {
              value: true,
              text: {
                fr: 'Oui',
              },
            },
            {
              value: false,
              text: {
                fr: 'Non',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'carLeasing',
          isRequired: true,
          visibleIf: "{whatDoYouOwn} contains 'car'",
          title: {
            fr: 'Est-ce que ta voiture est en leasing?',
          },
          choices: [
            {
              value: true,
              text: {
                fr: 'Oui',
              },
            },
            {
              value: false,
              text: {
                fr: 'Non',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'boatLeasing',
          isRequired: true,
          visibleIf: "{whatDoYouOwn} contains 'boat'",
          title: {
            fr: 'Est-ce que ton bateau est en leasing?',
          },
          choices: [
            {
              value: true,
              text: {
                fr: 'Oui',
              },
            },
            {
              value: false,
              text: {
                fr: 'Non',
              },
            },
          ],
        },
        // {
        //   type: 'radiogroup',
        //   name: 'valueObjetMore30k',
        //   isRequired: true,
        //   visibleIf: "{whatDoYouOwn} contains 'valueObject'",
        //   title: {
        //     fr: `Est-ce que tes objets de valeur ont un total supérieur à CHF 30'000?`,
        //   },
        //   choices: [
        //     {
        //       value: true,
        //       text: {
        //         fr: 'Oui',
        //       },
        //     },
        //     {
        //       value: false,
        //       text: {
        //         fr: 'Non',
        //       },
        //     },
        //   ],
        // },
        {
          type: 'radiogroup',
          name: 'secondaryHomeOwnerType',
          isRequired: true,
          visibleIf: "{whatDoYouOwn} contains 'secondaryHome'",
          title: {
            fr: 'Résidence secondaire individuel ou en PPE?',
          },
          choices: [
            {
              value: 'individual',
              text: {
                fr: 'Individuel',
              },
            },
            {
              value: 'ppe',
              text: {
                fr: 'PPE',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'page7',
      elements: [
        {
          type: 'radiogroup',
          name: 'businessOwner',
          isRequired: true,
          title: {
            fr: 'Gères-tu une entreprise?',
          },
          choices: [
            {
              value: true,
              text: {
                fr: 'Oui',
              },
            },
            {
              value: false,
              text: {
                fr: 'Non',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'companyType',
          visibleIf: '{businessOwner} = true',
          isRequired: true,
          title: {
            fr: 'Individuelle, SARL ou SA?',
          },
          choices: [
            {
              value: 'individuelle',
              text: {
                fr: 'Une raison individuelle',
              },
            },
            {
              value: 'sarl',
              text: {
                fr: 'Une société à responsabilité limité (SARL)',
              },
            },
            {
              value: 'sa',
              text: {
                fr: 'Une société anonyme (SA)',
              },
            },
          ],
        },
        {
          type: 'radiogroup',
          name: 'numberOfEmploye',
          visibleIf: '{businessOwner} = true',
          isRequired: true,
          title: {
            fr: `Combien d'employés?`,
          },
          choices: [
            {
              value: 'lessThan5',
              text: {
                fr: 'Moins de 5',
              },
            },
            {
              value: '5to20',
              text: {
                fr: 'Entre 5 et 20',
              },
            },
            {
              value: '20more',
              text: {
                fr: 'Plus de 20',
              },
            },
          ],
        },
        {
          type: 'text',
          name: 'companyActivity',
          visibleIf: '{businessOwner} = true',
          isRequired: true,
          title: {
            fr: `Quelles sont les activités de l'entreprise?`,
          },
        },
        {
          type: 'text',
          name: 'companyName',
          visibleIf: '{businessOwner} = true',
          isRequired: true,
          title: {
            fr: `Quel est le nom de l'entreprise?`,
          },
        },
      ],
    },
  ],
};
