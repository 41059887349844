export default function process(data) {
  const lineData = {
    type: 'Assistance voyages <i class="fa-solid fa-plane-departure"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  lineData.recommandedCoverages.push({
    descriptions: ['Assistance'],
    callToActionType: 'LINK',
    callToAction:
      'https://www.smile-direct.com/ch/main/fr/home/assurances/assurance-voyages.html?erid=EAIaIQobChMIuvveweH39AIVCc13Ch0q7AlPEAAYASADEgIem_D_BwE&ecid=sem-ch-google-pk-brand-per-0-fr-paid-343982796159-0&gclid=EAIaIQobChMIuvveweH39AIVCc13Ch0q7AlPEAAYASADEgIem_D_BwE',
  });

  return lineData;
}
