import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter, components } from 'react-select';
import * as Survey from 'survey-react';
import CustomMenuList from './CustomMenuList';

const CustomOption = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

CustomOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default class SurveySelect extends Survey.SurveyQuestionDropdown {
  get options() {
    return this.question.visibleChoices.map((c) => {
      return { value: c.value, label: `${c.value.npa} - ${c.value.city}` };
    });
  }
  get selectedOption() {
    return this.options.filter((o) => o.value === this.question.value);
  }
  onSelectChange = (selectedOption) => {
    this.setValueCore(selectedOption.value);
  };
  renderElement() {
    if (this.isDisplayMode) {
      return (
        <div id={this.question.inputId} className={this.question.getControlClass()} disabled>
          {this.question.displayValue || this.question.optionsCaption}
        </div>
      );
    }
    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        onChange={this.onSelectChange}
        options={this.options}
        filterOption={createFilter({ ignoreAccents: false })}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector('body')}
        captureMenuScroll={false}
        classNamePrefix="custom-select"
        components={{ Option: CustomOption, MenuList: CustomMenuList }}
      />
    );
  }
}
