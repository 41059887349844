import React from 'react';

import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
export default function CallToActionLink({ link }) {
  return (
    <>
      Pour recevoir une offre, cliquez ci-dessous:
      <br />
      <IconButton
        onClick={() => {
          window.open(link, '_blank');
        }}
      >
        <LanguageIcon />
      </IconButton>
    </>
  );
}
