export default function process(data) {
  const lineData = {
    type: 'Assurance Moto <i class="fa-solid fa-motorcycle"></i> ou Scooter',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('scooter')) {
    lineData.mandatoryCoverages.push({
      descriptions: ['RC <i class="fa-solid fa-scale-balanced"></i> du véhicule '],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });
  }

  if (data.scooterLeasing)
    lineData.mandatoryCoverages.push({
      descriptions: ['Casco complète'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });

  if (data.whatDoYouOwn?.includes('scooter') && !data.scooterLeasing)
    lineData.recommandedCoverages.push({
      descriptions: ['Casco partielle', 'Si < 5 ans casco complète'],
      callToActionType: 'LINK',
      callToAction: 'https://app.smile-direct.com/ui/broker/lrc?lang=fr',
    });

  if (data.whatDoYouOwn?.includes('scooter'))
    lineData.optimalCoverages.push({
      descriptions: ['Occupants, dépannage et protection juridique <i class="fa-solid fa-scale-balanced"></i> circulation'],
      callToActionType: 'LINK',
      callToAction:
        'https://www.emilia.swiss/fr/protection-juridique/demande/particuliers/produit?productCode=household&affiliate_id=fe546fe0-8152-49cb-a274-8759e6dea434',
    });

  return lineData;
}
