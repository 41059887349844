export default function process(data) {
  const lineData = {
    type: 'Frais de traitement en cas de maladie <i class="fa-solid fa-suitcase-medical"></i> et accidents <i class="fa-solid fa-person-falling"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  lineData.mandatoryCoverages.push({
    descriptions: ['Maladie = obligatoire'],
    callToActionType: 'LINK',
    callToAction: 'https://www.atupri.ch/fr/assurances/assurance-de-base/smartcare',
  });

  if (data.occupation === 'student' || data.occupation === 'retired' || (data.occupation === 'selfEmployed' && (data?.laaCovered ?? false))) {
    lineData.mandatoryCoverages.push({
      descriptions: ['Contacte ton assureur LAMal afin d’assurer le risque d’accident <i class="fa-solid fa-person-falling"></i>'],
      callToActionType: 'LINK',
      callToAction: 'https://www.atupri.ch/fr/assurances/assurance-de-base/smartcare',
    });
  }

  if (data.occupation === 'employed' || data.occupation === 'unemployed' || (data.occupation === 'selfEmployed' && !(data?.laaCovered ?? false))) {
    lineData.mandatoryCoverages.push({
      descriptions: [
        'Accident = assuré par ton employeur',
        'Contacte ton assureur LAMal afin de supprimer le risque d’accident <i class="fa-solid fa-person-falling"></i>',
      ],
      callToActionType: '',
      callToAction: '',
    });
  }

  lineData.recommandedCoverages.push({
    descriptions: ['Médecine complémentaire, prévention'],
    callToActionType: 'EMAIL',
    callToAction: 'eric.reber@mcr-solutions.com',
  });

  lineData.optimalCoverages.push({
    descriptions: ['Hospitalisation division semi-privée ou privée'],
    callToActionType: 'EMAIL',
    callToAction: 'eric.reber@mcr-solutions.com',
  });

  return lineData;
}
