export default function process(data) {
  const lineData = {
    type: 'Garantie Loyer <i class="fa-solid fa-house-user"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.livingPlace === 'location' || data.livingPlace === 'colocation') {
    lineData.recommandedCoverages.push({
      descriptions: ['Caution : garantie loyer <i class="fa-solid fa-house-user"></i>'],
      callToActionType: 'LINK',
      callToAction:
        'https://www.registration.gocaution.ch/fr?_gl=1*1ezyd0x*_gcl_aw*R0NMLjE2NDA2OTc2NDMuRUFJYUlRb2JDaE1JMTlDNnpjcUc5UUlWR09kM0NoMkJRZ0xURUFBWUFpQUFFZ0pWX19EX0J3RQ..&_ga=2.140967477.621232181.1640697426-1755903899.1640697426&_gac=1.237832756.1640697643.EAIaIQobChMI19C6zcqG9QIVGOd3Ch2BQgLTEAAYAiAAEgJV__D_BwE',
    });
  }

  return lineData;
}
