export default function process(data) {
  const lineData = {
    type: 'Assurance Vélo électrique',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.whatDoYouOwn?.includes('electricBike'))
    lineData.mandatoryCoverages.push({
      descriptions: ['RC <i class="fa-solid fa-scale-balanced"></i> pour le vélo > 500 watts ou 25 km/h'],
      callToActionType: 'LINK',
      callToAction: 'https://www.zurich.ch/fr/calculateur-de-prime/velo-velo-electrique',
    });

  if (data.whatDoYouOwn?.includes('electricBike'))
    lineData.recommandedCoverages.push({
      descriptions: ['Vol, détérioration suite à une chute'],
      callToActionType: 'LINK',
      callToAction: 'https://www.zurich.ch/fr/calculateur-de-prime/velo-velo-electrique',
    });

  if (data.whatDoYouOwn?.includes('electricBike'))
    lineData.optimalCoverages.push({
      descriptions: ['Dépannage'],
      callToActionType: 'LINK',
      callToAction: 'https://www.zurich.ch/fr/calculateur-de-prime/velo-velo-electrique',
    });

  return lineData;
}
