export default function process(data) {
  const lineData = {
    type: 'Assurance entreprise <i class="fa-solid fa-industry"></i>',
    icon: '',
    mandatoryCoverages: [],
    recommandedCoverages: [],
    optimalCoverages: [],
  };

  if (data.occupation === 'selfEmployed') {
    lineData.mandatoryCoverages.push({
      descriptions: ['LAA et LPP pour les employés'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  if (data.companyType === 'sarl' || data.companyType === 'sa') {
    lineData.mandatoryCoverages.push({
      descriptions: ['LAA et LPP'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  if (data.businessOwner) {
    lineData.recommandedCoverages.push({
      descriptions: ['Perte de gain maladie <i class="fa-solid fa-suitcase-medical"></i>, RC Entreprise <i class="fa-solid fa-industry"></i> et commerce'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  if (data.businessOwner) {
    lineData.optimalCoverages.push({
      descriptions: ['Protection juridique, transports, prévoyance'],
      callToActionType: 'EMAIL',
      callToAction: '',
    });
  }

  return lineData;
}
