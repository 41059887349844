const npa = [
  { npa: '8914', city: 'Aeugst am Albis', canton: 'ZH' },
  { npa: '8914', city: 'Aeugstertal', canton: 'ZH' },
  { npa: '8909', city: 'Zwillikon', canton: 'ZH' },
  { npa: '8910', city: 'Affoltern am Albis', canton: 'ZH' },
  { npa: '8906', city: 'Bonstetten', canton: 'ZH' },
  { npa: '8915', city: 'Hausen am Albis', canton: 'ZH' },
  { npa: '8925', city: 'Ebertswil', canton: 'ZH' },
  { npa: '8908', city: 'Hedingen', canton: 'ZH' },
  { npa: '8926', city: 'Kappel am Albis', canton: 'ZH' },
  { npa: '8926', city: 'Hauptikon', canton: 'ZH' },
  { npa: '8926', city: 'Uerzlikon', canton: 'ZH' },
  { npa: '8934', city: 'Knonau', canton: 'ZH' },
  { npa: '8933', city: 'Maschwanden', canton: 'ZH' },
  { npa: '8932', city: 'Mettmenstetten', canton: 'ZH' },
  { npa: '8912', city: 'Obfelden', canton: 'ZH' },
  { npa: '8913', city: 'Ottenbach', canton: 'ZH' },
  { npa: '8911', city: 'Rifferswil', canton: 'ZH' },
  { npa: '8143', city: 'Stallikon', canton: 'ZH' },
  { npa: '8143', city: 'Uetliberg', canton: 'ZH' },
  { npa: '8907', city: 'Wettswil', canton: 'ZH' },
  { npa: '8452', city: 'Adlikon b. Andelfingen', canton: 'ZH' },
  { npa: '8463', city: 'Benken ZH', canton: 'ZH' },
  { npa: '8415', city: 'Berg am Irchel', canton: 'ZH' },
  { npa: '8415', city: 'Gräslikon', canton: 'ZH' },
  { npa: '8414', city: 'Buch am Irchel', canton: 'ZH' },
  { npa: '8447', city: 'Dachsen', canton: 'ZH' },
  { npa: '8458', city: 'Dorf', canton: 'ZH' },
  { npa: '8245', city: 'Feuerthalen', canton: 'ZH' },
  { npa: '8246', city: 'Langwiesen', canton: 'ZH' },
  { npa: '8416', city: 'Flaach', canton: 'ZH' },
  { npa: '8247', city: 'Flurlingen', canton: 'ZH' },
  { npa: '8450', city: 'Andelfingen', canton: 'ZH' },
  { npa: '8444', city: 'Henggart', canton: 'ZH' },
  { npa: '8457', city: 'Humlikon', canton: 'ZH' },
  { npa: '8451', city: 'Kleinandelfingen', canton: 'ZH' },
  { npa: '8453', city: 'Alten', canton: 'ZH' },
  { npa: '8461', city: 'Oerlingen', canton: 'ZH' },
  { npa: '8212', city: 'Nohl', canton: 'ZH' },
  { npa: '8248', city: 'Uhwiesen', canton: 'ZH' },
  { npa: '8460', city: 'Marthalen', canton: 'ZH' },
  { npa: '8464', city: 'Ellikon am Rhein', canton: 'ZH' },
  { npa: '8475', city: 'Ossingen', canton: 'ZH' },
  { npa: '8462', city: 'Rheinau', canton: 'ZH' },
  { npa: '8478', city: 'Thalheim an der Thur', canton: 'ZH' },
  { npa: '8465', city: 'Rudolfingen', canton: 'ZH' },
  { npa: '8465', city: 'Wildensbuch', canton: 'ZH' },
  { npa: '8466', city: 'Trüllikon', canton: 'ZH' },
  { npa: '8467', city: 'Truttikon', canton: 'ZH' },
  { npa: '8459', city: 'Volken', canton: 'ZH' },
  { npa: '8184', city: 'Bachenbülach', canton: 'ZH' },
  { npa: '8303', city: 'Bassersdorf', canton: 'ZH' },
  { npa: '8180', city: 'Bülach', canton: 'ZH' },
  { npa: '8305', city: 'Dietlikon', canton: 'ZH' },
  { npa: '8193', city: 'Eglisau', canton: 'ZH' },
  { npa: '8424', city: 'Embrach', canton: 'ZH' },
  { npa: '8427', city: 'Freienstein', canton: 'ZH' },
  { npa: '8428', city: 'Teufen ZH', canton: 'ZH' },
  { npa: '8192', city: 'Glattfelden', canton: 'ZH' },
  { npa: '8192', city: 'Zweidlen', canton: 'ZH' },
  { npa: '8182', city: 'Hochfelden', canton: 'ZH' },
  { npa: '8181', city: 'Höri', canton: 'ZH' },
  { npa: '8194', city: 'Hüntwangen', canton: 'ZH' },
  { npa: '8302', city: 'Kloten', canton: 'ZH' },
  { npa: '8426', city: 'Lufingen', canton: 'ZH' },
  { npa: '8309', city: 'Nürensdorf', canton: 'ZH' },
  { npa: '8425', city: 'Oberembrach', canton: 'ZH' },
  { npa: '8152', city: 'Glattbrugg', canton: 'ZH' },
  { npa: '8152', city: 'Opfikon', canton: 'ZH' },
  { npa: '8152', city: 'Glattpark (Opfikon)', canton: 'ZH' },
  { npa: '8197', city: 'Rafz', canton: 'ZH' },
  { npa: '8427', city: 'Rorbas', canton: 'ZH' },
  { npa: '8304', city: 'Wallisellen', canton: 'ZH' },
  { npa: '8195', city: 'Wasterkingen', canton: 'ZH' },
  { npa: '8196', city: 'Wil ZH', canton: 'ZH' },
  { npa: '8185', city: 'Winkel', canton: 'ZH' },
  { npa: '8164', city: 'Bachs', canton: 'ZH' },
  { npa: '8113', city: 'Boppelsen', canton: 'ZH' },
  { npa: '8107', city: 'Buchs ZH', canton: 'ZH' },
  { npa: '8108', city: 'Dällikon', canton: 'ZH' },
  { npa: '8114', city: 'Dänikon ZH', canton: 'ZH' },
  { npa: '8157', city: 'Dielsdorf', canton: 'ZH' },
  { npa: '8115', city: 'Hüttikon', canton: 'ZH' },
  { npa: '8173', city: 'Neerach', canton: 'ZH' },
  { npa: '8172', city: 'Niederglatt ZH', canton: 'ZH' },
  { npa: '8155', city: 'Niederhasli', canton: 'ZH' },
  { npa: '8155', city: 'Nassenwil', canton: 'ZH' },
  { npa: '8156', city: 'Oberhasli', canton: 'ZH' },
  { npa: '8166', city: 'Niederweningen', canton: 'ZH' },
  { npa: '8154', city: 'Oberglatt ZH', canton: 'ZH' },
  { npa: '8165', city: 'Oberweningen', canton: 'ZH' },
  { npa: '8112', city: 'Otelfingen', canton: 'ZH' },
  { npa: '8158', city: 'Regensberg', canton: 'ZH' },
  { npa: '8105', city: 'Regensdorf', canton: 'ZH' },
  { npa: '8105', city: 'Watt', canton: 'ZH' },
  { npa: '8106', city: 'Adlikon b. Regensdorf', canton: 'ZH' },
  { npa: '8153', city: 'Rümlang', canton: 'ZH' },
  { npa: '8165', city: 'Schleinikon', canton: 'ZH' },
  { npa: '8165', city: 'Schöfflisdorf', canton: 'ZH' },
  { npa: '8174', city: 'Stadel b. Niederglatt', canton: 'ZH' },
  { npa: '8175', city: 'Windlach', canton: 'ZH' },
  { npa: '8162', city: 'Steinmaur', canton: 'ZH' },
  { npa: '8162', city: 'Sünikon', canton: 'ZH' },
  { npa: '8187', city: 'Weiach', canton: 'ZH' },
  { npa: '8344', city: 'Bäretswil', canton: 'ZH' },
  { npa: '8345', city: 'Adetswil', canton: 'ZH' },
  { npa: '8608', city: 'Bubikon', canton: 'ZH' },
  { npa: '8633', city: 'Wolfhausen', canton: 'ZH' },
  { npa: '8632', city: 'Tann', canton: 'ZH' },
  { npa: '8635', city: 'Dürnten', canton: 'ZH' },
  { npa: '8496', city: 'Steg im Tösstal', canton: 'ZH' },
  { npa: '8497', city: 'Fischenthal', canton: 'ZH' },
  { npa: '8498', city: 'Gibswil', canton: 'ZH' },
  { npa: '8614', city: 'Bertschikon (Gossau ZH)', canton: 'ZH' },
  { npa: '8624', city: 'Grüt (Gossau ZH)', canton: 'ZH' },
  { npa: '8625', city: 'Gossau ZH', canton: 'ZH' },
  { npa: '8626', city: 'Ottikon (Gossau ZH)', canton: 'ZH' },
  { npa: '8627', city: 'Grüningen', canton: 'ZH' },
  { npa: '8340', city: 'Hinwil', canton: 'ZH' },
  { npa: '8342', city: 'Wernetshausen', canton: 'ZH' },
  { npa: '8630', city: 'Rüti ZH', canton: 'ZH' },
  { npa: '8607', city: 'Aathal-Seegräben', canton: 'ZH' },
  { npa: '8636', city: 'Wald ZH', canton: 'ZH' },
  { npa: '8637', city: 'Laupen ZH', canton: 'ZH' },
  { npa: '8620', city: 'Wetzikon ZH', canton: 'ZH' },
  { npa: '8623', city: 'Wetzikon ZH', canton: 'ZH' },
  { npa: '8134', city: 'Adliswil', canton: 'ZH' },
  { npa: '8802', city: 'Kilchberg ZH', canton: 'ZH' },
  { npa: '8135', city: 'Langnau am Albis', canton: 'ZH' },
  { npa: '8942', city: 'Oberrieden', canton: 'ZH' },
  { npa: '8805', city: 'Richterswil', canton: 'ZH' },
  { npa: '8833', city: 'Samstagern', canton: 'ZH' },
  { npa: '8803', city: 'Rüschlikon', canton: 'ZH' },
  { npa: '8136', city: 'Gattikon', canton: 'ZH' },
  { npa: '8800', city: 'Thalwil', canton: 'ZH' },
  { npa: '8703', city: 'Erlenbach ZH', canton: 'ZH' },
  { npa: '8704', city: 'Herrliberg', canton: 'ZH' },
  { npa: '8634', city: 'Hombrechtikon', canton: 'ZH' },
  { npa: '8714', city: 'Feldbach', canton: 'ZH' },
  { npa: '8127', city: 'Forch', canton: 'ZH' },
  { npa: '8700', city: 'Küsnacht ZH', canton: 'ZH' },
  { npa: '8708', city: 'Männedorf', canton: 'ZH' },
  { npa: '8706', city: 'Meilen', canton: 'ZH' },
  { npa: '8618', city: 'Oetwil am See', canton: 'ZH' },
  { npa: '8712', city: 'Stäfa', canton: 'ZH' },
  { npa: '8713', city: 'Uerikon', canton: 'ZH' },
  { npa: '8707', city: 'Uetikon am See', canton: 'ZH' },
  { npa: '8126', city: 'Zumikon', canton: 'ZH' },
  { npa: '8125', city: 'Zollikerberg', canton: 'ZH' },
  { npa: '8702', city: 'Zollikon', canton: 'ZH' },
  { npa: '8320', city: 'Fehraltorf', canton: 'ZH' },
  { npa: '8335', city: 'Hittnau', canton: 'ZH' },
  { npa: '8310', city: 'Kemptthal', canton: 'ZH' },
  { npa: '8310', city: 'Grafstal', canton: 'ZH' },
  { npa: '8312', city: 'Winterberg ZH', canton: 'ZH' },
  { npa: '8315', city: 'Lindau', canton: 'ZH' },
  { npa: '8317', city: 'Tagelswangen', canton: 'ZH' },
  { npa: '8330', city: 'Pfäffikon ZH', canton: 'ZH' },
  { npa: '8331', city: 'Auslikon', canton: 'ZH' },
  { npa: '8322', city: 'Madetswil', canton: 'ZH' },
  { npa: '8322', city: 'Gündisau', canton: 'ZH' },
  { npa: '8332', city: 'Russikon', canton: 'ZH' },
  { npa: '8332', city: 'Rumlikon', canton: 'ZH' },
  { npa: '8484', city: 'Weisslingen', canton: 'ZH' },
  { npa: '8484', city: 'Neschwil', canton: 'ZH' },
  { npa: '8484', city: 'Theilingen', canton: 'ZH' },
  { npa: '8492', city: 'Wila', canton: 'ZH' },
  { npa: '8489', city: 'Wildberg', canton: 'ZH' },
  { npa: '8489', city: 'Schalchen', canton: 'ZH' },
  { npa: '8489', city: 'Ehrikon', canton: 'ZH' },
  { npa: '8044', city: 'Gockhausen', canton: 'ZH' },
  { npa: '8600', city: 'Dübendorf', canton: 'ZH' },
  { npa: '8132', city: 'Egg b. Zürich', canton: 'ZH' },
  { npa: '8132', city: 'Hinteregg', canton: 'ZH' },
  { npa: '8133', city: 'Esslingen', canton: 'ZH' },
  { npa: '8117', city: 'Fällanden', canton: 'ZH' },
  { npa: '8118', city: 'Pfaffhausen', canton: 'ZH' },
  { npa: '8121', city: 'Benglen', canton: 'ZH' },
  { npa: '8606', city: 'Greifensee', canton: 'ZH' },
  { npa: '8122', city: 'Binz', canton: 'ZH' },
  { npa: '8123', city: 'Ebmatingen', canton: 'ZH' },
  { npa: '8124', city: 'Maur', canton: 'ZH' },
  { npa: '8617', city: 'Mönchaltorf', canton: 'ZH' },
  { npa: '8603', city: 'Schwerzenbach', canton: 'ZH' },
  { npa: '8606', city: 'Nänikon', canton: 'ZH' },
  { npa: '8610', city: 'Uster', canton: 'ZH' },
  { npa: '8614', city: 'Sulzbach', canton: 'ZH' },
  { npa: '8615', city: 'Wermatswil', canton: 'ZH' },
  { npa: '8615', city: 'Freudwil', canton: 'ZH' },
  { npa: '8616', city: 'Riedikon', canton: 'ZH' },
  { npa: '8604', city: 'Volketswil', canton: 'ZH' },
  { npa: '8605', city: 'Gutenswil', canton: 'ZH' },
  { npa: '8306', city: 'Brüttisellen', canton: 'ZH' },
  { npa: '8602', city: 'Wangen b. Dübendorf', canton: 'ZH' },
  { npa: '8479', city: 'Altikon', canton: 'ZH' },
  { npa: '8311', city: 'Brütten', canton: 'ZH' },
  { npa: '8471', city: 'Rutschwil (Dägerlen)', canton: 'ZH' },
  { npa: '8471', city: 'Dägerlen', canton: 'ZH' },
  { npa: '8471', city: 'Oberwil (Dägerlen)', canton: 'ZH' },
  { npa: '8471', city: 'Berg (Dägerlen)', canton: 'ZH' },
  { npa: '8471', city: 'Bänk (Dägerlen)', canton: 'ZH' },
  { npa: '8421', city: 'Dättlikon', canton: 'ZH' },
  { npa: '8474', city: 'Dinhard', canton: 'ZH' },
  { npa: '8548', city: 'Ellikon an der Thur', canton: 'ZH' },
  { npa: '8352', city: 'Elsau', canton: 'ZH' },
  { npa: '8523', city: 'Hagenbuch ZH', canton: 'ZH' },
  { npa: '8442', city: 'Hettlingen', canton: 'ZH' },
  { npa: '8412', city: 'Aesch (Neftenbach)', canton: 'ZH' },
  { npa: '8412', city: 'Riet (Neftenbach)', canton: 'ZH' },
  { npa: '8412', city: 'Hünikon (Neftenbach)', canton: 'ZH' },
  { npa: '8413', city: 'Neftenbach', canton: 'ZH' },
  { npa: '8422', city: 'Pfungen', canton: 'ZH' },
  { npa: '8545', city: 'Rickenbach ZH', canton: 'ZH' },
  { npa: '8545', city: 'Rickenbach Sulz', canton: 'ZH' },
  { npa: '8418', city: 'Schlatt ZH', canton: 'ZH' },
  { npa: '8472', city: 'Seuzach', canton: 'ZH' },
  { npa: '8488', city: 'Turbenthal', canton: 'ZH' },
  { npa: '8495', city: 'Schmidrüti', canton: 'ZH' },
  { npa: '8352', city: 'Ricketwil (Winterthur)', canton: 'ZH' },
  { npa: '8400', city: 'Winterthur', canton: 'ZH' },
  { npa: '8404', city: 'Winterthur', canton: 'ZH' },
  { npa: '8404', city: 'Reutlingen (Winterthur)', canton: 'ZH' },
  { npa: '8404', city: 'Stadel (Winterthur)', canton: 'ZH' },
  { npa: '8405', city: 'Winterthur', canton: 'ZH' },
  { npa: '8406', city: 'Winterthur', canton: 'ZH' },
  { npa: '8408', city: 'Winterthur', canton: 'ZH' },
  { npa: '8409', city: 'Winterthur', canton: 'ZH' },
  { npa: '8482', city: 'Sennhof (Winterthur)', canton: 'ZH' },
  { npa: '8483', city: 'Kollbrunn', canton: 'ZH' },
  { npa: '8486', city: 'Rikon im Tösstal', canton: 'ZH' },
  { npa: '8487', city: 'Zell ZH', canton: 'ZH' },
  { npa: '8487', city: 'Rämismühle', canton: 'ZH' },
  { npa: '8904', city: 'Aesch ZH', canton: 'ZH' },
  { npa: '8903', city: 'Birmensdorf ZH', canton: 'ZH' },
  { npa: '8953', city: 'Dietikon', canton: 'ZH' },
  { npa: '8951', city: 'Fahrweid', canton: 'ZH' },
  { npa: '8954', city: 'Geroldswil', canton: 'ZH' },
  { npa: '8102', city: 'Oberengstringen', canton: 'ZH' },
  { npa: '8955', city: 'Oetwil an der Limmat', canton: 'ZH' },
  { npa: '8952', city: 'Schlieren', canton: 'ZH' },
  { npa: '8142', city: 'Uitikon Waldegg', canton: 'ZH' },
  { npa: '8103', city: 'Unterengstringen', canton: 'ZH' },
  { npa: '8902', city: 'Urdorf', canton: 'ZH' },
  { npa: '8104', city: 'Weiningen ZH', canton: 'ZH' },
  { npa: '8001', city: 'Zürich', canton: 'ZH' },
  { npa: '8002', city: 'Zürich', canton: 'ZH' },
  { npa: '8003', city: 'Zürich', canton: 'ZH' },
  { npa: '8004', city: 'Zürich', canton: 'ZH' },
  { npa: '8005', city: 'Zürich', canton: 'ZH' },
  { npa: '8006', city: 'Zürich', canton: 'ZH' },
  { npa: '8008', city: 'Zürich', canton: 'ZH' },
  { npa: '8032', city: 'Zürich', canton: 'ZH' },
  { npa: '8037', city: 'Zürich', canton: 'ZH' },
  { npa: '8038', city: 'Zürich', canton: 'ZH' },
  { npa: '8041', city: 'Zürich', canton: 'ZH' },
  { npa: '8044', city: 'Zürich', canton: 'ZH' },
  { npa: '8045', city: 'Zürich', canton: 'ZH' },
  { npa: '8046', city: 'Zürich', canton: 'ZH' },
  { npa: '8047', city: 'Zürich', canton: 'ZH' },
  { npa: '8048', city: 'Zürich', canton: 'ZH' },
  { npa: '8049', city: 'Zürich', canton: 'ZH' },
  { npa: '8050', city: 'Zürich', canton: 'ZH' },
  { npa: '8051', city: 'Zürich', canton: 'ZH' },
  { npa: '8052', city: 'Zürich', canton: 'ZH' },
  { npa: '8053', city: 'Zürich', canton: 'ZH' },
  { npa: '8055', city: 'Zürich', canton: 'ZH' },
  { npa: '8057', city: 'Zürich', canton: 'ZH' },
  { npa: '8064', city: 'Zürich', canton: 'ZH' },
  { npa: '8468', city: 'Waltalingen', canton: 'ZH' },
  { npa: '8468', city: 'Guntalingen', canton: 'ZH' },
  { npa: '8476', city: 'Unterstammheim', canton: 'ZH' },
  { npa: '8477', city: 'Oberstammheim', canton: 'ZH' },
  { npa: '8525', city: 'Wilen b. Neunforn', canton: 'ZH' },
  { npa: '8804', city: 'Au ZH', canton: 'ZH' },
  { npa: '8820', city: 'Wädenswil', canton: 'ZH' },
  { npa: '8824', city: 'Schönenberg ZH', canton: 'ZH' },
  { npa: '8825', city: 'Hütten', canton: 'ZH' },
  { npa: '8353', city: 'Elgg', canton: 'ZH' },
  { npa: '8354', city: 'Hofstetten ZH', canton: 'ZH' },
  { npa: '8354', city: 'Dickbuch', canton: 'ZH' },
  { npa: '8135', city: 'Sihlbrugg Station', canton: 'ZH' },
  { npa: '8135', city: 'Sihlwald', canton: 'ZH' },
  { npa: '8810', city: 'Horgen', canton: 'ZH' },
  { npa: '8815', city: 'Horgenberg', canton: 'ZH' },
  { npa: '8816', city: 'Hirzel', canton: 'ZH' },
  { npa: '8307', city: 'Effretikon', canton: 'ZH' },
  { npa: '8307', city: 'Ottikon b. Kemptthal', canton: 'ZH' },
  { npa: '8308', city: 'Illnau', canton: 'ZH' },
  { npa: '8308', city: 'Agasul', canton: 'ZH' },
  { npa: '8314', city: 'Kyburg', canton: 'ZH' },
  { npa: '8493', city: 'Saland', canton: 'ZH' },
  { npa: '8494', city: 'Bauma', canton: 'ZH' },
  { npa: '8499', city: 'Sternenberg', canton: 'ZH' },
  { npa: '8542', city: 'Wiesendangen', canton: 'ZH' },
  { npa: '8543', city: 'Bertschikon', canton: 'ZH' },
  { npa: '8543', city: 'Gundetswil', canton: 'ZH' },
  { npa: '8543', city: 'Kefikon ZH', canton: 'ZH' },
  { npa: '8544', city: 'Attikon', canton: 'ZH' },
  { npa: '8546', city: 'Menzengrüt', canton: 'ZH' },
  { npa: '3270', city: 'Aarberg', canton: 'BE' },
  { npa: '3282', city: 'Bargen BE', canton: 'BE' },
  { npa: '3257', city: 'Grossaffoltern', canton: 'BE' },
  { npa: '3257', city: 'Ammerzwil BE', canton: 'BE' },
  { npa: '3262', city: 'Suberg', canton: 'BE' },
  { npa: '3207', city: 'Golaten', canton: 'BE' },
  { npa: '3283', city: 'Kallnach', canton: 'BE' },
  { npa: '3283', city: 'Niederried b. Kallnach', canton: 'BE' },
  { npa: '3273', city: 'Kappelen', canton: 'BE' },
  { npa: '3250', city: 'Lyss', canton: 'BE' },
  { npa: '3292', city: 'Busswil BE', canton: 'BE' },
  { npa: '3042', city: 'Ortschwaben', canton: 'BE' },
  { npa: '3045', city: 'Meikirch', canton: 'BE' },
  { npa: '3046', city: 'Wahlendorf', canton: 'BE' },
  { npa: '3036', city: 'Detligen', canton: 'BE' },
  { npa: '3271', city: 'Radelfingen b. Aarberg', canton: 'BE' },
  { npa: '3053', city: 'Lätti', canton: 'BE' },
  { npa: '3251', city: 'Ruppoldsried', canton: 'BE' },
  { npa: '3255', city: 'Rapperswil BE', canton: 'BE' },
  { npa: '3256', city: 'Dieterswil', canton: 'BE' },
  { npa: '3256', city: 'Bangerten b. Dieterswil', canton: 'BE' },
  { npa: '3256', city: 'Seewil', canton: 'BE' },
  { npa: '3054', city: 'Schüpfen', canton: 'BE' },
  { npa: '3035', city: 'Frieswil', canton: 'BE' },
  { npa: '3266', city: 'Wiler b. Seedorf', canton: 'BE' },
  { npa: '3267', city: 'Seedorf BE', canton: 'BE' },
  { npa: '3268', city: 'Lobsigen', canton: 'BE' },
  { npa: '4912', city: 'Aarwangen', canton: 'BE' },
  { npa: '4944', city: 'Auswil', canton: 'BE' },
  { npa: '4913', city: 'Bannwil', canton: 'BE' },
  { npa: '3368', city: 'Bleienbach', canton: 'BE' },
  { npa: '4917', city: 'Busswil b. Melchnau', canton: 'BE' },
  { npa: '4955', city: 'Gondiswil', canton: 'BE' },
  { npa: '4900', city: 'Langenthal', canton: 'BE' },
  { npa: '4916', city: 'Untersteckholz', canton: 'BE' },
  { npa: '4924', city: 'Obersteckholz', canton: 'BE' },
  { npa: '4932', city: 'Lotzwil', canton: 'BE' },
  { npa: '4932', city: 'Gutenburg', canton: 'BE' },
  { npa: '4934', city: 'Madiswil', canton: 'BE' },
  { npa: '4935', city: 'Leimiswil', canton: 'BE' },
  { npa: '4936', city: 'Kleindietwil', canton: 'BE' },
  { npa: '4917', city: 'Melchnau', canton: 'BE' },
  { npa: '4943', city: 'Oeschenbach', canton: 'BE' },
  { npa: '4919', city: 'Reisiswil', canton: 'BE' },
  { npa: '4914', city: 'Roggwil BE', canton: 'BE' },
  { npa: '4938', city: 'Rohrbach', canton: 'BE' },
  { npa: '4938', city: 'Rohrbachgraben', canton: 'BE' },
  { npa: '4933', city: 'Rütschelen', canton: 'BE' },
  { npa: '4911', city: 'Schwarzhäusern', canton: 'BE' },
  { npa: '4922', city: 'Bützberg', canton: 'BE' },
  { npa: '4922', city: 'Thunstetten', canton: 'BE' },
  { npa: '4937', city: 'Ursenbach', canton: 'BE' },
  { npa: '4923', city: 'Wynau', canton: 'BE' },
  { npa: '3004', city: 'Bern', canton: 'BE' },
  { npa: '3005', city: 'Bern', canton: 'BE' },
  { npa: '3006', city: 'Bern', canton: 'BE' },
  { npa: '3007', city: 'Bern', canton: 'BE' },
  { npa: '3008', city: 'Bern', canton: 'BE' },
  { npa: '3010', city: 'Bern', canton: 'BE' },
  { npa: '3011', city: 'Bern', canton: 'BE' },
  { npa: '3012', city: 'Bern', canton: 'BE' },
  { npa: '3013', city: 'Bern', canton: 'BE' },
  { npa: '3014', city: 'Bern', canton: 'BE' },
  { npa: '3015', city: 'Bern', canton: 'BE' },
  { npa: '3018', city: 'Bern', canton: 'BE' },
  { npa: '3019', city: 'Bern', canton: 'BE' },
  { npa: '3020', city: 'Bern', canton: 'BE' },
  { npa: '3027', city: 'Bern', canton: 'BE' },
  { npa: '3065', city: 'Bolligen', canton: 'BE' },
  { npa: '3047', city: 'Bremgarten b. Bern', canton: 'BE' },
  { npa: '3037', city: 'Herrenschwanden', canton: 'BE' },
  { npa: '3038', city: 'Kirchlindach', canton: 'BE' },
  { npa: '3084', city: 'Wabern', canton: 'BE' },
  { npa: '3095', city: 'Spiegel b. Bern', canton: 'BE' },
  { npa: '3097', city: 'Liebefeld', canton: 'BE' },
  { npa: '3098', city: 'Köniz', canton: 'BE' },
  { npa: '3098', city: 'Köniz', canton: 'BE' },
  { npa: '3098', city: 'Schliern b. Köniz', canton: 'BE' },
  { npa: '3144', city: 'Gasel', canton: 'BE' },
  { npa: '3145', city: 'Niederscherli', canton: 'BE' },
  { npa: '3147', city: 'Mittelhäusern', canton: 'BE' },
  { npa: '3172', city: 'Niederwangen b. Bern', canton: 'BE' },
  { npa: '3173', city: 'Oberwangen b. Bern', canton: 'BE' },
  { npa: '3174', city: 'Thörishaus', canton: 'BE' },
  { npa: '3073', city: 'Gümligen', canton: 'BE' },
  { npa: '3074', city: 'Muri b. Bern', canton: 'BE' },
  { npa: '3096', city: 'Oberbalm', canton: 'BE' },
  { npa: '3066', city: 'Stettlen', canton: 'BE' },
  { npa: '3067', city: 'Boll', canton: 'BE' },
  { npa: '3068', city: 'Utzigen', canton: 'BE' },
  { npa: '3032', city: 'Hinterkappelen', canton: 'BE' },
  { npa: '3033', city: 'Wohlen b. Bern', canton: 'BE' },
  { npa: '3034', city: 'Murzelen', canton: 'BE' },
  { npa: '3043', city: 'Uettligen', canton: 'BE' },
  { npa: '3044', city: 'Innerberg', canton: 'BE' },
  { npa: '3049', city: 'Säriswil', canton: 'BE' },
  { npa: '3052', city: 'Zollikofen', canton: 'BE' },
  { npa: '3048', city: 'Worblaufen', canton: 'BE' },
  { npa: '3063', city: 'Ittigen', canton: 'BE' },
  { npa: '3072', city: 'Ostermundigen', canton: 'BE' },
  { npa: '2502', city: 'Biel/Bienne', canton: 'BE' },
  { npa: '2503', city: 'Biel/Bienne', canton: 'BE' },
  { npa: '2504', city: 'Biel/Bienne', canton: 'BE' },
  { npa: '2505', city: 'Biel/Bienne', canton: 'BE' },
  { npa: '2532', city: 'Magglingen/Macolin', canton: 'BE' },
  { npa: '2533', city: 'Evilard', canton: 'BE' },
  { npa: '3296', city: 'Arch', canton: 'BE' },
  { npa: '3263', city: 'Büetigen', canton: 'BE' },
  { npa: '3294', city: 'Büren an der Aare', canton: 'BE' },
  { npa: '3264', city: 'Diessbach b. Büren', canton: 'BE' },
  { npa: '3293', city: 'Dotzigen', canton: 'BE' },
  { npa: '2543', city: 'Lengnau BE', canton: 'BE' },
  { npa: '3297', city: 'Leuzigen', canton: 'BE' },
  { npa: '3294', city: 'Meienried', canton: 'BE' },
  { npa: '2554', city: 'Meinisberg', canton: 'BE' },
  { npa: '3298', city: 'Oberwil b. Büren', canton: 'BE' },
  { npa: '2542', city: 'Pieterlen', canton: 'BE' },
  { npa: '3295', city: 'Rüti b. Büren', canton: 'BE' },
  { npa: '3251', city: 'Wengi b. Büren', canton: 'BE' },
  { npa: '3426', city: 'Aefligen', canton: 'BE' },
  { npa: '3473', city: 'Alchenstorf', canton: 'BE' },
  { npa: '3323', city: 'Bäriswil BE', canton: 'BE' },
  { npa: '3400', city: 'Burgdorf', canton: 'BE' },
  { npa: '3423', city: 'Ersigen', canton: 'BE' },
  { npa: '3424', city: 'Niederösch', canton: 'BE' },
  { npa: '3424', city: 'Oberösch', canton: 'BE' },
  { npa: '3415', city: 'Hasle b. Burgdorf', canton: 'BE' },
  { npa: '3415', city: 'Schafhausen im Emmental', canton: 'BE' },
  { npa: '3419', city: 'Biembach im Emmental', canton: 'BE' },
  { npa: '3412', city: 'Heimiswil', canton: 'BE' },
  { npa: '3413', city: 'Kaltacker', canton: 'BE' },
  { npa: '3429', city: 'Hellsau', canton: 'BE' },
  { npa: '3324', city: 'Hindelbank', canton: 'BE' },
  { npa: '3324', city: 'Mötschwil', canton: 'BE' },
  { npa: '3429', city: 'Höchstetten', canton: 'BE' },
  { npa: '3309', city: 'Kernenried', canton: 'BE' },
  { npa: '3422', city: 'Kirchberg BE', canton: 'BE' },
  { npa: '3425', city: 'Koppigen', canton: 'BE' },
  { npa: '3325', city: 'Hettiswil b. Hindelbank', canton: 'BE' },
  { npa: '3326', city: 'Krauchthal', canton: 'BE' },
  { npa: '3421', city: 'Lyssach', canton: 'BE' },
  { npa: '3414', city: 'Oberburg', canton: 'BE' },
  { npa: '3421', city: 'Lyssach', canton: 'BE' },
  { npa: '3422', city: 'Rüdtligen', canton: 'BE' },
  { npa: '3422', city: 'Alchenflüh', canton: 'BE' },
  { npa: '3472', city: 'Rumendingen', canton: 'BE' },
  { npa: '3421', city: 'Rüti b. Lyssach', canton: 'BE' },
  { npa: '3425', city: 'Willadingen', canton: 'BE' },
  { npa: '3472', city: 'Wynigen', canton: 'BE' },
  { npa: '3474', city: 'Rüedisbach', canton: 'BE' },
  { npa: '2606', city: 'Corgémont', canton: 'BE' },
  { npa: '2610', city: 'Mont-Crosin', canton: 'BE' },
  { npa: '2612', city: 'Cormoret', canton: 'BE' },
  { npa: '2607', city: 'Cortébert', canton: 'BE' },
  { npa: '2608', city: 'Montagne-de-Courtelary', canton: 'BE' },
  { npa: '2608', city: 'Courtelary', canton: 'BE' },
  { npa: '2333', city: 'La Ferrière', canton: 'BE' },
  { npa: '2723', city: 'Mont-Tramelan', canton: 'BE' },
  { npa: '2534', city: 'Orvin', canton: 'BE' },
  { npa: '2534', city: "Les Prés-d'Orvin", canton: 'BE' },
  { npa: '2616', city: 'Renan BE', canton: 'BE' },
  { npa: '2538', city: 'Romont BE', canton: 'BE' },
  { npa: '2610', city: 'St-Imier', canton: 'BE' },
  { npa: '2610', city: 'Mont-Soleil', canton: 'BE' },
  { npa: '2610', city: 'Les Pontins', canton: 'BE' },
  { npa: '2605', city: 'Sonceboz-Sombeval', canton: 'BE' },
  { npa: '2615', city: 'Sonvilier', canton: 'BE' },
  { npa: '2615', city: 'Montagne-de-Sonvilier', canton: 'BE' },
  { npa: '2720', city: 'Tramelan', canton: 'BE' },
  { npa: '2722', city: 'Les Reussilles', canton: 'BE' },
  { npa: '2613', city: 'Villeret', canton: 'BE' },
  { npa: '2535', city: 'Frinvillier', canton: 'BE' },
  { npa: '2536', city: 'Plagne', canton: 'BE' },
  { npa: '2537', city: 'Vauffelin', canton: 'BE' },
  { npa: '2603', city: 'Péry', canton: 'BE' },
  { npa: '2604', city: 'La Heutte', canton: 'BE' },
  { npa: '3237', city: 'Brüttelen', canton: 'BE' },
  { npa: '3235', city: 'Erlach', canton: 'BE' },
  { npa: '2577', city: 'Finsterhennen', canton: 'BE' },
  { npa: '3238', city: 'Gals', canton: 'BE' },
  { npa: '3236', city: 'Gampelen', canton: 'BE' },
  { npa: '3232', city: 'Ins', canton: 'BE' },
  { npa: '2576', city: 'Lüscherz', canton: 'BE' },
  { npa: '3225', city: 'Müntschemier', canton: 'BE' },
  { npa: '2577', city: 'Siselen BE', canton: 'BE' },
  { npa: '3226', city: 'Treiten', canton: 'BE' },
  { npa: '3233', city: 'Tschugg', canton: 'BE' },
  { npa: '3234', city: 'Vinelz', canton: 'BE' },
  { npa: '3315', city: 'Bätterkinden', canton: 'BE' },
  { npa: '3315', city: 'Kräiligen', canton: 'BE' },
  { npa: '3053', city: 'Deisswil b. Münchenbuchsee', canton: 'BE' },
  { npa: '3053', city: 'Diemerswil', canton: 'BE' },
  { npa: '3306', city: 'Etzelkofen', canton: 'BE' },
  { npa: '3308', city: 'Grafenried', canton: 'BE' },
  { npa: '3309', city: 'Zauggenried', canton: 'BE' },
  { npa: '3312', city: 'Fraubrunnen', canton: 'BE' },
  { npa: '3313', city: 'Büren zum Hof', canton: 'BE' },
  { npa: '3314', city: 'Schalunen', canton: 'BE' },
  { npa: '3317', city: 'Limpach', canton: 'BE' },
  { npa: '3317', city: 'Mülchi', canton: 'BE' },
  { npa: '3303', city: 'Jegenstorf', canton: 'BE' },
  { npa: '3303', city: 'Münchringen', canton: 'BE' },
  { npa: '3303', city: 'Ballmoos', canton: 'BE' },
  { npa: '3305', city: 'Scheunen', canton: 'BE' },
  { npa: '3305', city: 'Iffwil', canton: 'BE' },
  { npa: '3322', city: 'Mattstetten', canton: 'BE' },
  { npa: '3302', city: 'Moosseedorf', canton: 'BE' },
  { npa: '3053', city: 'Münchenbuchsee', canton: 'BE' },
  { npa: '3322', city: 'Urtenen-Schönbühl', canton: 'BE' },
  { npa: '3427', city: 'Utzenstorf', canton: 'BE' },
  { npa: '3053', city: 'Wiggiswil', canton: 'BE' },
  { npa: '3428', city: 'Wiler b. Utzenstorf', canton: 'BE' },
  { npa: '4564', city: 'Zielebach', canton: 'BE' },
  { npa: '3303', city: 'Zuzwil BE', canton: 'BE' },
  { npa: '3715', city: 'Adelboden', canton: 'BE' },
  { npa: '3703', city: 'Aeschi b. Spiez', canton: 'BE' },
  { npa: '3703', city: 'Aeschiried', canton: 'BE' },
  { npa: '3711', city: 'Emdthal', canton: 'BE' },
  { npa: '3714', city: 'Frutigen', canton: 'BE' },
  { npa: '3724', city: 'Ried (Frutigen)', canton: 'BE' },
  { npa: '3725', city: 'Achseten', canton: 'BE' },
  { npa: '3716', city: 'Kandergrund', canton: 'BE' },
  { npa: '3717', city: 'Blausee-Mitholz', canton: 'BE' },
  { npa: '3718', city: 'Kandersteg', canton: 'BE' },
  { npa: '3704', city: 'Krattigen', canton: 'BE' },
  { npa: '3711', city: 'Mülenen', canton: 'BE' },
  { npa: '3713', city: 'Reichenbach im Kandertal', canton: 'BE' },
  { npa: '3714', city: 'Wengi b. Frutigen', canton: 'BE' },
  { npa: '3722', city: 'Scharnachtal', canton: 'BE' },
  { npa: '3723', city: 'Kiental', canton: 'BE' },
  { npa: '3800', city: 'Sundlauenen', canton: 'BE' },
  { npa: '3803', city: 'Beatenberg', canton: 'BE' },
  { npa: '3806', city: 'Bönigen b. Interlaken', canton: 'BE' },
  { npa: '3855', city: 'Brienz BE', canton: 'BE' },
  { npa: '3855', city: 'Axalp', canton: 'BE' },
  { npa: '3856', city: 'Brienzwiler', canton: 'BE' },
  { npa: '3707', city: 'Därligen', canton: 'BE' },
  { npa: '3816', city: 'Burglauenen', canton: 'BE' },
  { npa: '3818', city: 'Grindelwald', canton: 'BE' },
  { npa: '3814', city: 'Gsteigwiler', canton: 'BE' },
  { npa: '3812', city: 'Wilderswil', canton: 'BE' },
  { npa: '3815', city: 'Zweilütschinen', canton: 'BE' },
  { npa: '3815', city: 'Gündlischwand', canton: 'BE' },
  { npa: '3804', city: 'Habkern', canton: 'BE' },
  { npa: '3858', city: 'Hofstetten b. Brienz', canton: 'BE' },
  { npa: '3800', city: 'Interlaken', canton: 'BE' },
  { npa: '3807', city: 'Iseltwald', canton: 'BE' },
  { npa: '3822', city: 'Lauterbrunnen', canton: 'BE' },
  { npa: '3822', city: 'Isenfluh', canton: 'BE' },
  { npa: '3823', city: 'Wengen', canton: 'BE' },
  { npa: '3823', city: 'Kleine Scheidegg', canton: 'BE' },
  { npa: '3823', city: 'Eigergletscher', canton: 'BE' },
  { npa: '3824', city: 'Stechelberg', canton: 'BE' },
  { npa: '3825', city: 'Mürren', canton: 'BE' },
  { npa: '3826', city: 'Gimmelwald', canton: 'BE' },
  { npa: '3706', city: 'Leissigen', canton: 'BE' },
  { npa: '3816', city: 'Lütschental', canton: 'BE' },
  { npa: '3800', city: 'Matten b. Interlaken', canton: 'BE' },
  { npa: '3853', city: 'Niederried b. Interlaken', canton: 'BE' },
  { npa: '3854', city: 'Oberried am Brienzersee', canton: 'BE' },
  { npa: '3805', city: 'Goldswil b. Interlaken', canton: 'BE' },
  { npa: '3852', city: 'Ringgenberg BE', canton: 'BE' },
  { npa: '3813', city: 'Saxeten', canton: 'BE' },
  { npa: '3855', city: 'Schwanden b. Brienz', canton: 'BE' },
  { npa: '3800', city: 'Unterseen', canton: 'BE' },
  { npa: '3812', city: 'Wilderswil', canton: 'BE' },
  { npa: '3508', city: 'Arni BE', canton: 'BE' },
  { npa: '3507', city: 'Biglen', canton: 'BE' },
  { npa: '3533', city: 'Bowil', canton: 'BE' },
  { npa: '3671', city: 'Brenzikofen', canton: 'BE' },
  { npa: '3510', city: 'Freimettigen', canton: 'BE' },
  { npa: '3082', city: 'Schlosswil', canton: 'BE' },
  { npa: '3506', city: 'Grosshöchstetten', canton: 'BE' },
  { npa: '3510', city: 'Häutligen', canton: 'BE' },
  { npa: '3671', city: 'Herbligen', canton: 'BE' },
  { npa: '3629', city: 'Kiesen', canton: 'BE' },
  { npa: '3503', city: 'Gysenstein', canton: 'BE' },
  { npa: '3510', city: 'Konolfingen', canton: 'BE' },
  { npa: '3434', city: 'Obergoldbach', canton: 'BE' },
  { npa: '3434', city: 'Landiswil', canton: 'BE' },
  { npa: '3673', city: 'Linden', canton: 'BE' },
  { npa: '3532', city: 'Mirchel', canton: 'BE' },
  { npa: '3083', city: 'Trimstein', canton: 'BE' },
  { npa: '3110', city: 'Münsingen', canton: 'BE' },
  { npa: '3111', city: 'Tägertschi', canton: 'BE' },
  { npa: '3504', city: 'Niederhünigen', canton: 'BE' },
  { npa: '3672', city: 'Oberdiessbach', canton: 'BE' },
  { npa: '3672', city: 'Aeschlen b. Oberdiessbach', canton: 'BE' },
  { npa: '3674', city: 'Bleiken b. Oberdiessbach', canton: 'BE' },
  { npa: '3531', city: 'Oberthal', canton: 'BE' },
  { npa: '3629', city: 'Oppligen', canton: 'BE' },
  { npa: '3113', city: 'Rubigen', canton: 'BE' },
  { npa: '3512', city: 'Walkringen', canton: 'BE' },
  { npa: '3513', city: 'Bigenthal', canton: 'BE' },
  { npa: '3075', city: 'Rüfenacht BE', canton: 'BE' },
  { npa: '3075', city: 'Vielbringen b. Worb', canton: 'BE' },
  { npa: '3076', city: 'Worb', canton: 'BE' },
  { npa: '3077', city: 'Enggistein', canton: 'BE' },
  { npa: '3078', city: 'Richigen', canton: 'BE' },
  { npa: '3532', city: 'Zäziwil', canton: 'BE' },
  { npa: '3504', city: 'Oberhünigen', canton: 'BE' },
  { npa: '3112', city: 'Allmendingen b. Bern', canton: 'BE' },
  { npa: '3114', city: 'Wichtrach', canton: 'BE' },
  { npa: '3206', city: 'Rizenbach', canton: 'BE' },
  { npa: '3206', city: 'Ferenbalm', canton: 'BE' },
  { npa: '3206', city: 'Biberen', canton: 'BE' },
  { npa: '3206', city: 'Gammen', canton: 'BE' },
  { npa: '3202', city: 'Frauenkappelen', canton: 'BE' },
  { npa: '3208', city: 'Gurbrü', canton: 'BE' },
  { npa: '3179', city: 'Kriechenwil', canton: 'BE' },
  { npa: '3177', city: 'Laupen BE', canton: 'BE' },
  { npa: '3203', city: 'Mühleberg', canton: 'BE' },
  { npa: '3204', city: 'Rosshäusern', canton: 'BE' },
  { npa: '3205', city: 'Gümmenen', canton: 'BE' },
  { npa: '1797', city: 'Münchenwiler', canton: 'BE' },
  { npa: '3176', city: 'Neuenegg', canton: 'BE' },
  { npa: '3207', city: 'Wileroltigen', canton: 'BE' },
  { npa: '2744', city: 'Belprahon', canton: 'BE' },
  { npa: '2735', city: 'Champoz', canton: 'BE' },
  { npa: '2747', city: 'Corcelles BE', canton: 'BE' },
  { npa: '2738', city: 'Court', canton: 'BE' },
  { npa: '2746', city: 'Crémines', canton: 'BE' },
  { npa: '2743', city: 'Eschert', canton: 'BE' },
  { npa: '2745', city: 'Grandval', canton: 'BE' },
  { npa: '2732', city: 'Loveresse', canton: 'BE' },
  { npa: '2740', city: 'Moutier', canton: 'BE' },
  { npa: '2742', city: 'Perrefitte', canton: 'BE' },
  { npa: '2732', city: 'Reconvilier', canton: 'BE' },
  { npa: '2762', city: 'Roches BE', canton: 'BE' },
  { npa: '2712', city: 'Le Fuet', canton: 'BE' },
  { npa: '2713', city: 'Bellelay', canton: 'BE' },
  { npa: '2732', city: 'Saicourt', canton: 'BE' },
  { npa: '2732', city: 'Saules BE', canton: 'BE' },
  { npa: '2827', city: 'Schelten', canton: 'BE' },
  { npa: '2747', city: 'Seehof', canton: 'BE' },
  { npa: '2736', city: 'Sorvilier', canton: 'BE' },
  { npa: '2710', city: 'Tavannes', canton: 'BE' },
  { npa: '2720', city: 'La Tanne', canton: 'BE' },
  { npa: '2717', city: 'Rebévelier', canton: 'BE' },
  { npa: '2715', city: 'Châtelat', canton: 'BE' },
  { npa: '2715', city: 'Monible', canton: 'BE' },
  { npa: '2716', city: 'Sornetan', canton: 'BE' },
  { npa: '2717', city: 'Fornet-Dessous', canton: 'BE' },
  { npa: '2748', city: 'Souboz', canton: 'BE' },
  { npa: '2748', city: 'Les Ecorcheresses', canton: 'BE' },
  { npa: '2733', city: 'Pontenet', canton: 'BE' },
  { npa: '2735', city: 'Bévilard', canton: 'BE' },
  { npa: '2735', city: 'Malleray', canton: 'BE' },
  { npa: '2520', city: 'La Neuveville', canton: 'BE' },
  { npa: '2518', city: 'Nods', canton: 'BE' },
  { npa: '2515', city: 'Prêles', canton: 'BE' },
  { npa: '2516', city: 'Lamboing', canton: 'BE' },
  { npa: '2517', city: 'Diesse', canton: 'BE' },
  { npa: '2558', city: 'Aegerten', canton: 'BE' },
  { npa: '2564', city: 'Bellmund', canton: 'BE' },
  { npa: '2555', city: 'Brügg BE', canton: 'BE' },
  { npa: '3274', city: 'Bühl b. Aarberg', canton: 'BE' },
  { npa: '3272', city: 'Epsach', canton: 'BE' },
  { npa: '2575', city: 'Hagneck', canton: 'BE' },
  { npa: '3274', city: 'Hermrigen', canton: 'BE' },
  { npa: '2565', city: 'Jens', canton: 'BE' },
  { npa: '2563', city: 'Ipsach', canton: 'BE' },
  { npa: '2514', city: 'Ligerz', canton: 'BE' },
  { npa: '3274', city: 'Merzligen', canton: 'BE' },
  { npa: '2572', city: 'Mörigen', canton: 'BE' },
  { npa: '2560', city: 'Nidau', canton: 'BE' },
  { npa: '2552', city: 'Orpund', canton: 'BE' },
  { npa: '2562', city: 'Port', canton: 'BE' },
  { npa: '2553', city: 'Safnern', canton: 'BE' },
  { npa: '2556', city: 'Scheuren', canton: 'BE' },
  { npa: '2556', city: 'Schwadernau', canton: 'BE' },
  { npa: '2557', city: 'Studen BE', canton: 'BE' },
  { npa: '2572', city: 'Sutz', canton: 'BE' },
  { npa: '2575', city: 'Täuffelen', canton: 'BE' },
  { npa: '2575', city: 'Gerolfingen', canton: 'BE' },
  { npa: '3272', city: 'Walperswil', canton: 'BE' },
  { npa: '3252', city: 'Worben', canton: 'BE' },
  { npa: '2512', city: 'Tüscherz-Alfermée', canton: 'BE' },
  { npa: '2513', city: 'Twann', canton: 'BE' },
  { npa: '3763', city: 'Därstetten', canton: 'BE' },
  { npa: '3764', city: 'Weissenburg', canton: 'BE' },
  { npa: '3753', city: 'Oey', canton: 'BE' },
  { npa: '3754', city: 'Diemtigen', canton: 'BE' },
  { npa: '3755', city: 'Horboden', canton: 'BE' },
  { npa: '3756', city: 'Zwischenflüh', canton: 'BE' },
  { npa: '3757', city: 'Schwenden im Diemtigtal', canton: 'BE' },
  { npa: '3758', city: 'Latterbach', canton: 'BE' },
  { npa: '3762', city: 'Erlenbach im Simmental', canton: 'BE' },
  { npa: '3765', city: 'Oberwil im Simmental', canton: 'BE' },
  { npa: '3647', city: 'Reutigen', canton: 'BE' },
  { npa: '3646', city: 'Einigen', canton: 'BE' },
  { npa: '3700', city: 'Spiez', canton: 'BE' },
  { npa: '3702', city: 'Hondrich', canton: 'BE' },
  { npa: '3705', city: 'Faulensee', canton: 'BE' },
  { npa: '3752', city: 'Wimmis', canton: 'BE' },
  { npa: '3631', city: 'Höfen b. Thun', canton: 'BE' },
  { npa: '3632', city: 'Niederstocken', canton: 'BE' },
  { npa: '3632', city: 'Oberstocken', canton: 'BE' },
  { npa: '3864', city: 'Guttannen', canton: 'BE' },
  { npa: '6083', city: 'Hasliberg Hohfluh', canton: 'BE' },
  { npa: '6084', city: 'Hasliberg Wasserwendi', canton: 'BE' },
  { npa: '6085', city: 'Hasliberg Goldern', canton: 'BE' },
  { npa: '6086', city: 'Hasliberg Reuti', canton: 'BE' },
  { npa: '3862', city: 'Innertkirchen', canton: 'BE' },
  { npa: '3863', city: 'Gadmen', canton: 'BE' },
  { npa: '3857', city: 'Unterbach BE', canton: 'BE' },
  { npa: '3860', city: 'Meiringen', canton: 'BE' },
  { npa: '3860', city: 'Brünig', canton: 'BE' },
  { npa: '3860', city: 'Rosenlaui', canton: 'BE' },
  { npa: '3860', city: 'Schattenhalb', canton: 'BE' },
  { npa: '3766', city: 'Boltigen', canton: 'BE' },
  { npa: '3775', city: 'Lenk im Simmental', canton: 'BE' },
  { npa: '3772', city: 'St. Stephan', canton: 'BE' },
  { npa: '3773', city: 'Matten (St. Stephan)', canton: 'BE' },
  { npa: '3770', city: 'Zweisimmen', canton: 'BE' },
  { npa: '3771', city: 'Blankenburg', canton: 'BE' },
  { npa: '3776', city: 'Oeschseite', canton: 'BE' },
  { npa: '3784', city: 'Feutersoey', canton: 'BE' },
  { npa: '3785', city: 'Gsteig b. Gstaad', canton: 'BE' },
  { npa: '3782', city: 'Lauenen b. Gstaad', canton: 'BE' },
  { npa: '1657', city: 'Abländschen', canton: 'BE' },
  { npa: '3777', city: 'Saanenmöser', canton: 'BE' },
  { npa: '3778', city: 'Schönried', canton: 'BE' },
  { npa: '3780', city: 'Gstaad', canton: 'BE' },
  { npa: '3781', city: 'Turbach', canton: 'BE' },
  { npa: '3783', city: 'Grund b. Gstaad', canton: 'BE' },
  { npa: '3792', city: 'Saanen', canton: 'BE' },
  { npa: '1738', city: 'Sangernboden', canton: 'BE' },
  { npa: '3156', city: 'Riffenmatt', canton: 'BE' },
  { npa: '3158', city: 'Guggisberg', canton: 'BE' },
  { npa: '3159', city: 'Riedstätt', canton: 'BE' },
  { npa: '3153', city: 'Rüschegg Gambach', canton: 'BE' },
  { npa: '3154', city: 'Rüschegg Heubach', canton: 'BE' },
  { npa: '3148', city: 'Lanzenhäusern', canton: 'BE' },
  { npa: '3150', city: 'Schwarzenburg', canton: 'BE' },
  { npa: '3152', city: 'Mamishaus', canton: 'BE' },
  { npa: '3157', city: 'Milken', canton: 'BE' },
  { npa: '3183', city: 'Albligen', canton: 'BE' },
  { npa: '3123', city: 'Belp', canton: 'BE' },
  { npa: '3124', city: 'Belpberg', canton: 'BE' },
  { npa: '3664', city: 'Burgistein', canton: 'BE' },
  { npa: '3115', city: 'Gerzensee', canton: 'BE' },
  { npa: '3663', city: 'Gurzelen', canton: 'BE' },
  { npa: '3629', city: 'Jaberg', canton: 'BE' },
  { npa: '3126', city: 'Kaufdorf', canton: 'BE' },
  { npa: '3122', city: 'Kehrsatz', canton: 'BE' },
  { npa: '3116', city: 'Kirchdorf BE', canton: 'BE' },
  { npa: '3116', city: 'Mühledorf BE', canton: 'BE' },
  { npa: '3116', city: 'Noflen BE', canton: 'BE' },
  { npa: '3126', city: 'Gelterfingen', canton: 'BE' },
  { npa: '3087', city: 'Niedermuhlern', canton: 'BE' },
  { npa: '3099', city: 'Rüti b. Riggisberg', canton: 'BE' },
  { npa: '3128', city: 'Rümligen', canton: 'BE' },
  { npa: '3132', city: 'Riggisberg', canton: 'BE' },
  { npa: '3088', city: 'Rüeggisberg', canton: 'BE' },
  { npa: '3088', city: 'Oberbütschel', canton: 'BE' },
  { npa: '3089', city: 'Hinterfultigen', canton: 'BE' },
  { npa: '3155', city: 'Helgisried-Rohrbach', canton: 'BE' },
  { npa: '3662', city: 'Seftigen', canton: 'BE' },
  { npa: '3125', city: 'Toffen', canton: 'BE' },
  { npa: '3628', city: 'Uttigen', canton: 'BE' },
  { npa: '3665', city: 'Wattenwil', canton: 'BE' },
  { npa: '3086', city: 'Zimmerwald', canton: 'BE' },
  { npa: '3086', city: 'Englisberg', canton: 'BE' },
  { npa: '3127', city: 'Mühlethurnen', canton: 'BE' },
  { npa: '3127', city: 'Lohnstorf', canton: 'BE' },
  { npa: '3128', city: 'Kirchenthurnen', canton: 'BE' },
  { npa: '3536', city: 'Aeschau', canton: 'BE' },
  { npa: '3537', city: 'Eggiwil', canton: 'BE' },
  { npa: '3550', city: 'Langnau im Emmental', canton: 'BE' },
  { npa: '3551', city: 'Oberfrittenbach', canton: 'BE' },
  { npa: '3552', city: 'Bärau', canton: 'BE' },
  { npa: '3553', city: 'Gohl', canton: 'BE' },
  { npa: '3438', city: 'Lauperswil', canton: 'BE' },
  { npa: '3543', city: 'Emmenmatt', canton: 'BE' },
  { npa: '3538', city: 'Röthenbach im Emmental', canton: 'BE' },
  { npa: '3433', city: 'Schwanden im Emmental', canton: 'BE' },
  { npa: '3436', city: 'Zollbrück', canton: 'BE' },
  { npa: '3437', city: 'Rüderswil', canton: 'BE' },
  { npa: '6197', city: 'Schangnau', canton: 'BE' },
  { npa: '3534', city: 'Signau', canton: 'BE' },
  { npa: '3535', city: 'Schüpbach', canton: 'BE' },
  { npa: '3556', city: 'Trub', canton: 'BE' },
  { npa: '3557', city: 'Fankhaus (Trub)', canton: 'BE' },
  { npa: '3555', city: 'Trubschachen', canton: 'BE' },
  { npa: '3633', city: 'Amsoldingen', canton: 'BE' },
  { npa: '3638', city: 'Blumenstein', canton: 'BE' },
  { npa: '3615', city: 'Heimenschwand', canton: 'BE' },
  { npa: '3619', city: 'Eriz', canton: 'BE' },
  { npa: '3617', city: 'Fahrni b. Thun', canton: 'BE' },
  { npa: '3625', city: 'Heiligenschwendi', canton: 'BE' },
  { npa: '3627', city: 'Heimberg', canton: 'BE' },
  { npa: '3626', city: 'Hünibach', canton: 'BE' },
  { npa: '3652', city: 'Hilterfingen', canton: 'BE' },
  { npa: '3622', city: 'Homberg b. Thun', canton: 'BE' },
  { npa: '3623', city: 'Horrenbach', canton: 'BE' },
  { npa: '3623', city: 'Buchen BE', canton: 'BE' },
  { npa: '3653', city: 'Oberhofen am Thunersee', canton: 'BE' },
  { npa: '3638', city: 'Pohlern', canton: 'BE' },
  { npa: '3654', city: 'Gunten', canton: 'BE' },
  { npa: '3655', city: 'Sigriswil', canton: 'BE' },
  { npa: '3656', city: 'Tschingel ob Gunten', canton: 'BE' },
  { npa: '3656', city: 'Aeschlen ob Gunten', canton: 'BE' },
  { npa: '3656', city: 'Ringoldswil', canton: 'BE' },
  { npa: '3657', city: 'Schwanden (Sigriswil)', canton: 'BE' },
  { npa: '3658', city: 'Merligen', canton: 'BE' },
  { npa: '3612', city: 'Steffisburg', canton: 'BE' },
  { npa: '3613', city: 'Steffisburg', canton: 'BE' },
  { npa: '3624', city: 'Schwendibach', canton: 'BE' },
  { npa: '3623', city: 'Teuffenthal b. Thun', canton: 'BE' },
  { npa: '3634', city: 'Thierachern', canton: 'BE' },
  { npa: '3600', city: 'Thun', canton: 'BE' },
  { npa: '3603', city: 'Thun', canton: 'BE' },
  { npa: '3604', city: 'Thun', canton: 'BE' },
  { npa: '3608', city: 'Thun', canton: 'BE' },
  { npa: '3624', city: 'Goldiwil (Thun)', canton: 'BE' },
  { npa: '3645', city: 'Gwatt (Thun)', canton: 'BE' },
  { npa: '3635', city: 'Uebeschi', canton: 'BE' },
  { npa: '3661', city: 'Uetendorf', canton: 'BE' },
  { npa: '3614', city: 'Unterlangenegg', canton: 'BE' },
  { npa: '3616', city: 'Schwarzenegg', canton: 'BE' },
  { npa: '3618', city: 'Süderen', canton: 'BE' },
  { npa: '3645', city: 'Zwieselberg', canton: 'BE' },
  { npa: '3636', city: 'Längenbühl', canton: 'BE' },
  { npa: '3636', city: 'Forst b. Längenbühl', canton: 'BE' },
  { npa: '3416', city: 'Affoltern im Emmental', canton: 'BE' },
  { npa: '3462', city: 'Weier im Emmental', canton: 'BE' },
  { npa: '3463', city: 'Häusernmoos im Emmental', canton: 'BE' },
  { npa: '3465', city: 'Dürrenroth', canton: 'BE' },
  { npa: '4952', city: 'Eriswil', canton: 'BE' },
  { npa: '4950', city: 'Huttwil', canton: 'BE' },
  { npa: '4953', city: 'Schwarzenbach (Huttwil)', canton: 'BE' },
  { npa: '3432', city: 'Lützelflüh-Goldbach', canton: 'BE' },
  { npa: '3435', city: 'Ramsei', canton: 'BE' },
  { npa: '3439', city: 'Ranflüh', canton: 'BE' },
  { npa: '3452', city: 'Grünenmatt', canton: 'BE' },
  { npa: '3415', city: 'Rüegsauschachen', canton: 'BE' },
  { npa: '3417', city: 'Rüegsau', canton: 'BE' },
  { npa: '3418', city: 'Rüegsbach', canton: 'BE' },
  { npa: '3454', city: 'Sumiswald', canton: 'BE' },
  { npa: '3455', city: 'Grünen', canton: 'BE' },
  { npa: '3457', city: 'Wasen im Emmental', canton: 'BE' },
  { npa: '3453', city: 'Heimisbach', canton: 'BE' },
  { npa: '3456', city: 'Trachselwald', canton: 'BE' },
  { npa: '3464', city: 'Schmidigen-Mühleweg', canton: 'BE' },
  { npa: '4942', city: 'Walterswil BE', canton: 'BE' },
  { npa: '4954', city: 'Wyssachen', canton: 'BE' },
  { npa: '4536', city: 'Attiswil', canton: 'BE' },
  { npa: '3376', city: 'Berken', canton: 'BE' },
  { npa: '3366', city: 'Bettenhausen', canton: 'BE' },
  { npa: '3366', city: 'Bollodingen', canton: 'BE' },
  { npa: '4539', city: 'Farnern', canton: 'BE' },
  { npa: '3376', city: 'Graben', canton: 'BE' },
  { npa: '3372', city: 'Wanzwil', canton: 'BE' },
  { npa: '3373', city: 'Röthenbach Herzogenbuchsee', canton: 'BE' },
  { npa: '3373', city: 'Heimenhausen', canton: 'BE' },
  { npa: '3360', city: 'Herzogenbuchsee', canton: 'BE' },
  { npa: '3363', city: 'Oberönz', canton: 'BE' },
  { npa: '3375', city: 'Inkwil', canton: 'BE' },
  { npa: '4704', city: 'Niederbipp', canton: 'BE' },
  { npa: '4704', city: 'Wolfisberg', canton: 'BE' },
  { npa: '3362', city: 'Niederönz', canton: 'BE' },
  { npa: '4538', city: 'Oberbipp', canton: 'BE' },
  { npa: '3367', city: 'Ochlenberg', canton: 'BE' },
  { npa: '3476', city: 'Oschwand', canton: 'BE' },
  { npa: '4539', city: 'Rumisberg', canton: 'BE' },
  { npa: '3365', city: 'Grasswil', canton: 'BE' },
  { npa: '3365', city: 'Seeberg', canton: 'BE' },
  { npa: '3475', city: 'Riedtwil', canton: 'BE' },
  { npa: '3475', city: 'Hermiswil', canton: 'BE' },
  { npa: '3367', city: 'Thörigen', canton: 'BE' },
  { npa: '3380', city: 'Walliswil b. Niederbipp', canton: 'BE' },
  { npa: '3377', city: 'Walliswil b. Wangen', canton: 'BE' },
  { npa: '3380', city: 'Wangen an der Aare', canton: 'BE' },
  { npa: '3374', city: 'Wangenried', canton: 'BE' },
  { npa: '4537', city: 'Wiedlisbach', canton: 'BE' },
  { npa: '6112', city: 'Doppleschwand', canton: 'LU' },
  { npa: '6162', city: 'Entlebuch', canton: 'LU' },
  { npa: '6162', city: 'Rengg', canton: 'LU' },
  { npa: '6162', city: 'Finsterwald b. Entlebuch', canton: 'LU' },
  { npa: '6163', city: 'Ebnet', canton: 'LU' },
  { npa: '6173', city: 'Flühli LU', canton: 'LU' },
  { npa: '6174', city: 'Sörenberg', canton: 'LU' },
  { npa: '6166', city: 'Hasle LU', canton: 'LU' },
  { npa: '6110', city: 'Fontannen b. Wolhusen', canton: 'LU' },
  { npa: '6113', city: 'Romoos', canton: 'LU' },
  { npa: '6167', city: 'Bramboden', canton: 'LU' },
  { npa: '6170', city: 'Schüpfheim', canton: 'LU' },
  { npa: '6105', city: 'Schachen LU', canton: 'LU' },
  { npa: '6106', city: 'Werthenstein', canton: 'LU' },
  { npa: '6182', city: 'Escholzmatt', canton: 'LU' },
  { npa: '6192', city: 'Wiggen', canton: 'LU' },
  { npa: '6196', city: 'Marbach LU', canton: 'LU' },
  { npa: '6287', city: 'Aesch LU', canton: 'LU' },
  { npa: '6275', city: 'Ballwil', canton: 'LU' },
  { npa: '6020', city: 'Emmenbrücke', canton: 'LU' },
  { npa: '6032', city: 'Emmen', canton: 'LU' },
  { npa: '6294', city: 'Ermensee', canton: 'LU' },
  { npa: '6274', city: 'Eschenbach LU', canton: 'LU' },
  { npa: '6284', city: 'Gelfingen', canton: 'LU' },
  { npa: '6284', city: 'Sulz LU', canton: 'LU' },
  { npa: '6285', city: 'Hitzkirch', canton: 'LU' },
  { npa: '6285', city: 'Retschwil', canton: 'LU' },
  { npa: '6286', city: 'Altwis', canton: 'LU' },
  { npa: '6289', city: 'Müswangen', canton: 'LU' },
  { npa: '6289', city: 'Hämikon', canton: 'LU' },
  { npa: '6289', city: 'Hämikon', canton: 'LU' },
  { npa: '6295', city: 'Mosen', canton: 'LU' },
  { npa: '6280', city: 'Hochdorf', canton: 'LU' },
  { npa: '6280', city: 'Urswil', canton: 'LU' },
  { npa: '6283', city: 'Baldegg', canton: 'LU' },
  { npa: '6276', city: 'Hohenrain', canton: 'LU' },
  { npa: '6277', city: 'Kleinwangen', canton: 'LU' },
  { npa: '6277', city: 'Lieli LU', canton: 'LU' },
  { npa: '6034', city: 'Inwil', canton: 'LU' },
  { npa: '6026', city: 'Rain', canton: 'LU' },
  { npa: '6027', city: 'Römerswil LU', canton: 'LU' },
  { npa: '6028', city: 'Herlisberg', canton: 'LU' },
  { npa: '6023', city: 'Rothenburg', canton: 'LU' },
  { npa: '6288', city: 'Schongau', canton: 'LU' },
  { npa: '6043', city: 'Adligenswil', canton: 'LU' },
  { npa: '6033', city: 'Buchrain', canton: 'LU' },
  { npa: '6035', city: 'Perlen', canton: 'LU' },
  { npa: '6036', city: 'Dierikon', canton: 'LU' },
  { npa: '6030', city: 'Ebikon', canton: 'LU' },
  { npa: '6038', city: 'Gisikon', canton: 'LU' },
  { npa: '6404', city: 'Greppen', canton: 'LU' },
  { npa: '6038', city: 'Honau', canton: 'LU' },
  { npa: '6005', city: 'St. Niklausen LU', canton: 'LU' },
  { npa: '6047', city: 'Kastanienbaum', canton: 'LU' },
  { npa: '6048', city: 'Horw', canton: 'LU' },
  { npa: '6010', city: 'Kriens', canton: 'LU' },
  { npa: '6012', city: 'Obernau', canton: 'LU' },
  { npa: '6003', city: 'Luzern', canton: 'LU' },
  { npa: '6004', city: 'Luzern', canton: 'LU' },
  { npa: '6005', city: 'Luzern', canton: 'LU' },
  { npa: '6006', city: 'Luzern', canton: 'LU' },
  { npa: '6014', city: 'Luzern', canton: 'LU' },
  { npa: '6015', city: 'Luzern', canton: 'LU' },
  { npa: '6102', city: 'Malters', canton: 'LU' },
  { npa: '6045', city: 'Meggen', canton: 'LU' },
  { npa: '6344', city: 'Meierskappel', canton: 'LU' },
  { npa: '6037', city: 'Root', canton: 'LU' },
  { npa: '6039', city: 'Root D4', canton: 'LU' },
  { npa: '6013', city: 'Eigenthal', canton: 'LU' },
  { npa: '6103', city: 'Schwarzenberg LU', canton: 'LU' },
  { npa: '6044', city: 'Udligenswil', canton: 'LU' },
  { npa: '6354', city: 'Vitznau', canton: 'LU' },
  { npa: '6353', city: 'Weggis', canton: 'LU' },
  { npa: '6356', city: 'Rigi Kaltbad', canton: 'LU' },
  { npa: '6025', city: 'Neudorf', canton: 'LU' },
  { npa: '6215', city: 'Beromünster', canton: 'LU' },
  { npa: '6215', city: 'Schwarzenbach LU', canton: 'LU' },
  { npa: '6222', city: 'Gunzwil', canton: 'LU' },
  { npa: '6233', city: 'Büron', canton: 'LU' },
  { npa: '6018', city: 'Buttisholz', canton: 'LU' },
  { npa: '6205', city: 'Eich', canton: 'LU' },
  { npa: '6232', city: 'Geuensee', canton: 'LU' },
  { npa: '6022', city: 'Grosswangen', canton: 'LU' },
  { npa: '6024', city: 'Hildisrieden', canton: 'LU' },
  { npa: '6212', city: 'St. Erhard', canton: 'LU' },
  { npa: '6213', city: 'Knutwil', canton: 'LU' },
  { npa: '6212', city: 'Kaltbach', canton: 'LU' },
  { npa: '6216', city: 'Mauensee', canton: 'LU' },
  { npa: '6016', city: 'Hellbühl', canton: 'LU' },
  { npa: '6203', city: 'Sempach Station', canton: 'LU' },
  { npa: '6206', city: 'Neuenkirch', canton: 'LU' },
  { npa: '6207', city: 'Nottwil', canton: 'LU' },
  { npa: '6208', city: 'Oberkirch LU', canton: 'LU' },
  { npa: '5735', city: 'Pfeffikon LU', canton: 'LU' },
  { npa: '6221', city: 'Rickenbach LU', canton: 'LU' },
  { npa: '6017', city: 'Ruswil', canton: 'LU' },
  { npa: '6019', city: 'Sigigen', canton: 'LU' },
  { npa: '6214', city: 'Schenkon', canton: 'LU' },
  { npa: '6231', city: 'Schlierbach', canton: 'LU' },
  { npa: '6204', city: 'Sempach', canton: 'LU' },
  { npa: '6210', city: 'Sursee', canton: 'LU' },
  { npa: '6234', city: 'Triengen', canton: 'LU' },
  { npa: '6234', city: 'Kulmerau', canton: 'LU' },
  { npa: '6235', city: 'Winikon', canton: 'LU' },
  { npa: '6236', city: 'Wilihof', canton: 'LU' },
  { npa: '6110', city: 'Wolhusen', canton: 'LU' },
  { npa: '6114', city: 'Steinhuserberg', canton: 'LU' },
  { npa: '6248', city: 'Alberswil', canton: 'LU' },
  { npa: '6147', city: 'Altbüron', canton: 'LU' },
  { npa: '6245', city: 'Ebersecken', canton: 'LU' },
  { npa: '6246', city: 'Altishofen', canton: 'LU' },
  { npa: '6211', city: 'Buchs LU', canton: 'LU' },
  { npa: '6252', city: 'Dagmersellen', canton: 'LU' },
  { npa: '6253', city: 'Uffikon', canton: 'LU' },
  { npa: '6243', city: 'Egolzwil', canton: 'LU' },
  { npa: '6217', city: 'Kottwil', canton: 'LU' },
  { npa: '6218', city: 'Ettiswil', canton: 'LU' },
  { npa: '6145', city: 'Fischbach LU', canton: 'LU' },
  { npa: '6146', city: 'Grossdietwil', canton: 'LU' },
  { npa: '6133', city: 'Hergiswil b. Willisau', canton: 'LU' },
  { npa: '6154', city: 'Hofstatt', canton: 'LU' },
  { npa: '6156', city: 'Luthern', canton: 'LU' },
  { npa: '6156', city: 'Luthern Bad', canton: 'LU' },
  { npa: '6122', city: 'Menznau', canton: 'LU' },
  { npa: '6123', city: 'Geiss', canton: 'LU' },
  { npa: '6125', city: 'Menzberg', canton: 'LU' },
  { npa: '6244', city: 'Nebikon', canton: 'LU' },
  { npa: '4915', city: 'St. Urban', canton: 'LU' },
  { npa: '6264', city: 'Pfaffnau', canton: 'LU' },
  { npa: '6260', city: 'Reiden', canton: 'LU' },
  { npa: '6260', city: 'Reidermoos', canton: 'LU' },
  { npa: '6260', city: 'Mehlsecken', canton: 'LU' },
  { npa: '6262', city: 'Langnau b. Reiden', canton: 'LU' },
  { npa: '6263', city: 'Richenthal', canton: 'LU' },
  { npa: '6265', city: 'Roggliswil', canton: 'LU' },
  { npa: '6143', city: 'Ohmstal', canton: 'LU' },
  { npa: '6247', city: 'Schötz', canton: 'LU' },
  { npa: '6153', city: 'Ufhusen', canton: 'LU' },
  { npa: '6242', city: 'Wauwil', canton: 'LU' },
  { npa: '4806', city: 'Wikon', canton: 'LU' },
  { npa: '6260', city: 'Hintermoos', canton: 'LU' },
  { npa: '6144', city: 'Zell LU', canton: 'LU' },
  { npa: '6152', city: 'Hüswil', canton: 'LU' },
  { npa: '6126', city: 'Daiwil', canton: 'LU' },
  { npa: '6130', city: 'Willisau', canton: 'LU' },
  { npa: '6132', city: 'Rohrmatt', canton: 'LU' },
  { npa: '6142', city: 'Gettnau', canton: 'LU' },
  { npa: '6460', city: 'Altdorf UR', canton: 'UR' },
  { npa: '6490', city: 'Andermatt', canton: 'UR' },
  { npa: '6468', city: 'Attinghausen', canton: 'UR' },
  { npa: '6463', city: 'Bürglen UR', canton: 'UR' },
  { npa: '6472', city: 'Erstfeld', canton: 'UR' },
  { npa: '6454', city: 'Flüelen', canton: 'UR' },
  { npa: '6487', city: 'Göschenen', canton: 'UR' },
  { npa: '6476', city: 'Intschi', canton: 'UR' },
  { npa: '6482', city: 'Gurtnellen', canton: 'UR' },
  { npa: '6493', city: 'Hospental', canton: 'UR' },
  { npa: '6461', city: 'Isenthal', canton: 'UR' },
  { npa: '6491', city: 'Realp', canton: 'UR' },
  { npa: '6467', city: 'Schattdorf', canton: 'UR' },
  { npa: '6469', city: 'Haldi b. Schattdorf', canton: 'UR' },
  { npa: '6462', city: 'Seedorf UR', canton: 'UR' },
  { npa: '6466', city: 'Bauen', canton: 'UR' },
  { npa: '6377', city: 'Seelisberg', canton: 'UR' },
  { npa: '6441', city: 'Rütli', canton: 'UR' },
  { npa: '6473', city: 'Silenen', canton: 'UR' },
  { npa: '6474', city: 'Amsteg', canton: 'UR' },
  { npa: '6475', city: 'Bristen', canton: 'UR' },
  { npa: '6452', city: 'Sisikon', canton: 'UR' },
  { npa: '6464', city: 'Spiringen', canton: 'UR' },
  { npa: '8751', city: 'Urnerboden', canton: 'UR' },
  { npa: '6465', city: 'Unterschächen', canton: 'UR' },
  { npa: '6484', city: 'Wassen UR', canton: 'UR' },
  { npa: '6485', city: 'Meien', canton: 'UR' },
  { npa: '8836', city: 'Bennau', canton: 'SZ' },
  { npa: '8840', city: 'Einsiedeln', canton: 'SZ' },
  { npa: '8840', city: 'Trachslau', canton: 'SZ' },
  { npa: '8841', city: 'Gross', canton: 'SZ' },
  { npa: '8844', city: 'Euthal', canton: 'SZ' },
  { npa: '8846', city: 'Willerzell', canton: 'SZ' },
  { npa: '8847', city: 'Egg SZ', canton: 'SZ' },
  { npa: '6410', city: 'Rigi Scheidegg', canton: 'SZ' },
  { npa: '6442', city: 'Gersau', canton: 'SZ' },
  { npa: '8834', city: 'Schindellegi', canton: 'SZ' },
  { npa: '8835', city: 'Feusisberg', canton: 'SZ' },
  { npa: '8640', city: 'Hurden', canton: 'SZ' },
  { npa: '8806', city: 'Bäch SZ', canton: 'SZ' },
  { npa: '8807', city: 'Freienbach', canton: 'SZ' },
  { npa: '8808', city: 'Pfäffikon SZ', canton: 'SZ' },
  { npa: '8832', city: 'Wollerau', canton: 'SZ' },
  { npa: '8832', city: 'Wilen b. Wollerau', canton: 'SZ' },
  { npa: '8832', city: 'Wollerau', canton: 'SZ' },
  { npa: '6402', city: 'Merlischachen', canton: 'SZ' },
  { npa: '6403', city: 'Küssnacht am Rigi', canton: 'SZ' },
  { npa: '6405', city: 'Immensee', canton: 'SZ' },
  { npa: '8852', city: 'Altendorf', canton: 'SZ' },
  { npa: '8854', city: 'Galgenen', canton: 'SZ' },
  { npa: '8858', city: 'Innerthal', canton: 'SZ' },
  { npa: '8853', city: 'Lachen SZ', canton: 'SZ' },
  { npa: '8864', city: 'Reichenburg', canton: 'SZ' },
  { npa: '8854', city: 'Siebnen', canton: 'SZ' },
  { npa: '8862', city: 'Schübelbach', canton: 'SZ' },
  { npa: '8863', city: 'Buttikon SZ', canton: 'SZ' },
  { npa: '8856', city: 'Tuggen', canton: 'SZ' },
  { npa: '8857', city: 'Vorderthal', canton: 'SZ' },
  { npa: '8855', city: 'Wangen SZ', canton: 'SZ' },
  { npa: '8849', city: 'Alpthal', canton: 'SZ' },
  { npa: '6410', city: 'Goldau', canton: 'SZ' },
  { npa: '6410', city: 'Rigi Klösterli', canton: 'SZ' },
  { npa: '6410', city: 'Rigi Staffel', canton: 'SZ' },
  { npa: '6410', city: 'Rigi Kulm', canton: 'SZ' },
  { npa: '6414', city: 'Oberarth', canton: 'SZ' },
  { npa: '6415', city: 'Arth', canton: 'SZ' },
  { npa: '6434', city: 'Illgau', canton: 'SZ' },
  { npa: '6440', city: 'Brunnen', canton: 'SZ' },
  { npa: '6424', city: 'Lauerz', canton: 'SZ' },
  { npa: '6433', city: 'Stoos SZ', canton: 'SZ' },
  { npa: '6443', city: 'Morschach', canton: 'SZ' },
  { npa: '6436', city: 'Muotathal', canton: 'SZ' },
  { npa: '6436', city: 'Bisisthal', canton: 'SZ' },
  { npa: '6436', city: 'Ried (Muotathal)', canton: 'SZ' },
  { npa: '8843', city: 'Oberiberg', canton: 'SZ' },
  { npa: '6452', city: 'Riemenstalden', canton: 'SZ' },
  { npa: '6418', city: 'Rothenthurm', canton: 'SZ' },
  { npa: '6417', city: 'Sattel', canton: 'SZ' },
  { npa: '6423', city: 'Seewen SZ', canton: 'SZ' },
  { npa: '6430', city: 'Schwyz', canton: 'SZ' },
  { npa: '6432', city: 'Rickenbach b. Schwyz', canton: 'SZ' },
  { npa: '6438', city: 'Ibach', canton: 'SZ' },
  { npa: '6422', city: 'Steinen', canton: 'SZ' },
  { npa: '6416', city: 'Steinerberg', canton: 'SZ' },
  { npa: '8842', city: 'Unteriberg', canton: 'SZ' },
  { npa: '8845', city: 'Studen SZ', canton: 'SZ' },
  { npa: '6010', city: 'Pilatus Kulm', canton: 'OW' },
  { npa: '6053', city: 'Alpnachstad', canton: 'OW' },
  { npa: '6055', city: 'Alpnach Dorf', canton: 'OW' },
  { npa: '6388', city: 'Grafenort', canton: 'OW' },
  { npa: '6390', city: 'Engelberg', canton: 'OW' },
  { npa: '6074', city: 'Giswil', canton: 'OW' },
  { npa: '6064', city: 'Kerns', canton: 'OW' },
  { npa: '6066', city: 'St. Niklausen OW', canton: 'OW' },
  { npa: '6067', city: 'Melchtal', canton: 'OW' },
  { npa: '6068', city: 'Melchsee-Frutt', canton: 'OW' },
  { npa: '6078', city: 'Lungern', canton: 'OW' },
  { npa: '6078', city: 'Bürglen OW', canton: 'OW' },
  { npa: '6072', city: 'Sachseln', canton: 'OW' },
  { npa: '6073', city: 'Flüeli-Ranft', canton: 'OW' },
  { npa: '6056', city: 'Kägiswil', canton: 'OW' },
  { npa: '6060', city: 'Sarnen', canton: 'OW' },
  { npa: '6060', city: 'Ramersberg', canton: 'OW' },
  { npa: '6062', city: 'Wilen (Sarnen)', canton: 'OW' },
  { npa: '6063', city: 'Stalden (Sarnen)', canton: 'OW' },
  { npa: '6375', city: 'Beckenried', canton: 'NW' },
  { npa: '6374', city: 'Buochs', canton: 'NW' },
  { npa: '6383', city: 'Dallenwil', canton: 'NW' },
  { npa: '6383', city: 'Wiesenberg', canton: 'NW' },
  { npa: '6383', city: 'Wirzweli', canton: 'NW' },
  { npa: '6376', city: 'Emmetten', canton: 'NW' },
  { npa: '6363', city: 'Bürgenstock', canton: 'NW' },
  { npa: '6373', city: 'Ennetbürgen', canton: 'NW' },
  { npa: '6372', city: 'Ennetmoos', canton: 'NW' },
  { npa: '6052', city: 'Hergiswil NW', canton: 'NW' },
  { npa: '6370', city: 'Oberdorf NW', canton: 'NW' },
  { npa: '6382', city: 'Büren NW', canton: 'NW' },
  { npa: '6383', city: 'Niederrickenbach', canton: 'NW' },
  { npa: '6370', city: 'Stans', canton: 'NW' },
  { npa: '6362', city: 'Stansstad', canton: 'NW' },
  { npa: '6363', city: 'Obbürgen', canton: 'NW' },
  { npa: '6363', city: 'Fürigen', canton: 'NW' },
  { npa: '6365', city: 'Kehrsiten', canton: 'NW' },
  { npa: '6386', city: 'Wolfenschiessen', canton: 'NW' },
  { npa: '6387', city: 'Oberrickenbach', canton: 'NW' },
  { npa: '8752', city: 'Näfels', canton: 'GL' },
  { npa: '8753', city: 'Mollis', canton: 'GL' },
  { npa: '8757', city: 'Filzbach', canton: 'GL' },
  { npa: '8758', city: 'Obstalden', canton: 'GL' },
  { npa: '8865', city: 'Bilten', canton: 'GL' },
  { npa: '8867', city: 'Niederurnen', canton: 'GL' },
  { npa: '8868', city: 'Oberurnen', canton: 'GL' },
  { npa: '8874', city: 'Mühlehorn', canton: 'GL' },
  { npa: '8756', city: 'Mitlödi', canton: 'GL' },
  { npa: '8762', city: 'Schwanden GL', canton: 'GL' },
  { npa: '8762', city: 'Schwändi b. Schwanden', canton: 'GL' },
  { npa: '8762', city: 'Sool', canton: 'GL' },
  { npa: '8765', city: 'Engi', canton: 'GL' },
  { npa: '8766', city: 'Matt', canton: 'GL' },
  { npa: '8767', city: 'Elm', canton: 'GL' },
  { npa: '8772', city: 'Nidfurn', canton: 'GL' },
  { npa: '8773', city: 'Haslen GL', canton: 'GL' },
  { npa: '8774', city: 'Leuggelbach', canton: 'GL' },
  { npa: '8775', city: 'Luchsingen', canton: 'GL' },
  { npa: '8775', city: 'Hätzingen', canton: 'GL' },
  { npa: '8777', city: 'Diesbach GL', canton: 'GL' },
  { npa: '8777', city: 'Betschwanden', canton: 'GL' },
  { npa: '8782', city: 'Rüti GL', canton: 'GL' },
  { npa: '8783', city: 'Linthal', canton: 'GL' },
  { npa: '8784', city: 'Braunwald', canton: 'GL' },
  { npa: '8750', city: 'Glarus', canton: 'GL' },
  { npa: '8750', city: 'Riedern', canton: 'GL' },
  { npa: '8750', city: 'Klöntal', canton: 'GL' },
  { npa: '8754', city: 'Netstal', canton: 'GL' },
  { npa: '8755', city: 'Ennenda', canton: 'GL' },
  { npa: '6319', city: 'Allenwinden', canton: 'ZG' },
  { npa: '6340', city: 'Baar', canton: 'ZG' },
  { npa: '6330', city: 'Cham', canton: 'ZG' },
  { npa: '6332', city: 'Hagendorn', canton: 'ZG' },
  { npa: '6331', city: 'Hünenberg', canton: 'ZG' },
  { npa: '6333', city: 'Hünenberg See', canton: 'ZG' },
  { npa: '6313', city: 'Menzingen', canton: 'ZG' },
  { npa: '6313', city: 'Menzingen', canton: 'ZG' },
  { npa: '6313', city: 'Edlibach', canton: 'ZG' },
  { npa: '6313', city: 'Finstersee', canton: 'ZG' },
  { npa: '6340', city: 'Sihlbrugg', canton: 'ZG' },
  { npa: '6345', city: 'Neuheim', canton: 'ZG' },
  { npa: '6315', city: 'Oberägeri', canton: 'ZG' },
  { npa: '6315', city: 'Alosen', canton: 'ZG' },
  { npa: '6315', city: 'Morgarten', canton: 'ZG' },
  { npa: '6343', city: 'Rotkreuz', canton: 'ZG' },
  { npa: '6343', city: 'Buonas', canton: 'ZG' },
  { npa: '6343', city: 'Risch', canton: 'ZG' },
  { npa: '6343', city: 'Holzhäusern ZG', canton: 'ZG' },
  { npa: '6312', city: 'Steinhausen', canton: 'ZG' },
  { npa: '6314', city: 'Unterägeri', canton: 'ZG' },
  { npa: '6314', city: 'Neuägeri', canton: 'ZG' },
  { npa: '6318', city: 'Walchwil', canton: 'ZG' },
  { npa: '6300', city: 'Zug', canton: 'ZG' },
  { npa: '6300', city: 'Zugerberg', canton: 'ZG' },
  { npa: '6317', city: 'Oberwil b. Zug', canton: 'ZG' },
  { npa: '1473', city: 'Châtillon FR', canton: 'FR' },
  { npa: '1482', city: 'Cugy FR', canton: 'FR' },
  { npa: '1483', city: 'Vesin', canton: 'FR' },
  { npa: '1532', city: 'Fétigny', canton: 'FR' },
  { npa: '1544', city: 'Gletterens', canton: 'FR' },
  { npa: '1470', city: 'Lully FR', canton: 'FR' },
  { npa: '1470', city: 'Seiry', canton: 'FR' },
  { npa: '1470', city: 'Bollion', canton: 'FR' },
  { npa: '1533', city: 'Ménières', canton: 'FR' },
  { npa: '1774', city: 'Cousset', canton: 'FR' },
  { npa: '1774', city: 'Cousset', canton: 'FR' },
  { npa: '1774', city: 'Montagny-les-Monts', canton: 'FR' },
  { npa: '1775', city: 'Mannens', canton: 'FR' },
  { npa: '1775', city: 'Grandsivaz', canton: 'FR' },
  { npa: '1776', city: 'Montagny-la-Ville', canton: 'FR' },
  { npa: '1485', city: 'Nuvilly', canton: 'FR' },
  { npa: '1410', city: 'Prévondavaux', canton: 'FR' },
  { npa: '1566', city: 'St-Aubin FR', canton: 'FR' },
  { npa: '1566', city: 'Les Friques', canton: 'FR' },
  { npa: '1541', city: 'Sévaz', canton: 'FR' },
  { npa: '1527', city: 'Villeneuve FR', canton: 'FR' },
  { npa: '1528', city: 'Surpierre', canton: 'FR' },
  { npa: '1528', city: 'Praratoud', canton: 'FR' },
  { npa: '1529', city: 'Cheiry', canton: 'FR' },
  { npa: '1534', city: 'Chapelle (Broye)', canton: 'FR' },
  { npa: '1565', city: 'Vallon', canton: 'FR' },
  { npa: '1483', city: 'Montet (Broye)', canton: 'FR' },
  { npa: '1483', city: 'Frasses', canton: 'FR' },
  { npa: '1484', city: 'Aumont', canton: 'FR' },
  { npa: '1484', city: 'Granges-de-Vesin', canton: 'FR' },
  { npa: '1567', city: 'Delley', canton: 'FR' },
  { npa: '1568', city: 'Portalban', canton: 'FR' },
  { npa: '1563', city: 'Dompierre FR', canton: 'FR' },
  { npa: '1564', city: 'Domdidier', canton: 'FR' },
  { npa: '1773', city: 'Léchelles', canton: 'FR' },
  { npa: '1773', city: 'Chandon', canton: 'FR' },
  { npa: '1773', city: 'Russy', canton: 'FR' },
  { npa: '1470', city: 'Estavayer-le-Lac', canton: 'FR' },
  { npa: '1473', city: 'Font', canton: 'FR' },
  { npa: '1473', city: 'Font', canton: 'FR' },
  { npa: '1475', city: 'Autavaux', canton: 'FR' },
  { npa: '1475', city: 'Forel FR', canton: 'FR' },
  { npa: '1475', city: 'Montbrelloz', canton: 'FR' },
  { npa: '1486', city: 'Vuissens', canton: 'FR' },
  { npa: '1489', city: 'Murist', canton: 'FR' },
  { npa: '1541', city: 'Bussy FR', canton: 'FR' },
  { npa: '1541', city: 'Morens FR', canton: 'FR' },
  { npa: '1542', city: 'Rueyres-les-Prés', canton: 'FR' },
  { npa: '1468', city: 'Cheyres', canton: 'FR' },
  { npa: '1474', city: 'Châbles FR', canton: 'FR' },
  { npa: '1673', city: 'Auboranges', canton: 'FR' },
  { npa: '1681', city: 'Billens', canton: 'FR' },
  { npa: '1681', city: 'Hennens', canton: 'FR' },
  { npa: '1608', city: 'Chapelle (Glâne)', canton: 'FR' },
  { npa: '1689', city: 'Le Châtelard-près-Romont', canton: 'FR' },
  { npa: '1553', city: 'Châtonnaye', canton: 'FR' },
  { npa: '1673', city: 'Ecublens FR', canton: 'FR' },
  { npa: '1686', city: 'Grangettes-près-Romont', canton: 'FR' },
  { npa: '1692', city: 'Massonnens', canton: 'FR' },
  { npa: '1680', city: 'Berlens', canton: 'FR' },
  { npa: '1684', city: 'Mézières FR', canton: 'FR' },
  { npa: '1674', city: 'Montet (Glâne)', canton: 'FR' },
  { npa: '1680', city: 'Romont FR', canton: 'FR' },
  { npa: '1673', city: 'Promasens', canton: 'FR' },
  { npa: '1673', city: 'Gillarens', canton: 'FR' },
  { npa: '1673', city: 'Rue', canton: 'FR' },
  { npa: '1675', city: 'Blessens', canton: 'FR' },
  { npa: '1676', city: 'Chavannes-les-Forts', canton: 'FR' },
  { npa: '1677', city: 'Prez-vers-Siviriez', canton: 'FR' },
  { npa: '1678', city: 'Siviriez', canton: 'FR' },
  { npa: '1679', city: 'Villaraboud', canton: 'FR' },
  { npa: '1670', city: 'Ursy', canton: 'FR' },
  { npa: '1670', city: 'Bionnens', canton: 'FR' },
  { npa: '1670', city: 'Esmonts', canton: 'FR' },
  { npa: '1674', city: 'Vuarmarens', canton: 'FR' },
  { npa: '1674', city: 'Morlens', canton: 'FR' },
  { npa: '1675', city: 'Vauderens', canton: 'FR' },
  { npa: '1675', city: 'Mossel', canton: 'FR' },
  { npa: '1685', city: 'Villariaz', canton: 'FR' },
  { npa: '1686', city: 'La Neirigue', canton: 'FR' },
  { npa: '1687', city: 'Vuisternens-devant-Romont', canton: 'FR' },
  { npa: '1687', city: 'La Magne', canton: 'FR' },
  { npa: '1687', city: 'Estévenens', canton: 'FR' },
  { npa: '1688', city: 'Sommentier', canton: 'FR' },
  { npa: '1688', city: 'Lieffrens', canton: 'FR' },
  { npa: '1697', city: 'La Joux FR', canton: 'FR' },
  { npa: '1697', city: 'Les Ecasseys', canton: 'FR' },
  { npa: '1694', city: 'Villarsiviriaux', canton: 'FR' },
  { npa: '1694', city: 'Villargiroud', canton: 'FR' },
  { npa: '1694', city: 'Orsonnens', canton: 'FR' },
  { npa: '1694', city: 'Chavannes-sous-Orsonnens', canton: 'FR' },
  { npa: '1748', city: 'Torny-le-Grand', canton: 'FR' },
  { npa: '1749', city: 'Middes', canton: 'FR' },
  { npa: '1690', city: 'Villaz-St-Pierre', canton: 'FR' },
  { npa: '1690', city: 'Lussy FR', canton: 'FR' },
  { npa: '1691', city: 'Villarimboud', canton: 'FR' },
  { npa: '1669', city: 'Albeuve', canton: 'FR' },
  { npa: '1669', city: 'Lessoc', canton: 'FR' },
  { npa: '1669', city: "Les Sciernes-d'Albeuve", canton: 'FR' },
  { npa: '1669', city: 'Neirivue', canton: 'FR' },
  { npa: '1669', city: 'Montbovon', canton: 'FR' },
  { npa: '1643', city: 'Gumefens', canton: 'FR' },
  { npa: '1644', city: 'Avry-devant-Pont', canton: 'FR' },
  { npa: '1645', city: 'Le Bry', canton: 'FR' },
  { npa: '1652', city: 'Botterens', canton: 'FR' },
  { npa: '1652', city: 'Villarbeney', canton: 'FR' },
  { npa: '1636', city: 'Broc', canton: 'FR' },
  { npa: '1630', city: 'Bulle', canton: 'FR' },
  { npa: '1635', city: 'La Tour-de-Trême', canton: 'FR' },
  { npa: '1653', city: 'Châtel-sur-Montsalvens', canton: 'FR' },
  { npa: '1647', city: 'Corbières', canton: 'FR' },
  { npa: '1651', city: 'Villarvolard', canton: 'FR' },
  { npa: '1653', city: 'Crésuz', canton: 'FR' },
  { npa: '1646', city: 'Echarlens', canton: 'FR' },
  { npa: '1666', city: 'Grandvillard', canton: 'FR' },
  { npa: '1663', city: 'Gruyères', canton: 'FR' },
  { npa: '1663', city: 'Pringy', canton: 'FR' },
  { npa: '1663', city: 'Epagny', canton: 'FR' },
  { npa: '1663', city: 'Moléson-sur-Gruyères', canton: 'FR' },
  { npa: '1648', city: 'Hauteville', canton: 'FR' },
  { npa: '1656', city: 'Jaun', canton: 'FR' },
  { npa: '1656', city: 'Im Fang', canton: 'FR' },
  { npa: '1633', city: 'Marsens', canton: 'FR' },
  { npa: '1633', city: 'Vuippens', canton: 'FR' },
  { npa: '1638', city: 'Morlon', canton: 'FR' },
  { npa: '1661', city: 'Le Pâquier-Montbarry', canton: 'FR' },
  { npa: '1649', city: 'Pont-la-Ville', canton: 'FR' },
  { npa: '1632', city: 'Riaz', canton: 'FR' },
  { npa: '1634', city: 'La Roche FR', canton: 'FR' },
  { npa: '1625', city: 'Sâles (Gruyère)', canton: 'FR' },
  { npa: '1625', city: 'Maules', canton: 'FR' },
  { npa: '1626', city: 'Romanens', canton: 'FR' },
  { npa: '1626', city: 'Treyfayes', canton: 'FR' },
  { npa: '1626', city: 'Rueyres-Treyfayes', canton: 'FR' },
  { npa: '1642', city: 'Sorens', canton: 'FR' },
  { npa: '1627', city: 'Vaulruz', canton: 'FR' },
  { npa: '1628', city: 'Vuadens', canton: 'FR' },
  { npa: '1665', city: 'Estavannens', canton: 'FR' },
  { npa: '1666', city: 'Villars-sous-Mont', canton: 'FR' },
  { npa: '1667', city: 'Enney', canton: 'FR' },
  { npa: '1637', city: 'Charmey (Gruyère)', canton: 'FR' },
  { npa: '1654', city: 'Cerniat FR', canton: 'FR' },
  { npa: '1742', city: 'Autigny', canton: 'FR' },
  { npa: '1754', city: 'Corjolens', canton: 'FR' },
  { npa: '1754', city: 'Avry-sur-Matran', canton: 'FR' },
  { npa: '1782', city: 'Belfaux', canton: 'FR' },
  { npa: '1782', city: 'Autafond', canton: 'FR' },
  { npa: '1744', city: 'Chénens', canton: 'FR' },
  { npa: '1720', city: 'Corminboeuf', canton: 'FR' },
  { npa: '1720', city: 'Chésopelloz', canton: 'FR' },
  { npa: '1741', city: 'Cottens FR', canton: 'FR' },
  { npa: '1724', city: 'Ferpicloz', canton: 'FR' },
  { npa: '1700', city: 'Fribourg', canton: 'FR' },
  { npa: '1722', city: 'Bourguillon', canton: 'FR' },
  { npa: '1762', city: 'Givisiez', canton: 'FR' },
  { npa: '1763', city: 'Granges-Paccot', canton: 'FR' },
  { npa: '1772', city: 'Grolley', canton: 'FR' },
  { npa: '1723', city: 'Marly', canton: 'FR' },
  { npa: '1753', city: 'Matran', canton: 'FR' },
  { npa: '1740', city: 'Neyruz FR', canton: 'FR' },
  { npa: '1723', city: 'Pierrafortscha', canton: 'FR' },
  { npa: '1772', city: 'Nierlet-les-Bois', canton: 'FR' },
  { npa: '1772', city: 'Ponthaux', canton: 'FR' },
  { npa: '1724', city: 'Le Mouret', canton: 'FR' },
  { npa: '1724', city: 'Essert FR', canton: 'FR' },
  { npa: '1724', city: 'Montévraz', canton: 'FR' },
  { npa: '1724', city: 'Oberried FR', canton: 'FR' },
  { npa: '1724', city: 'Zénauva', canton: 'FR' },
  { npa: '1724', city: 'Bonnefontaine', canton: 'FR' },
  { npa: '1733', city: 'Treyvaux', canton: 'FR' },
  { npa: '1752', city: 'Villars-sur-Glâne', canton: 'FR' },
  { npa: '1723', city: 'Villarsel-sur-Marly', canton: 'FR' },
  { npa: '1725', city: 'Posieux', canton: 'FR' },
  { npa: '1730', city: 'Ecuvillens', canton: 'FR' },
  { npa: '1745', city: 'Lentigny', canton: 'FR' },
  { npa: '1756', city: 'Onnens FR', canton: 'FR' },
  { npa: '1756', city: 'Lovens', canton: 'FR' },
  { npa: '1782', city: 'Lossy', canton: 'FR' },
  { npa: '1782', city: 'La Corbaz', canton: 'FR' },
  { npa: '1782', city: 'Cormagens', canton: 'FR' },
  { npa: '1782', city: 'Formangueires', canton: 'FR' },
  { npa: '1695', city: 'Villarlod', canton: 'FR' },
  { npa: '1695', city: 'Rueyres-St-Laurent', canton: 'FR' },
  { npa: '1695', city: 'Estavayer-le-Gibloux', canton: 'FR' },
  { npa: '1695', city: 'Villarsel-le-Gibloux', canton: 'FR' },
  { npa: '1696', city: 'Vuisternens-en-Ogoz', canton: 'FR' },
  { npa: '1726', city: 'Farvagny-le-Grand', canton: 'FR' },
  { npa: '1726', city: 'Farvagny-le-Petit', canton: 'FR' },
  { npa: '1726', city: 'Grenilles', canton: 'FR' },
  { npa: '1726', city: 'Posat', canton: 'FR' },
  { npa: '1727', city: 'Corpataux', canton: 'FR' },
  { npa: '1727', city: 'Magnedens', canton: 'FR' },
  { npa: '1728', city: 'Rossens FR', canton: 'FR' },
  { npa: '1746', city: 'Prez-vers-Noréaz', canton: 'FR' },
  { npa: '1747', city: 'Corserey', canton: 'FR' },
  { npa: '1757', city: 'Noréaz', canton: 'FR' },
  { npa: '1724', city: 'Senèdes', canton: 'FR' },
  { npa: '1731', city: 'Ependes FR', canton: 'FR' },
  { npa: '1732', city: 'Arconciel', canton: 'FR' },
  { npa: '1796', city: 'Courgevaux', canton: 'FR' },
  { npa: '1583', city: 'Villarepos', canton: 'FR' },
  { npa: '1783', city: 'Pensier', canton: 'FR' },
  { npa: '1783', city: 'Barberêche', canton: 'FR' },
  { npa: '1784', city: 'Courtepin', canton: 'FR' },
  { npa: '1784', city: 'Wallenried', canton: 'FR' },
  { npa: '1791', city: 'Courtaman', canton: 'FR' },
  { npa: '1785', city: 'Cressier FR', canton: 'FR' },
  { npa: '3284', city: 'Fräschels', canton: 'FR' },
  { npa: '3280', city: 'Greng', canton: 'FR' },
  { npa: '1792', city: 'Cordast', canton: 'FR' },
  { npa: '1792', city: 'Guschelmuth', canton: 'FR' },
  { npa: '3206', city: 'Wallenbuch', canton: 'FR' },
  { npa: '3212', city: 'Gurmels', canton: 'FR' },
  { npa: '3212', city: 'Kleingurmels', canton: 'FR' },
  { npa: '3213', city: 'Liebistorf', canton: 'FR' },
  { npa: '3210', city: 'Kerzers', canton: 'FR' },
  { npa: '3213', city: 'Kleinbösingen', canton: 'FR' },
  { npa: '3280', city: 'Meyriez', canton: 'FR' },
  { npa: '1721', city: 'Misery', canton: 'FR' },
  { npa: '1721', city: 'Cormérod', canton: 'FR' },
  { npa: '1721', city: 'Courtion', canton: 'FR' },
  { npa: '1721', city: 'Cournillens', canton: 'FR' },
  { npa: '3286', city: 'Muntelier', canton: 'FR' },
  { npa: '1595', city: 'Clavaleyres', canton: 'FR' },
  { npa: '1793', city: 'Jeuss', canton: 'FR' },
  { npa: '1794', city: 'Salvenach', canton: 'FR' },
  { npa: '1795', city: 'Courlevon', canton: 'FR' },
  { npa: '3215', city: 'Gempenach', canton: 'FR' },
  { npa: '3215', city: 'Büchslen', canton: 'FR' },
  { npa: '3215', city: 'Lurtigen', canton: 'FR' },
  { npa: '3280', city: 'Murten', canton: 'FR' },
  { npa: '3285', city: 'Galmiz', canton: 'FR' },
  { npa: '3216', city: 'Ried b. Kerzers', canton: 'FR' },
  { npa: '3216', city: 'Agriswil', canton: 'FR' },
  { npa: '3214', city: 'Ulmiz', canton: 'FR' },
  { npa: '1786', city: 'Sugiez', canton: 'FR' },
  { npa: '1787', city: 'Môtier (Vully)', canton: 'FR' },
  { npa: '1787', city: 'Mur (Vully) FR', canton: 'FR' },
  { npa: '1788', city: 'Praz (Vully)', canton: 'FR' },
  { npa: '1789', city: 'Lugnorre', canton: 'FR' },
  { npa: '1719', city: 'Brünisried', canton: 'FR' },
  { npa: '3186', city: 'Düdingen', canton: 'FR' },
  { npa: '1735', city: 'Giffers', canton: 'FR' },
  { npa: '3178', city: 'Bösingen', canton: 'FR' },
  { npa: '1714', city: 'Heitenried', canton: 'FR' },
  { npa: '1716', city: 'Plaffeien', canton: 'FR' },
  { npa: '1716', city: 'Oberschrot', canton: 'FR' },
  { npa: '1716', city: 'Schwarzsee', canton: 'FR' },
  { npa: '1719', city: 'Zumholz', canton: 'FR' },
  { npa: '1737', city: 'Plasselb', canton: 'FR' },
  { npa: '1718', city: 'Rechthalten', canton: 'FR' },
  { npa: '1736', city: 'St. Silvester', canton: 'FR' },
  { npa: '1717', city: 'St. Ursen', canton: 'FR' },
  { npa: '3185', city: 'Schmitten FR', canton: 'FR' },
  { npa: '1712', city: 'Tafers', canton: 'FR' },
  { npa: '1713', city: 'St. Antoni', canton: 'FR' },
  { npa: '1715', city: 'Alterswil FR', canton: 'FR' },
  { npa: '1734', city: 'Tentlingen', canton: 'FR' },
  { npa: '3182', city: 'Ueberstorf', canton: 'FR' },
  { npa: '3175', city: 'Flamatt', canton: 'FR' },
  { npa: '3184', city: 'Wünnewil', canton: 'FR' },
  { npa: '1616', city: 'Attalens', canton: 'FR' },
  { npa: '1617', city: 'Tatroz', canton: 'FR' },
  { npa: '1615', city: 'Bossonnens', canton: 'FR' },
  { npa: '1618', city: 'Châtel-St-Denis', canton: 'FR' },
  { npa: '1619', city: 'Les Paccots', canton: 'FR' },
  { npa: '1614', city: 'Granges (Veveyse)', canton: 'FR' },
  { npa: '1617', city: 'Remaufens', canton: 'FR' },
  { npa: '1609', city: 'St-Martin FR', canton: 'FR' },
  { npa: '1609', city: 'Besencens', canton: 'FR' },
  { npa: '1609', city: 'Fiaugères', canton: 'FR' },
  { npa: '1623', city: 'Semsales', canton: 'FR' },
  { npa: '1699', city: 'Porsel', canton: 'FR' },
  { npa: '1699', city: 'Pont (Veveyse)', canton: 'FR' },
  { npa: '1699', city: 'Bouloz', canton: 'FR' },
  { npa: '1611', city: 'Le Crêt-près-Semsales', canton: 'FR' },
  { npa: '1624', city: 'La Verrerie', canton: 'FR' },
  { npa: '1624', city: 'Grattavache', canton: 'FR' },
  { npa: '1624', city: 'Progens', canton: 'FR' },
  { npa: '1624', city: 'Progens', canton: 'FR' },
  { npa: '4622', city: 'Egerkingen', canton: 'SO' },
  { npa: '4624', city: 'Härkingen', canton: 'SO' },
  { npa: '4703', city: 'Kestenholz', canton: 'SO' },
  { npa: '4623', city: 'Neuendorf', canton: 'SO' },
  { npa: '4626', city: 'Niederbuchsiten', canton: 'SO' },
  { npa: '4625', city: 'Oberbuchsiten', canton: 'SO' },
  { npa: '4702', city: 'Oensingen', canton: 'SO' },
  { npa: '4628', city: 'Wolfwil', canton: 'SO' },
  { npa: '4714', city: 'Aedermannsdorf', canton: 'SO' },
  { npa: '4710', city: 'Balsthal', canton: 'SO' },
  { npa: '4715', city: 'Herbetswil', canton: 'SO' },
  { npa: '4718', city: 'Holderbank SO', canton: 'SO' },
  { npa: '4712', city: 'Laupersdorf', canton: 'SO' },
  { npa: '4713', city: 'Matzendorf', canton: 'SO' },
  { npa: '4717', city: 'Mümliswil', canton: 'SO' },
  { npa: '4719', city: 'Ramiswil', canton: 'SO' },
  { npa: '4716', city: 'Welschenrohr', canton: 'SO' },
  { npa: '4716', city: 'Gänsbrunnen', canton: 'SO' },
  { npa: '4585', city: 'Biezwil', canton: 'SO' },
  { npa: '4571', city: 'Lüterkofen', canton: 'SO' },
  { npa: '4571', city: 'Ichertswil', canton: 'SO' },
  { npa: '4584', city: 'Lüterswil', canton: 'SO' },
  { npa: '4584', city: 'Gächliwil', canton: 'SO' },
  { npa: '3254', city: 'Messen', canton: 'SO' },
  { npa: '3254', city: 'Balm b. Messen', canton: 'SO' },
  { npa: '3307', city: 'Brunnenthal', canton: 'SO' },
  { npa: '4588', city: 'Oberramsern', canton: 'SO' },
  { npa: '3253', city: 'Schnottwil', canton: 'SO' },
  { npa: '4588', city: 'Unterramsern', canton: 'SO' },
  { npa: '4574', city: 'Nennigkofen', canton: 'SO' },
  { npa: '4574', city: 'Lüsslingen', canton: 'SO' },
  { npa: '4576', city: 'Tscheppach', canton: 'SO' },
  { npa: '4577', city: 'Hessigkofen', canton: 'SO' },
  { npa: '4578', city: 'Bibern SO', canton: 'SO' },
  { npa: '4579', city: 'Gossliwil', canton: 'SO' },
  { npa: '4581', city: 'Küttigkofen', canton: 'SO' },
  { npa: '4582', city: 'Brügglen', canton: 'SO' },
  { npa: '4583', city: 'Mühledorf SO', canton: 'SO' },
  { npa: '4583', city: 'Aetigkofen', canton: 'SO' },
  { npa: '4586', city: 'Kyburg-Buchegg', canton: 'SO' },
  { npa: '4587', city: 'Aetingen', canton: 'SO' },
  { npa: '4588', city: 'Brittern', canton: 'SO' },
  { npa: '4112', city: 'Bättwil', canton: 'SO' },
  { npa: '4413', city: 'Büren SO', canton: 'SO' },
  { npa: '4143', city: 'Dornach', canton: 'SO' },
  { npa: '4145', city: 'Gempen', canton: 'SO' },
  { npa: '4146', city: 'Hochwald', canton: 'SO' },
  { npa: '4112', city: 'Flüh', canton: 'SO' },
  { npa: '4114', city: 'Hofstetten SO', canton: 'SO' },
  { npa: '4115', city: 'Mariastein', canton: 'SO' },
  { npa: '4116', city: 'Metzerlen', canton: 'SO' },
  { npa: '4412', city: 'Nuglar', canton: 'SO' },
  { npa: '4421', city: 'St. Pantaleon', canton: 'SO' },
  { npa: '4118', city: 'Rodersdorf', canton: 'SO' },
  { npa: '4206', city: 'Seewen SO', canton: 'SO' },
  { npa: '4108', city: 'Witterswil', canton: 'SO' },
  { npa: '4633', city: 'Hauenstein', canton: 'SO' },
  { npa: '4468', city: 'Kienberg', canton: 'SO' },
  { npa: '4654', city: 'Lostorf', canton: 'SO' },
  { npa: '5013', city: 'Niedergösgen', canton: 'SO' },
  { npa: '4653', city: 'Obergösgen', canton: 'SO' },
  { npa: '4655', city: 'Stüsslingen', canton: 'SO' },
  { npa: '4655', city: 'Rohr b. Olten', canton: 'SO' },
  { npa: '4632', city: 'Trimbach', canton: 'SO' },
  { npa: '4652', city: 'Winznau', canton: 'SO' },
  { npa: '4634', city: 'Wisen SO', canton: 'SO' },
  { npa: '5015', city: 'Erlinsbach SO', canton: 'SO' },
  { npa: '4556', city: 'Aeschi SO', canton: 'SO' },
  { npa: '4556', city: 'Burgäschi', canton: 'SO' },
  { npa: '4556', city: 'Steinhof SO', canton: 'SO' },
  { npa: '4562', city: 'Biberist', canton: 'SO' },
  { npa: '4556', city: 'Bolken', canton: 'SO' },
  { npa: '4543', city: 'Deitingen', canton: 'SO' },
  { npa: '4552', city: 'Derendingen', canton: 'SO' },
  { npa: '4554', city: 'Etziken', canton: 'SO' },
  { npa: '4563', city: 'Gerlafingen', canton: 'SO' },
  { npa: '4566', city: 'Halten', canton: 'SO' },
  { npa: '4557', city: 'Horriwil', canton: 'SO' },
  { npa: '4554', city: 'Hüniken', canton: 'SO' },
  { npa: '4566', city: 'Kriegstetten', canton: 'SO' },
  { npa: '4573', city: 'Lohn-Ammannsegg', canton: 'SO' },
  { npa: '4542', city: 'Luterbach', canton: 'SO' },
  { npa: '4564', city: 'Obergerlafingen', canton: 'SO' },
  { npa: '4566', city: 'Oekingen', canton: 'SO' },
  { npa: '4565', city: 'Recherswil', canton: 'SO' },
  { npa: '4553', city: 'Subingen', canton: 'SO' },
  { npa: '4528', city: 'Zuchwil', canton: 'SO' },
  { npa: '4558', city: 'Hersiwil', canton: 'SO' },
  { npa: '4558', city: 'Heinrichswil', canton: 'SO' },
  { npa: '4558', city: 'Winistorf', canton: 'SO' },
  { npa: '4524', city: 'Balmberg', canton: 'SO' },
  { npa: '4524', city: 'Oberbalmberg', canton: 'SO' },
  { npa: '4525', city: 'Balm b. Günsberg', canton: 'SO' },
  { npa: '4512', city: 'Bellach', canton: 'SO' },
  { npa: '2544', city: 'Bettlach', canton: 'SO' },
  { npa: '4532', city: 'Feldbrunnen', canton: 'SO' },
  { npa: '4534', city: 'Flumenthal', canton: 'SO' },
  { npa: '2540', city: 'Grenchen', canton: 'SO' },
  { npa: '4524', city: 'Günsberg', canton: 'SO' },
  { npa: '4535', city: 'Hubersdorf', canton: 'SO' },
  { npa: '4535', city: 'Kammersrohr', canton: 'SO' },
  { npa: '4513', city: 'Langendorf', canton: 'SO' },
  { npa: '4514', city: 'Lommiswil', canton: 'SO' },
  { npa: '4515', city: 'Oberdorf SO', canton: 'SO' },
  { npa: '4515', city: 'Weissenstein b. Solothurn', canton: 'SO' },
  { npa: '4523', city: 'Niederwil SO', canton: 'SO' },
  { npa: '4533', city: 'Riedholz', canton: 'SO' },
  { npa: '4522', city: 'Rüttenen', canton: 'SO' },
  { npa: '2545', city: 'Selzach', canton: 'SO' },
  { npa: '4618', city: 'Boningen', canton: 'SO' },
  { npa: '4658', city: 'Däniken SO', canton: 'SO' },
  { npa: '4657', city: 'Dulliken', canton: 'SO' },
  { npa: '5012', city: 'Wöschnau', canton: 'SO' },
  { npa: '5012', city: 'Eppenberg', canton: 'SO' },
  { npa: '4629', city: 'Fulenbach', canton: 'SO' },
  { npa: '5014', city: 'Gretzenbach', canton: 'SO' },
  { npa: '4617', city: 'Gunzgen', canton: 'SO' },
  { npa: '4614', city: 'Hägendorf', canton: 'SO' },
  { npa: '4615', city: 'Allerheiligenberg', canton: 'SO' },
  { npa: '4616', city: 'Kappel SO', canton: 'SO' },
  { npa: '4600', city: 'Olten', canton: 'SO' },
  { npa: '4613', city: 'Rickenbach SO', canton: 'SO' },
  { npa: '5012', city: 'Schönenwerd', canton: 'SO' },
  { npa: '4656', city: 'Starrkirch-Wil', canton: 'SO' },
  { npa: '5746', city: 'Walterswil SO', canton: 'SO' },
  { npa: '4612', city: 'Wangen b. Olten', canton: 'SO' },
  { npa: '4500', city: 'Solothurn', canton: 'SO' },
  { npa: '4252', city: 'Bärschwil', canton: 'SO' },
  { npa: '4229', city: 'Beinwil SO', canton: 'SO' },
  { npa: '4226', city: 'Breitenbach', canton: 'SO' },
  { npa: '4227', city: 'Büsserach', canton: 'SO' },
  { npa: '4228', city: 'Erschwil', canton: 'SO' },
  { npa: '4232', city: 'Fehren', canton: 'SO' },
  { npa: '4247', city: 'Grindel', canton: 'SO' },
  { npa: '4204', city: 'Himmelried', canton: 'SO' },
  { npa: '4245', city: 'Kleinlützel', canton: 'SO' },
  { npa: '4233', city: 'Meltingen', canton: 'SO' },
  { npa: '4208', city: 'Nunningen', canton: 'SO' },
  { npa: '4234', city: 'Zullwil', canton: 'SO' },
  { npa: '4001', city: 'Basel', canton: 'BS' },
  { npa: '4031', city: 'Basel', canton: 'BS' },
  { npa: '4031', city: 'Basel', canton: 'BS' },
  { npa: '4051', city: 'Basel', canton: 'BS' },
  { npa: '4052', city: 'Basel', canton: 'BS' },
  { npa: '4052', city: 'Basel', canton: 'BS' },
  { npa: '4053', city: 'Basel', canton: 'BS' },
  { npa: '4054', city: 'Basel', canton: 'BS' },
  { npa: '4055', city: 'Basel', canton: 'BS' },
  { npa: '4056', city: 'Basel', canton: 'BS' },
  { npa: '4057', city: 'Basel', canton: 'BS' },
  { npa: '4058', city: 'Basel', canton: 'BS' },
  { npa: '4059', city: 'Basel', canton: 'BS' },
  { npa: '4126', city: 'Bettingen', canton: 'BS' },
  { npa: '4125', city: 'Riehen', canton: 'BS' },
  { npa: '4147', city: 'Aesch BL', canton: 'BL' },
  { npa: '4123', city: 'Allschwil', canton: 'BL' },
  { npa: '4144', city: 'Arlesheim', canton: 'BL' },
  { npa: '4105', city: 'Biel-Benken BL', canton: 'BL' },
  { npa: '4101', city: 'Bruderholz', canton: 'BL' },
  { npa: '4102', city: 'Binningen', canton: 'BL' },
  { npa: '4127', city: 'Birsfelden', canton: 'BL' },
  { npa: '4103', city: 'Bottmingen', canton: 'BL' },
  { npa: '4107', city: 'Ettingen', canton: 'BL' },
  { npa: '4142', city: 'Münchenstein', canton: 'BL' },
  { npa: '4132', city: 'Muttenz', canton: 'BL' },
  { npa: '4104', city: 'Oberwil BL', canton: 'BL' },
  { npa: '4148', city: 'Pfeffingen', canton: 'BL' },
  { npa: '4153', city: 'Reinach BL', canton: 'BL' },
  { npa: '4124', city: 'Schönenbuch', canton: 'BL' },
  { npa: '4106', city: 'Therwil', canton: 'BL' },
  { npa: '4223', city: 'Blauen', canton: 'BL' },
  { npa: '4225', city: 'Brislach', canton: 'BL' },
  { npa: '4117', city: 'Burg im Leimental', canton: 'BL' },
  { npa: '4243', city: 'Dittingen', canton: 'BL' },
  { npa: '4202', city: 'Duggingen', canton: 'BL' },
  { npa: '4203', city: 'Grellingen', canton: 'BL' },
  { npa: '4242', city: 'Laufen', canton: 'BL' },
  { npa: '4253', city: 'Liesberg', canton: 'BL' },
  { npa: '4254', city: 'Liesberg Dorf', canton: 'BL' },
  { npa: '4224', city: 'Nenzlingen', canton: 'BL' },
  { npa: '2814', city: 'Roggenburg', canton: 'BL' },
  { npa: '4244', city: 'Röschenz', canton: 'BL' },
  { npa: '4246', city: 'Wahlen b. Laufen', canton: 'BL' },
  { npa: '4222', city: 'Zwingen', canton: 'BL' },
  { npa: '4422', city: 'Arisdorf', canton: 'BL' },
  { npa: '4302', city: 'Augst BL', canton: 'BL' },
  { npa: '4416', city: 'Bubendorf', canton: 'BL' },
  { npa: '4402', city: 'Frenkendorf', canton: 'BL' },
  { npa: '4414', city: 'Füllinsdorf', canton: 'BL' },
  { npa: '4304', city: 'Giebenach', canton: 'BL' },
  { npa: '4423', city: 'Hersberg', canton: 'BL' },
  { npa: '4415', city: 'Lausen', canton: 'BL' },
  { npa: '4410', city: 'Liestal', canton: 'BL' },
  { npa: '4419', city: 'Lupsingen', canton: 'BL' },
  { npa: '4133', city: 'Pratteln', canton: 'BL' },
  { npa: '4433', city: 'Ramlinsburg', canton: 'BL' },
  { npa: '4411', city: 'Seltisberg', canton: 'BL' },
  { npa: '4417', city: 'Ziefen', canton: 'BL' },
  { npa: '4469', city: 'Anwil', canton: 'BL' },
  { npa: '4461', city: 'Böckten', canton: 'BL' },
  { npa: '4446', city: 'Buckten', canton: 'BL' },
  { npa: '4463', city: 'Buus', canton: 'BL' },
  { npa: '4442', city: 'Diepflingen', canton: 'BL' },
  { npa: '4460', city: 'Gelterkinden', canton: 'BL' },
  { npa: '4445', city: 'Häfelfingen', canton: 'BL' },
  { npa: '4465', city: 'Hemmiken', canton: 'BL' },
  { npa: '4452', city: 'Itingen', canton: 'BL' },
  { npa: '4447', city: 'Känerkinden', canton: 'BL' },
  { npa: '4496', city: 'Kilchberg BL', canton: 'BL' },
  { npa: '4448', city: 'Läufelfingen', canton: 'BL' },
  { npa: '4464', city: 'Maisprach', canton: 'BL' },
  { npa: '4453', city: 'Nusshof', canton: 'BL' },
  { npa: '4494', city: 'Oltingen', canton: 'BL' },
  { npa: '4466', city: 'Ormalingen', canton: 'BL' },
  { npa: '4462', city: 'Rickenbach BL', canton: 'BL' },
  { npa: '4467', city: 'Rothenfluh', canton: 'BL' },
  { npa: '4444', city: 'Rümlingen', canton: 'BL' },
  { npa: '4497', city: 'Rünenberg', canton: 'BL' },
  { npa: '4450', city: 'Sissach', canton: 'BL' },
  { npa: '4492', city: 'Tecknau', canton: 'BL' },
  { npa: '4456', city: 'Tenniken', canton: 'BL' },
  { npa: '4441', city: 'Thürnen', canton: 'BL' },
  { npa: '4493', city: 'Wenslingen', canton: 'BL' },
  { npa: '4451', city: 'Wintersingen', canton: 'BL' },
  { npa: '4443', city: 'Wittinsburg', canton: 'BL' },
  { npa: '4495', city: 'Zeglingen', canton: 'BL' },
  { npa: '4455', city: 'Zunzgen', canton: 'BL' },
  { npa: '4424', city: 'Arboldswil', canton: 'BL' },
  { npa: '4431', city: 'Bennwil', canton: 'BL' },
  { npa: '4207', city: 'Bretzwil', canton: 'BL' },
  { npa: '4457', city: 'Diegten', canton: 'BL' },
  { npa: '4458', city: 'Eptingen', canton: 'BL' },
  { npa: '4434', city: 'Hölstein', canton: 'BL' },
  { npa: '4432', city: 'Lampenberg', canton: 'BL' },
  { npa: '4438', city: 'Langenbruck', canton: 'BL' },
  { npa: '4426', city: 'Lauwil', canton: 'BL' },
  { npa: '4436', city: 'Liedertswil', canton: 'BL' },
  { npa: '4435', city: 'Niederdorf', canton: 'BL' },
  { npa: '4436', city: 'Oberdorf BL', canton: 'BL' },
  { npa: '4418', city: 'Reigoldswil', canton: 'BL' },
  { npa: '4425', city: 'Titterten', canton: 'BL' },
  { npa: '4437', city: 'Waldenburg', canton: 'BL' },
  { npa: '8214', city: 'Gächlingen', canton: 'SH' },
  { npa: '8214', city: 'Gächlingen', canton: 'SH' },
  { npa: '8224', city: 'Löhningen', canton: 'SH' },
  { npa: '8213', city: 'Neunkirch', canton: 'SH' },
  { npa: '8236', city: 'Büttenhardt', canton: 'SH' },
  { npa: '8239', city: 'Dörflingen', canton: 'SH' },
  { npa: '8235', city: 'Lohn SH', canton: 'SH' },
  { npa: '8234', city: 'Stetten SH', canton: 'SH' },
  { npa: '8236', city: 'Opfertshofen SH', canton: 'SH' },
  { npa: '8240', city: 'Thayngen', canton: 'SH' },
  { npa: '8241', city: 'Barzheim', canton: 'SH' },
  { npa: '8242', city: 'Bibern SH', canton: 'SH' },
  { npa: '8242', city: 'Hofen SH', canton: 'SH' },
  { npa: '8243', city: 'Altdorf SH', canton: 'SH' },
  { npa: '8233', city: 'Bargen SH', canton: 'SH' },
  { npa: '8222', city: 'Beringen', canton: 'SH' },
  { npa: '8223', city: 'Guntmadingen', canton: 'SH' },
  { npa: '8454', city: 'Buchberg', canton: 'SH' },
  { npa: '8232', city: 'Merishausen', canton: 'SH' },
  { npa: '8212', city: 'Neuhausen am Rheinfall', canton: 'SH' },
  { npa: '8455', city: 'Rüdlingen', canton: 'SH' },
  { npa: '8200', city: 'Schaffhausen', canton: 'SH' },
  { npa: '8203', city: 'Schaffhausen', canton: 'SH' },
  { npa: '8207', city: 'Schaffhausen', canton: 'SH' },
  { npa: '8208', city: 'Schaffhausen', canton: 'SH' },
  { npa: '8231', city: 'Hemmental', canton: 'SH' },
  { npa: '8228', city: 'Beggingen', canton: 'SH' },
  { npa: '8226', city: 'Schleitheim', canton: 'SH' },
  { npa: '8225', city: 'Siblingen', canton: 'SH' },
  { npa: '8263', city: 'Buch SH', canton: 'SH' },
  { npa: '8261', city: 'Hemishofen', canton: 'SH' },
  { npa: '8262', city: 'Ramsen', canton: 'SH' },
  { npa: '8260', city: 'Stein am Rhein', canton: 'SH' },
  { npa: '8215', city: 'Hallau', canton: 'SH' },
  { npa: '8216', city: 'Oberhallau', canton: 'SH' },
  { npa: '8219', city: 'Trasadingen', canton: 'SH' },
  { npa: '8217', city: 'Wilchingen', canton: 'SH' },
  { npa: '8217', city: 'Wilchingen', canton: 'SH' },
  { npa: '8218', city: 'Osterfingen', canton: 'SH' },
  { npa: '9100', city: 'Herisau', canton: 'AR' },
  { npa: '9112', city: 'Schachen b. Herisau', canton: 'AR' },
  { npa: '9064', city: 'Hundwil', canton: 'AR' },
  { npa: '9105', city: 'Schönengrund', canton: 'AR' },
  { npa: '9103', city: 'Schwellbrunn', canton: 'AR' },
  { npa: '9063', city: 'Stein AR', canton: 'AR' },
  { npa: '9107', city: 'Urnäsch', canton: 'AR' },
  { npa: '9104', city: 'Waldstatt', canton: 'AR' },
  { npa: '9055', city: 'Bühler', canton: 'AR' },
  { npa: '9056', city: 'Gais', canton: 'AR' },
  { npa: '9037', city: 'Speicherschwendi', canton: 'AR' },
  { npa: '9042', city: 'Speicher', canton: 'AR' },
  { npa: '9052', city: 'Niederteufen', canton: 'AR' },
  { npa: '9053', city: 'Teufen AR', canton: 'AR' },
  { npa: '9062', city: 'Lustmühle', canton: 'AR' },
  { npa: '9043', city: 'Trogen', canton: 'AR' },
  { npa: '9035', city: 'Grub AR', canton: 'AR' },
  { npa: '9410', city: 'Heiden', canton: 'AR' },
  { npa: '9405', city: 'Wienacht-Tobel', canton: 'AR' },
  { npa: '9426', city: 'Lutzenberg', canton: 'AR' },
  { npa: '9038', city: 'Rehetobel', canton: 'AR' },
  { npa: '9411', city: 'Reute AR', canton: 'AR' },
  { npa: '9411', city: 'Schachen b. Reute', canton: 'AR' },
  { npa: '9044', city: 'Wald AR', canton: 'AR' },
  { npa: '9428', city: 'Walzenhausen', canton: 'AR' },
  { npa: '9427', city: 'Wolfhalden', canton: 'AR' },
  { npa: '9050', city: 'Appenzell', canton: 'AI' },
  { npa: '9050', city: 'Appenzell Meistersrüte', canton: 'AI' },
  { npa: '9108', city: 'Gonten', canton: 'AI' },
  { npa: '9108', city: 'Gontenbad', canton: 'AI' },
  { npa: '9108', city: 'Jakobsbad', canton: 'AI' },
  { npa: '9050', city: 'Appenzell Eggerstanden', canton: 'AI' },
  { npa: '9050', city: 'Appenzell Steinegg', canton: 'AI' },
  { npa: '9057', city: 'Weissbad', canton: 'AI' },
  { npa: '9058', city: 'Brülisau', canton: 'AI' },
  { npa: '9050', city: 'Appenzell Enggenhütten', canton: 'AI' },
  { npa: '9050', city: 'Appenzell Schlatt', canton: 'AI' },
  { npa: '9054', city: 'Haslen AI', canton: 'AI' },
  { npa: '9057', city: 'Schwende', canton: 'AI' },
  { npa: '9057', city: 'Wasserauen', canton: 'AI' },
  { npa: '9413', city: 'Oberegg', canton: 'AI' },
  { npa: '9413', city: 'Oberegg', canton: 'AI' },
  { npa: '9442', city: 'Büriswilen', canton: 'AI' },
  { npa: '9308', city: 'Lömmenschwil', canton: 'SG' },
  { npa: '9312', city: 'Häggenschwil', canton: 'SG' },
  { npa: '9313', city: 'Muolen', canton: 'SG' },
  { npa: '9000', city: 'St. Gallen', canton: 'SG' },
  { npa: '9008', city: 'St. Gallen', canton: 'SG' },
  { npa: '9010', city: 'St. Gallen', canton: 'SG' },
  { npa: '9011', city: 'St. Gallen', canton: 'SG' },
  { npa: '9012', city: 'St. Gallen', canton: 'SG' },
  { npa: '9014', city: 'St. Gallen', canton: 'SG' },
  { npa: '9015', city: 'St. Gallen', canton: 'SG' },
  { npa: '9016', city: 'St. Gallen', canton: 'SG' },
  { npa: '9300', city: 'Wittenbach', canton: 'SG' },
  { npa: '9305', city: 'Berg SG', canton: 'SG' },
  { npa: '9034', city: 'Eggersriet', canton: 'SG' },
  { npa: '9036', city: 'Grub SG', canton: 'SG' },
  { npa: '9403', city: 'Goldach', canton: 'SG' },
  { npa: '9402', city: 'Mörschwil', canton: 'SG' },
  { npa: '9400', city: 'Rorschach', canton: 'SG' },
  { npa: '9404', city: 'Rorschacherberg', canton: 'SG' },
  { npa: '9323', city: 'Steinach', canton: 'SG' },
  { npa: '9327', city: 'Tübach', canton: 'SG' },
  { npa: '9033', city: 'Untereggen', canton: 'SG' },
  { npa: '9434', city: 'Au SG', canton: 'SG' },
  { npa: '9435', city: 'Heerbrugg', canton: 'SG' },
  { npa: '9436', city: 'Balgach', canton: 'SG' },
  { npa: '9411', city: 'Reute AR', canton: 'SG' },
  { npa: '9442', city: 'Berneck', canton: 'SG' },
  { npa: '9444', city: 'Diepoldsau', canton: 'SG' },
  { npa: '9424', city: 'Rheineck', canton: 'SG' },
  { npa: '9430', city: 'St. Margrethen SG', canton: 'SG' },
  { npa: '9422', city: 'Staad SG', canton: 'SG' },
  { npa: '9423', city: 'Altenrhein', canton: 'SG' },
  { npa: '9425', city: 'Thal', canton: 'SG' },
  { npa: '9443', city: 'Widnau', canton: 'SG' },
  { npa: '9450', city: 'Altstätten SG', canton: 'SG' },
  { npa: '9450', city: 'Lüchingen', canton: 'SG' },
  { npa: '9452', city: 'Hinterforst', canton: 'SG' },
  { npa: '9464', city: 'Lienz', canton: 'SG' },
  { npa: '9453', city: 'Eichberg', canton: 'SG' },
  { npa: '9437', city: 'Marbach SG', canton: 'SG' },
  { npa: '9451', city: 'Kriessern', canton: 'SG' },
  { npa: '9462', city: 'Montlingen', canton: 'SG' },
  { npa: '9463', city: 'Oberriet SG', canton: 'SG' },
  { npa: '9445', city: 'Rebstein', canton: 'SG' },
  { npa: '9464', city: 'Rüthi (Rheintal)', canton: 'SG' },
  { npa: '9470', city: 'Buchs SG', canton: 'SG' },
  { npa: '9473', city: 'Gams', canton: 'SG' },
  { npa: '9470', city: 'Werdenberg', canton: 'SG' },
  { npa: '9472', city: 'Grabs', canton: 'SG' },
  { npa: '9472', city: 'Grabserberg', canton: 'SG' },
  { npa: '9465', city: 'Salez', canton: 'SG' },
  { npa: '9466', city: 'Sennwald', canton: 'SG' },
  { npa: '9467', city: 'Frümsen', canton: 'SG' },
  { npa: '9468', city: 'Sax', canton: 'SG' },
  { npa: '9469', city: 'Haag (Rheintal)', canton: 'SG' },
  { npa: '9475', city: 'Sevelen', canton: 'SG' },
  { npa: '9476', city: 'Weite', canton: 'SG' },
  { npa: '9476', city: 'Fontnas', canton: 'SG' },
  { npa: '9477', city: 'Trübbach', canton: 'SG' },
  { npa: '9478', city: 'Azmoos', canton: 'SG' },
  { npa: '9479', city: 'Oberschan', canton: 'SG' },
  { npa: '9479', city: 'Malans SG', canton: 'SG' },
  { npa: '9479', city: 'Gretschins', canton: 'SG' },
  { npa: '7310', city: 'Bad Ragaz', canton: 'SG' },
  { npa: '8890', city: 'Flums', canton: 'SG' },
  { npa: '8893', city: 'Flums Hochwiese', canton: 'SG' },
  { npa: '8894', city: 'Flumserberg Saxli', canton: 'SG' },
  { npa: '8895', city: 'Flumserberg Portels', canton: 'SG' },
  { npa: '8896', city: 'Flumserberg Bergheim', canton: 'SG' },
  { npa: '8897', city: 'Flumserberg Tannenheim', canton: 'SG' },
  { npa: '8898', city: 'Flumserberg Tannenbodenalp', canton: 'SG' },
  { npa: '7325', city: 'Schwendi im Weisstannental', canton: 'SG' },
  { npa: '7326', city: 'Weisstannen', canton: 'SG' },
  { npa: '8886', city: 'Mädris-Vermol', canton: 'SG' },
  { npa: '8887', city: 'Mels', canton: 'SG' },
  { npa: '8888', city: 'Heiligkreuz (Mels)', canton: 'SG' },
  { npa: '8889', city: 'Plons', canton: 'SG' },
  { npa: '7312', city: 'Pfäfers', canton: 'SG' },
  { npa: '7313', city: 'St. Margrethenberg', canton: 'SG' },
  { npa: '7314', city: 'Vadura', canton: 'SG' },
  { npa: '7315', city: 'Vättis', canton: 'SG' },
  { npa: '7317', city: 'Valens', canton: 'SG' },
  { npa: '7317', city: 'Vasön', canton: 'SG' },
  { npa: '8877', city: 'Murg', canton: 'SG' },
  { npa: '8878', city: 'Quinten', canton: 'SG' },
  { npa: '8882', city: 'Unterterzen', canton: 'SG' },
  { npa: '8883', city: 'Quarten', canton: 'SG' },
  { npa: '8884', city: 'Oberterzen', canton: 'SG' },
  { npa: '8885', city: 'Mols', canton: 'SG' },
  { npa: '7320', city: 'Sargans', canton: 'SG' },
  { npa: '7323', city: 'Wangs', canton: 'SG' },
  { npa: '7323', city: 'Wangs', canton: 'SG' },
  { npa: '7324', city: 'Vilters', canton: 'SG' },
  { npa: '8880', city: 'Walenstadt', canton: 'SG' },
  { npa: '8881', city: 'Walenstadtberg', canton: 'SG' },
  { npa: '8881', city: 'Tscherlach', canton: 'SG' },
  { npa: '8892', city: 'Berschis', canton: 'SG' },
  { npa: '8873', city: 'Amden', canton: 'SG' },
  { npa: '8717', city: 'Benken SG', canton: 'SG' },
  { npa: '8722', city: 'Kaltbrunn', canton: 'SG' },
  { npa: '8718', city: 'Schänis', canton: 'SG' },
  { npa: '8723', city: 'Rufi', canton: 'SG' },
  { npa: '8723', city: 'Maseltrangen', canton: 'SG' },
  { npa: '8866', city: 'Ziegelbrücke', canton: 'SG' },
  { npa: '8872', city: 'Weesen', canton: 'SG' },
  { npa: '8716', city: 'Schmerikon', canton: 'SG' },
  { npa: '8730', city: 'Uznach', canton: 'SG' },
  { npa: '8640', city: 'Rapperswil SG', canton: 'SG' },
  { npa: '8645', city: 'Jona', canton: 'SG' },
  { npa: '8646', city: 'Wagen', canton: 'SG' },
  { npa: '8715', city: 'Bollingen', canton: 'SG' },
  { npa: '8725', city: 'Ernetschwil', canton: 'SG' },
  { npa: '8725', city: 'Gebertingen', canton: 'SG' },
  { npa: '8737', city: 'Gommiswald', canton: 'SG' },
  { npa: '8738', city: 'Uetliburg SG', canton: 'SG' },
  { npa: '8739', city: 'Rieden SG', canton: 'SG' },
  { npa: '8638', city: 'Goldingen', canton: 'SG' },
  { npa: '8727', city: 'Walde SG', canton: 'SG' },
  { npa: '8732', city: 'Neuhaus SG', canton: 'SG' },
  { npa: '8733', city: 'Eschenbach SG', canton: 'SG' },
  { npa: '8734', city: 'Ermenswil', canton: 'SG' },
  { npa: '8735', city: 'St. Gallenkappel', canton: 'SG' },
  { npa: '8735', city: 'Rüeterswil', canton: 'SG' },
  { npa: '9642', city: 'Ebnat-Kappel', canton: 'SG' },
  { npa: '9656', city: 'Alt St. Johann', canton: 'SG' },
  { npa: '9657', city: 'Unterwasser', canton: 'SG' },
  { npa: '9658', city: 'Wildhaus', canton: 'SG' },
  { npa: '9643', city: 'Krummenau', canton: 'SG' },
  { npa: '9650', city: 'Nesslau', canton: 'SG' },
  { npa: '9651', city: 'Ennetbühl', canton: 'SG' },
  { npa: '9652', city: 'Neu St. Johann', canton: 'SG' },
  { npa: '9655', city: 'Stein SG', canton: 'SG' },
  { npa: '9633', city: 'Hemberg', canton: 'SG' },
  { npa: '9633', city: 'Bächli (Hemberg)', canton: 'SG' },
  { npa: '9620', city: 'Lichtensteig', canton: 'SG' },
  { npa: '9621', city: 'Oberhelfenschwil', canton: 'SG' },
  { npa: '9114', city: 'Hoffeld', canton: 'SG' },
  { npa: '9115', city: 'Dicken', canton: 'SG' },
  { npa: '9122', city: 'Mogelsberg', canton: 'SG' },
  { npa: '9122', city: 'Ebersol', canton: 'SG' },
  { npa: '9123', city: 'Nassen', canton: 'SG' },
  { npa: '9125', city: 'Brunnadern', canton: 'SG' },
  { npa: '9126', city: 'Necker', canton: 'SG' },
  { npa: '9127', city: 'St. Peterzell', canton: 'SG' },
  { npa: '8726', city: 'Ricken SG', canton: 'SG' },
  { npa: '9622', city: 'Krinau', canton: 'SG' },
  { npa: '9630', city: 'Wattwil', canton: 'SG' },
  { npa: '9631', city: 'Ulisbach', canton: 'SG' },
  { npa: '9500', city: 'Wil SG', canton: 'SG' },
  { npa: '9533', city: 'Kirchberg SG', canton: 'SG' },
  { npa: '9533', city: 'Dietschwil', canton: 'SG' },
  { npa: '9534', city: 'Gähwil', canton: 'SG' },
  { npa: '9602', city: 'Bazenheid', canton: 'SG' },
  { npa: '9602', city: 'Müselbach', canton: 'SG' },
  { npa: '9601', city: 'Lütisburg Station', canton: 'SG' },
  { npa: '9604', city: 'Lütisburg', canton: 'SG' },
  { npa: '9604', city: 'Unterrindal', canton: 'SG' },
  { npa: '9607', city: 'Mosnang', canton: 'SG' },
  { npa: '9612', city: 'Dreien', canton: 'SG' },
  { npa: '9613', city: 'Mühlrüti', canton: 'SG' },
  { npa: '9614', city: 'Libingen', canton: 'SG' },
  { npa: '9606', city: 'Bütschwil', canton: 'SG' },
  { npa: '9608', city: 'Ganterschwil', canton: 'SG' },
  { npa: '9615', city: 'Dietfurt', canton: 'SG' },
  { npa: '9113', city: 'Degersheim', canton: 'SG' },
  { npa: '9116', city: 'Wolfertswil', canton: 'SG' },
  { npa: '9230', city: 'Flawil', canton: 'SG' },
  { npa: '9231', city: 'Egg (Flawil)', canton: 'SG' },
  { npa: '9243', city: 'Jonschwil', canton: 'SG' },
  { npa: '9532', city: 'Rickenbach b. Wil', canton: 'SG' },
  { npa: '9536', city: 'Schwarzenbach SG', canton: 'SG' },
  { npa: '9604', city: 'Oberrindal', canton: 'SG' },
  { npa: '9240', city: 'Niederglatt SG', canton: 'SG' },
  { npa: '9242', city: 'Oberuzwil', canton: 'SG' },
  { npa: '9248', city: 'Bichwil', canton: 'SG' },
  { npa: '9240', city: 'Uzwil', canton: 'SG' },
  { npa: '9244', city: 'Niederuzwil', canton: 'SG' },
  { npa: '9247', city: 'Henau', canton: 'SG' },
  { npa: '9249', city: 'Algetshausen', canton: 'SG' },
  { npa: '9249', city: 'Niederstetten', canton: 'SG' },
  { npa: '9249', city: 'Oberstetten', canton: 'SG' },
  { npa: '9246', city: 'Niederbüren', canton: 'SG' },
  { npa: '9525', city: 'Lenggenwil', canton: 'SG' },
  { npa: '9526', city: 'Zuckenriet', canton: 'SG' },
  { npa: '9527', city: 'Niederhelfenschwil', canton: 'SG' },
  { npa: '9203', city: 'Niederwil SG', canton: 'SG' },
  { npa: '9245', city: 'Oberbüren', canton: 'SG' },
  { npa: '9245', city: 'Sonnental', canton: 'SG' },
  { npa: '9523', city: 'Züberwangen', canton: 'SG' },
  { npa: '9524', city: 'Zuzwil SG', canton: 'SG' },
  { npa: '9500', city: 'Wil SG', canton: 'SG' },
  { npa: '9512', city: 'Rossrüti', canton: 'SG' },
  { npa: '9552', city: 'Bronschhofen', canton: 'SG' },
  { npa: '9204', city: 'Andwil SG', canton: 'SG' },
  { npa: '9030', city: 'Abtwil SG', canton: 'SG' },
  { npa: '9030', city: 'St. Josefen', canton: 'SG' },
  { npa: '9032', city: 'Engelburg', canton: 'SG' },
  { npa: '9200', city: 'Gossau SG', canton: 'SG' },
  { npa: '9212', city: 'Arnegg', canton: 'SG' },
  { npa: '9205', city: 'Waldkirch', canton: 'SG' },
  { npa: '9304', city: 'Bernhardzell', canton: 'SG' },
  { npa: '7077', city: 'Valbella', canton: 'GR' },
  { npa: '7078', city: 'Lenzerheide/Lai', canton: 'GR' },
  { npa: '7082', city: 'Vaz/Obervaz', canton: 'GR' },
  { npa: '7450', city: 'Tiefencastel', canton: 'GR' },
  { npa: '7083', city: 'Lantsch/Lenz', canton: 'GR' },
  { npa: '7493', city: 'Schmitten (Albula)', canton: 'GR' },
  { npa: '7084', city: 'Brienz/Brinzauls GR', canton: 'GR' },
  { npa: '7450', city: 'Tiefencastel', canton: 'GR' },
  { npa: '7451', city: 'Alvaschein', canton: 'GR' },
  { npa: '7458', city: 'Mon', canton: 'GR' },
  { npa: '7459', city: 'Stierva', canton: 'GR' },
  { npa: '7472', city: 'Surava', canton: 'GR' },
  { npa: '7473', city: 'Alvaneu Bad', canton: 'GR' },
  { npa: '7492', city: 'Alvaneu Dorf', canton: 'GR' },
  { npa: '7452', city: 'Cunter', canton: 'GR' },
  { npa: '7453', city: 'Tinizong', canton: 'GR' },
  { npa: '7454', city: 'Rona', canton: 'GR' },
  { npa: '7455', city: 'Mulegns', canton: 'GR' },
  { npa: '7456', city: 'Sur', canton: 'GR' },
  { npa: '7456', city: 'Marmorera', canton: 'GR' },
  { npa: '7457', city: 'Bivio', canton: 'GR' },
  { npa: '7460', city: 'Savognin', canton: 'GR' },
  { npa: '7462', city: 'Salouf', canton: 'GR' },
  { npa: '7463', city: 'Riom', canton: 'GR' },
  { npa: '7463', city: 'Riom', canton: 'GR' },
  { npa: '7464', city: 'Parsonz', canton: 'GR' },
  { npa: '7477', city: 'Filisur', canton: 'GR' },
  { npa: '7482', city: 'Bergün/Bravuogn', canton: 'GR' },
  { npa: '7482', city: 'Preda', canton: 'GR' },
  { npa: '7482', city: 'Stugl/Stuls', canton: 'GR' },
  { npa: '7484', city: 'Latsch', canton: 'GR' },
  { npa: '7743', city: 'Brusio', canton: 'GR' },
  { npa: '7744', city: 'Campocologno', canton: 'GR' },
  { npa: '7747', city: 'Viano', canton: 'GR' },
  { npa: '7748', city: 'Campascio', canton: 'GR' },
  { npa: '7710', city: 'Ospizio Bernina', canton: 'GR' },
  { npa: '7710', city: 'Alp Grüm', canton: 'GR' },
  { npa: '7741', city: 'S. Carlo (Poschiavo)', canton: 'GR' },
  { npa: '7742', city: 'Poschiavo', canton: 'GR' },
  { npa: '7742', city: 'Sfazù', canton: 'GR' },
  { npa: '7742', city: 'La Rösa', canton: 'GR' },
  { npa: '7743', city: 'Miralago', canton: 'GR' },
  { npa: '7745', city: 'Li Curt', canton: 'GR' },
  { npa: '7746', city: 'Le Prese', canton: 'GR' },
  { npa: '7153', city: 'Falera', canton: 'GR' },
  { npa: '7031', city: 'Laax GR', canton: 'GR' },
  { npa: '7032', city: 'Laax GR 2', canton: 'GR' },
  { npa: '7152', city: 'Sagogn', canton: 'GR' },
  { npa: '7151', city: 'Schluein', canton: 'GR' },
  { npa: '7116', city: 'St. Martin (Lugnez)', canton: 'GR' },
  { npa: '7132', city: 'Vals', canton: 'GR' },
  { npa: '7110', city: 'Peiden', canton: 'GR' },
  { npa: '7113', city: 'Camuns', canton: 'GR' },
  { npa: '7114', city: 'Uors (Lumnezia)', canton: 'GR' },
  { npa: '7115', city: 'Surcasti', canton: 'GR' },
  { npa: '7116', city: 'Tersnaus', canton: 'GR' },
  { npa: '7142', city: 'Cumbel', canton: 'GR' },
  { npa: '7143', city: 'Morissen', canton: 'GR' },
  { npa: '7144', city: 'Vella', canton: 'GR' },
  { npa: '7145', city: 'Degen', canton: 'GR' },
  { npa: '7146', city: 'Vattiz', canton: 'GR' },
  { npa: '7147', city: 'Vignogn', canton: 'GR' },
  { npa: '7148', city: 'Lumbrein', canton: 'GR' },
  { npa: '7149', city: 'Vrin', canton: 'GR' },
  { npa: '7111', city: 'Pitasch', canton: 'GR' },
  { npa: '7112', city: 'Duvin', canton: 'GR' },
  { npa: '7126', city: 'Castrisch', canton: 'GR' },
  { npa: '7127', city: 'Sevgein', canton: 'GR' },
  { npa: '7128', city: 'Riein', canton: 'GR' },
  { npa: '7130', city: 'Ilanz', canton: 'GR' },
  { npa: '7130', city: 'Schnaus', canton: 'GR' },
  { npa: '7130', city: 'Schnaus', canton: 'GR' },
  { npa: '7141', city: 'Luven', canton: 'GR' },
  { npa: '7154', city: 'Ruschein', canton: 'GR' },
  { npa: '7155', city: 'Ladir', canton: 'GR' },
  { npa: '7155', city: 'Ladir', canton: 'GR' },
  { npa: '7156', city: 'Rueun', canton: 'GR' },
  { npa: '7156', city: 'Pigniu', canton: 'GR' },
  { npa: '7157', city: 'Siat', canton: 'GR' },
  { npa: '7413', city: 'Fürstenaubruck', canton: 'GR' },
  { npa: '7414', city: 'Fürstenau', canton: 'GR' },
  { npa: '7405', city: 'Rothenbrunnen', canton: 'GR' },
  { npa: '7412', city: 'Scharans', canton: 'GR' },
  { npa: '7411', city: 'Sils im Domleschg', canton: 'GR' },
  { npa: '7408', city: 'Cazis', canton: 'GR' },
  { npa: '7421', city: 'Summaprada', canton: 'GR' },
  { npa: '7422', city: 'Tartar', canton: 'GR' },
  { npa: '7423', city: 'Sarn', canton: 'GR' },
  { npa: '7423', city: 'Portein', canton: 'GR' },
  { npa: '7424', city: 'Präz', canton: 'GR' },
  { npa: '7424', city: 'Dalin', canton: 'GR' },
  { npa: '7426', city: 'Flerden', canton: 'GR' },
  { npa: '7426', city: 'Flerden', canton: 'GR' },
  { npa: '7425', city: 'Masein', canton: 'GR' },
  { npa: '7430', city: 'Thusis', canton: 'GR' },
  { npa: '7431', city: 'Mutten', canton: 'GR' },
  { npa: '7431', city: 'Obermutten', canton: 'GR' },
  { npa: '7428', city: 'Tschappina', canton: 'GR' },
  { npa: '7427', city: 'Urmein', canton: 'GR' },
  { npa: '7104', city: 'Versam', canton: 'GR' },
  { npa: '7106', city: 'Tenna', canton: 'GR' },
  { npa: '7107', city: 'Safien Platz', canton: 'GR' },
  { npa: '7109', city: 'Thalkirch', canton: 'GR' },
  { npa: '7122', city: 'Valendas', canton: 'GR' },
  { npa: '7404', city: 'Feldis/Veulden', canton: 'GR' },
  { npa: '7407', city: 'Trans', canton: 'GR' },
  { npa: '7415', city: 'Rodels', canton: 'GR' },
  { npa: '7415', city: 'Pratval', canton: 'GR' },
  { npa: '7416', city: 'Almens', canton: 'GR' },
  { npa: '7417', city: 'Paspels', canton: 'GR' },
  { npa: '7418', city: 'Tumegl/Tomils', canton: 'GR' },
  { npa: '7419', city: 'Scheid', canton: 'GR' },
  { npa: '7446', city: 'Campsut-Cröt', canton: 'GR' },
  { npa: '7447', city: 'Cresta (Avers)', canton: 'GR' },
  { npa: '7447', city: 'Am Bach (Avers)', canton: 'GR' },
  { npa: '7448', city: 'Juf', canton: 'GR' },
  { npa: '7434', city: 'Sufers', canton: 'GR' },
  { npa: '7440', city: 'Andeer', canton: 'GR' },
  { npa: '7442', city: 'Clugin', canton: 'GR' },
  { npa: '7443', city: 'Pignia', canton: 'GR' },
  { npa: '7430', city: 'Rongellen', canton: 'GR' },
  { npa: '7432', city: 'Zillis', canton: 'GR' },
  { npa: '7444', city: 'Ausserferrera', canton: 'GR' },
  { npa: '7445', city: 'Innerferrera', canton: 'GR' },
  { npa: '7445', city: 'Innerferrera', canton: 'GR' },
  { npa: '7435', city: 'Splügen', canton: 'GR' },
  { npa: '7436', city: 'Medels im Rheinwald', canton: 'GR' },
  { npa: '7437', city: 'Nufenen', canton: 'GR' },
  { npa: '7438', city: 'Hinterrhein', canton: 'GR' },
  { npa: '7433', city: 'Donat', canton: 'GR' },
  { npa: '7433', city: 'Lohn GR', canton: 'GR' },
  { npa: '7433', city: 'Mathon', canton: 'GR' },
  { npa: '7433', city: 'Wergenstein', canton: 'GR' },
  { npa: '7402', city: 'Bonaduz', canton: 'GR' },
  { npa: '7013', city: 'Domat/Ems', canton: 'GR' },
  { npa: '7403', city: 'Rhäzüns', canton: 'GR' },
  { npa: '7012', city: 'Felsberg', canton: 'GR' },
  { npa: '7017', city: 'Flims Dorf', canton: 'GR' },
  { npa: '7018', city: 'Flims Waldhaus', canton: 'GR' },
  { npa: '7019', city: 'Fidaz', canton: 'GR' },
  { npa: '7015', city: 'Tamins', canton: 'GR' },
  { npa: '7014', city: 'Trin', canton: 'GR' },
  { npa: '7016', city: 'Trin Mulin', canton: 'GR' },
  { npa: '7527', city: 'Brail', canton: 'GR' },
  { npa: '7530', city: 'Zernez', canton: 'GR' },
  { npa: '7542', city: 'Susch', canton: 'GR' },
  { npa: '7543', city: 'Lavin', canton: 'GR' },
  { npa: '7562', city: 'Samnaun-Compatsch', canton: 'GR' },
  { npa: '7563', city: 'Samnaun Dorf', canton: 'GR' },
  { npa: '7545', city: 'Guarda', canton: 'GR' },
  { npa: '7546', city: 'Ardez', canton: 'GR' },
  { npa: '7550', city: 'Scuol', canton: 'GR' },
  { npa: '7551', city: 'Ftan', canton: 'GR' },
  { npa: '7552', city: 'Vulpera', canton: 'GR' },
  { npa: '7553', city: 'Tarasp', canton: 'GR' },
  { npa: '7554', city: 'Sent', canton: 'GR' },
  { npa: '7556', city: 'Ramosch', canton: 'GR' },
  { npa: '7556', city: 'Ramosch', canton: 'GR' },
  { npa: '7557', city: 'Vnà', canton: 'GR' },
  { npa: '7558', city: 'Strada', canton: 'GR' },
  { npa: '7559', city: 'Tschlin', canton: 'GR' },
  { npa: '7560', city: 'Martina', canton: 'GR' },
  { npa: '7502', city: 'Bever', canton: 'GR' },
  { npa: '7502', city: 'Bever', canton: 'GR' },
  { npa: '7505', city: 'Celerina/Schlarigna', canton: 'GR' },
  { npa: '7523', city: 'Madulain', canton: 'GR' },
  { npa: '7523', city: 'Madulain', canton: 'GR' },
  { npa: '7504', city: 'Pontresina', canton: 'GR' },
  { npa: '7522', city: 'La Punt Chamues-ch', canton: 'GR' },
  { npa: '7503', city: 'Samedan', canton: 'GR' },
  { npa: '7500', city: 'St. Moritz', canton: 'GR' },
  { npa: '7525', city: 'S-chanf', canton: 'GR' },
  { npa: '7526', city: 'Cinuos-chel', canton: 'GR' },
  { npa: '7526', city: 'Chapella', canton: 'GR' },
  { npa: '7514', city: 'Sils/Segl Maria', canton: 'GR' },
  { npa: '7514', city: 'Fex', canton: 'GR' },
  { npa: '7515', city: 'Sils/Segl Baselgia', canton: 'GR' },
  { npa: '7517', city: 'Plaun da Lej', canton: 'GR' },
  { npa: '7512', city: 'Champfèr', canton: 'GR' },
  { npa: '7513', city: 'Silvaplana', canton: 'GR' },
  { npa: '7513', city: 'Silvaplana-Surlej', canton: 'GR' },
  { npa: '7524', city: 'Zuoz', canton: 'GR' },
  { npa: '7524', city: 'Zuoz', canton: 'GR' },
  { npa: '7516', city: 'Maloja', canton: 'GR' },
  { npa: '7602', city: 'Casaccia', canton: 'GR' },
  { npa: '7603', city: 'Vicosoprano', canton: 'GR' },
  { npa: '7604', city: 'Borgonovo', canton: 'GR' },
  { npa: '7605', city: 'Stampa', canton: 'GR' },
  { npa: '7606', city: 'Promontogno', canton: 'GR' },
  { npa: '7606', city: 'Bondo', canton: 'GR' },
  { npa: '7608', city: 'Castasegna', canton: 'GR' },
  { npa: '7610', city: 'Soglio', canton: 'GR' },
  { npa: '6542', city: 'Buseno', canton: 'GR' },
  { npa: '6540', city: 'Castaneda', canton: 'GR' },
  { npa: '6548', city: 'Rossa', canton: 'GR' },
  { npa: '6548', city: 'Augio', canton: 'GR' },
  { npa: '6548', city: 'Sta. Domenica', canton: 'GR' },
  { npa: '6541', city: 'Sta. Maria in Calanca', canton: 'GR' },
  { npa: '6558', city: 'Lostallo', canton: 'GR' },
  { npa: '6563', city: 'Mesocco', canton: 'GR' },
  { npa: '6565', city: 'S. Bernardino', canton: 'GR' },
  { npa: '6562', city: 'Soazza', canton: 'GR' },
  { npa: '6557', city: 'Cama', canton: 'GR' },
  { npa: '6537', city: 'Grono', canton: 'GR' },
  { npa: '6538', city: 'Verdabbio', canton: 'GR' },
  { npa: '6538', city: 'Verdabbio', canton: 'GR' },
  { npa: '6556', city: 'Leggia', canton: 'GR' },
  { npa: '6535', city: 'Roveredo GR', canton: 'GR' },
  { npa: '6549', city: 'Laura', canton: 'GR' },
  { npa: '6534', city: 'S. Vittore', canton: 'GR' },
  { npa: '6534', city: 'S. Vittore', canton: 'GR' },
  { npa: '6543', city: 'Arvigo', canton: 'GR' },
  { npa: '6544', city: 'Braggio', canton: 'GR' },
  { npa: '6545', city: 'Selma', canton: 'GR' },
  { npa: '6546', city: 'Cauco', canton: 'GR' },
  { npa: '7532', city: 'Tschierv', canton: 'GR' },
  { npa: '7533', city: 'Fuldera', canton: 'GR' },
  { npa: '7534', city: 'Lü', canton: 'GR' },
  { npa: '7535', city: 'Valchava', canton: 'GR' },
  { npa: '7536', city: 'Sta. Maria Val Müstair', canton: 'GR' },
  { npa: '7537', city: 'Müstair', canton: 'GR' },
  { npa: '7260', city: 'Davos Dorf', canton: 'GR' },
  { npa: '7265', city: 'Davos Wolfgang', canton: 'GR' },
  { npa: '7270', city: 'Davos Platz', canton: 'GR' },
  { npa: '7272', city: 'Davos Clavadel', canton: 'GR' },
  { npa: '7276', city: 'Davos Frauenkirch', canton: 'GR' },
  { npa: '7277', city: 'Davos Glaris', canton: 'GR' },
  { npa: '7278', city: 'Davos Monstein', canton: 'GR' },
  { npa: '7494', city: 'Davos Wiesen', canton: 'GR' },
  { npa: '7235', city: 'Fideris', canton: 'GR' },
  { npa: '7232', city: 'Furna', canton: 'GR' },
  { npa: '7231', city: 'Pragg-Jenaz', canton: 'GR' },
  { npa: '7233', city: 'Jenaz', canton: 'GR' },
  { npa: '7247', city: 'Saas im Prättigau', canton: 'GR' },
  { npa: '7249', city: 'Serneus', canton: 'GR' },
  { npa: '7250', city: 'Klosters', canton: 'GR' },
  { npa: '7252', city: 'Klosters Dorf', canton: 'GR' },
  { npa: '7241', city: 'Conters im Prättigau', canton: 'GR' },
  { npa: '7240', city: 'Küblis', canton: 'GR' },
  { npa: '7223', city: 'Buchen im Prättigau', canton: 'GR' },
  { npa: '7224', city: 'Putz', canton: 'GR' },
  { npa: '7242', city: 'Luzein', canton: 'GR' },
  { npa: '7243', city: 'Pany', canton: 'GR' },
  { npa: '7244', city: 'Gadenstätt', canton: 'GR' },
  { npa: '7245', city: 'Ascharina', canton: 'GR' },
  { npa: '7246', city: 'St. Antönien', canton: 'GR' },
  { npa: '7000', city: 'Chur', canton: 'GR' },
  { npa: '7023', city: 'Haldenstein', canton: 'GR' },
  { npa: '7026', city: 'Maladers', canton: 'GR' },
  { npa: '7062', city: 'Passugg', canton: 'GR' },
  { npa: '7074', city: 'Malix', canton: 'GR' },
  { npa: '7075', city: 'Churwalden', canton: 'GR' },
  { npa: '7076', city: 'Parpan', canton: 'GR' },
  { npa: '7027', city: 'Castiel', canton: 'GR' },
  { npa: '7027', city: 'Lüen', canton: 'GR' },
  { npa: '7027', city: 'Calfreisen', canton: 'GR' },
  { npa: '7028', city: 'St. Peter', canton: 'GR' },
  { npa: '7028', city: 'Pagig', canton: 'GR' },
  { npa: '7029', city: 'Peist', canton: 'GR' },
  { npa: '7050', city: 'Arosa', canton: 'GR' },
  { npa: '7056', city: 'Molinis', canton: 'GR' },
  { npa: '7057', city: 'Langwies', canton: 'GR' },
  { npa: '7058', city: 'Litzirüti', canton: 'GR' },
  { npa: '7063', city: 'Praden', canton: 'GR' },
  { npa: '7064', city: 'Tschiertschen', canton: 'GR' },
  { npa: '7202', city: 'Says', canton: 'GR' },
  { npa: '7203', city: 'Trimmis', canton: 'GR' },
  { npa: '7204', city: 'Untervaz', canton: 'GR' },
  { npa: '7205', city: 'Zizers', canton: 'GR' },
  { npa: '7306', city: 'Fläsch', canton: 'GR' },
  { npa: '7307', city: 'Jenins', canton: 'GR' },
  { npa: '7304', city: 'Maienfeld', canton: 'GR' },
  { npa: '7208', city: 'Malans GR', canton: 'GR' },
  { npa: '7206', city: 'Igis', canton: 'GR' },
  { npa: '7302', city: 'Landquart', canton: 'GR' },
  { npa: '7303', city: 'Mastrils', canton: 'GR' },
  { npa: '7213', city: 'Valzeina', canton: 'GR' },
  { npa: '7214', city: 'Grüsch', canton: 'GR' },
  { npa: '7215', city: 'Fanas', canton: 'GR' },
  { npa: '7220', city: 'Schiers', canton: 'GR' },
  { npa: '7220', city: 'Schiers', canton: 'GR' },
  { npa: '7222', city: 'Lunden', canton: 'GR' },
  { npa: '7226', city: 'Stels', canton: 'GR' },
  { npa: '7226', city: 'Stels', canton: 'GR' },
  { npa: '7226', city: 'Fajauna', canton: 'GR' },
  { npa: '7228', city: 'Schuders', canton: 'GR' },
  { npa: '7228', city: 'Pusserein', canton: 'GR' },
  { npa: '7212', city: 'Seewis Dorf', canton: 'GR' },
  { npa: '7212', city: 'Seewis-Pardisla', canton: 'GR' },
  { npa: '7212', city: 'Seewis-Schmitten', canton: 'GR' },
  { npa: '7158', city: 'Waltensburg/Vuorz', canton: 'GR' },
  { npa: '7159', city: 'Andiast', canton: 'GR' },
  { npa: '7162', city: 'Tavanasa', canton: 'GR' },
  { npa: '7163', city: 'Danis', canton: 'GR' },
  { npa: '7164', city: 'Dardin', canton: 'GR' },
  { npa: '7165', city: 'Breil/Brigels', canton: 'GR' },
  { npa: '7180', city: 'Disentis/Mustér', canton: 'GR' },
  { npa: '7182', city: 'Cavardiras', canton: 'GR' },
  { npa: '7183', city: 'Mumpé Medel', canton: 'GR' },
  { npa: '7186', city: 'Segnas', canton: 'GR' },
  { npa: '7184', city: 'Curaglia', canton: 'GR' },
  { npa: '7185', city: 'Platta', canton: 'GR' },
  { npa: '7172', city: 'Rabius', canton: 'GR' },
  { npa: '7173', city: 'Surrein', canton: 'GR' },
  { npa: '7174', city: 'S. Benedetg', canton: 'GR' },
  { npa: '7175', city: 'Sumvitg', canton: 'GR' },
  { npa: '7176', city: 'Cumpadials', canton: 'GR' },
  { npa: '7187', city: 'Camischolas', canton: 'GR' },
  { npa: '7188', city: 'Sedrun', canton: 'GR' },
  { npa: '7189', city: 'Rueras', canton: 'GR' },
  { npa: '7166', city: 'Trun', canton: 'GR' },
  { npa: '7167', city: 'Zignau', canton: 'GR' },
  { npa: '7168', city: 'Schlans', canton: 'GR' },
  { npa: '7134', city: 'Obersaxen', canton: 'GR' },
  { npa: '7137', city: 'Flond', canton: 'GR' },
  { npa: '7138', city: 'Surcuolm', canton: 'GR' },
  { npa: '5000', city: 'Aarau', canton: 'AG' },
  { npa: '5004', city: 'Aarau', canton: 'AG' },
  { npa: '5032', city: 'Aarau Rohr', canton: 'AG' },
  { npa: '5023', city: 'Biberstein', canton: 'AG' },
  { npa: '5033', city: 'Buchs AG', canton: 'AG' },
  { npa: '5025', city: 'Asp', canton: 'AG' },
  { npa: '5026', city: 'Densbüren', canton: 'AG' },
  { npa: '5017', city: 'Barmelweid', canton: 'AG' },
  { npa: '5018', city: 'Erlinsbach', canton: 'AG' },
  { npa: '5722', city: 'Gränichen', canton: 'AG' },
  { npa: '5042', city: 'Hirschthal', canton: 'AG' },
  { npa: '5022', city: 'Rombach', canton: 'AG' },
  { npa: '5024', city: 'Küttigen', canton: 'AG' },
  { npa: '5037', city: 'Muhen', canton: 'AG' },
  { npa: '5036', city: 'Oberentfelden', canton: 'AG' },
  { npa: '5034', city: 'Suhr', canton: 'AG' },
  { npa: '5035', city: 'Unterentfelden', canton: 'AG' },
  { npa: '5400', city: 'Baden', canton: 'AG' },
  { npa: '5404', city: 'Baden', canton: 'AG' },
  { npa: '5405', city: 'Dättwil AG', canton: 'AG' },
  { npa: '5406', city: 'Rütihof', canton: 'AG' },
  { npa: '5454', city: 'Bellikon', canton: 'AG' },
  { npa: '8962', city: 'Bergdietikon', canton: 'AG' },
  { npa: '5413', city: 'Birmenstorf AG', canton: 'AG' },
  { npa: '5408', city: 'Ennetbaden', canton: 'AG' },
  { npa: '5442', city: 'Fislisbach', canton: 'AG' },
  { npa: '5423', city: 'Freienwil', canton: 'AG' },
  { npa: '5412', city: 'Gebenstorf', canton: 'AG' },
  { npa: '5412', city: 'Vogelsang AG', canton: 'AG' },
  { npa: '8956', city: 'Killwangen', canton: 'AG' },
  { npa: '5444', city: 'Künten', canton: 'AG' },
  { npa: '5506', city: 'Mägenwil', canton: 'AG' },
  { npa: '5507', city: 'Mellingen', canton: 'AG' },
  { npa: '5432', city: 'Neuenhof', canton: 'AG' },
  { npa: '5443', city: 'Niederrohrdorf', canton: 'AG' },
  { npa: '5452', city: 'Oberrohrdorf', canton: 'AG' },
  { npa: '5415', city: 'Nussbaumen AG', canton: 'AG' },
  { npa: '5415', city: 'Hertenstein AG', canton: 'AG' },
  { npa: '5415', city: 'Rieden AG', canton: 'AG' },
  { npa: '5416', city: 'Kirchdorf AG', canton: 'AG' },
  { npa: '5453', city: 'Remetschwil', canton: 'AG' },
  { npa: '8957', city: 'Spreitenbach', canton: 'AG' },
  { npa: '5608', city: 'Stetten AG', canton: 'AG' },
  { npa: '5300', city: 'Turgi', canton: 'AG' },
  { npa: '5301', city: 'Siggenthal Station', canton: 'AG' },
  { npa: '5417', city: 'Untersiggenthal', canton: 'AG' },
  { npa: '5430', city: 'Wettingen', canton: 'AG' },
  { npa: '5512', city: 'Wohlenschwil', canton: 'AG' },
  { npa: '5303', city: 'Würenlingen', canton: 'AG' },
  { npa: '5436', city: 'Würenlos', canton: 'AG' },
  { npa: '8109', city: 'Kloster Fahr', canton: 'AG' },
  { npa: '5420', city: 'Ehrendingen', canton: 'AG' },
  { npa: '8905', city: 'Arni AG', canton: 'AG' },
  { npa: '8965', city: 'Berikon', canton: 'AG' },
  { npa: '5620', city: 'Bremgarten AG', canton: 'AG' },
  { npa: '5626', city: 'Hermetschwil-Staffeln', canton: 'AG' },
  { npa: '5619', city: 'Büttikon AG', canton: 'AG' },
  { npa: '5605', city: 'Dottikon', canton: 'AG' },
  { npa: '5445', city: 'Eggenwil', canton: 'AG' },
  { npa: '5525', city: 'Fischbach-Göslikon', canton: 'AG' },
  { npa: '5607', city: 'Hägglingen', canton: 'AG' },
  { npa: '8916', city: 'Jonen', canton: 'AG' },
  { npa: '5524', city: 'Niederwil AG', canton: 'AG' },
  { npa: '5524', city: 'Nesselnbach', canton: 'AG' },
  { npa: '8917', city: 'Oberlunkhofen', canton: 'AG' },
  { npa: '8966', city: 'Oberwil-Lieli', canton: 'AG' },
  { npa: '8964', city: 'Rudolfstetten', canton: 'AG' },
  { npa: '5614', city: 'Sarmenstorf', canton: 'AG' },
  { npa: '5522', city: 'Tägerig', canton: 'AG' },
  { npa: '5619', city: 'Uezwil', canton: 'AG' },
  { npa: '8918', city: 'Unterlunkhofen', canton: 'AG' },
  { npa: '5612', city: 'Villmergen', canton: 'AG' },
  { npa: '5613', city: 'Hilfikon', canton: 'AG' },
  { npa: '8967', city: 'Widen', canton: 'AG' },
  { npa: '5610', city: 'Wohlen AG', canton: 'AG' },
  { npa: '5611', city: 'Anglikon', canton: 'AG' },
  { npa: '5621', city: 'Zufikon', canton: 'AG' },
  { npa: '8905', city: 'Islisberg', canton: 'AG' },
  { npa: '5105', city: 'Auenstein', canton: 'AG' },
  { npa: '5242', city: 'Birr', canton: 'AG' },
  { npa: '5244', city: 'Birrhard', canton: 'AG' },
  { npa: '5116', city: 'Schinznach Bad', canton: 'AG' },
  { npa: '5200', city: 'Brugg AG', canton: 'AG' },
  { npa: '5222', city: 'Umiken', canton: 'AG' },
  { npa: '5245', city: 'Habsburg', canton: 'AG' },
  { npa: '5212', city: 'Hausen AG', canton: 'AG' },
  { npa: '5242', city: 'Lupfig', canton: 'AG' },
  { npa: '5246', city: 'Scherz', canton: 'AG' },
  { npa: '5318', city: 'Mandach', canton: 'AG' },
  { npa: '5237', city: 'Mönthal', canton: 'AG' },
  { npa: '5243', city: 'Mülligen', canton: 'AG' },
  { npa: '5236', city: 'Remigen', canton: 'AG' },
  { npa: '5223', city: 'Riniken', canton: 'AG' },
  { npa: '5235', city: 'Rüfenach AG', canton: 'AG' },
  { npa: '5112', city: 'Thalheim AG', canton: 'AG' },
  { npa: '5106', city: 'Veltheim AG', canton: 'AG' },
  { npa: '5233', city: 'Stilli', canton: 'AG' },
  { npa: '5234', city: 'Villigen', canton: 'AG' },
  { npa: '5213', city: 'Villnachern', canton: 'AG' },
  { npa: '5210', city: 'Windisch', canton: 'AG' },
  { npa: '5225', city: 'Bözberg', canton: 'AG' },
  { npa: '5107', city: 'Schinznach Dorf', canton: 'AG' },
  { npa: '5108', city: 'Oberflachs', canton: 'AG' },
  { npa: '5712', city: 'Beinwil am See', canton: 'AG' },
  { npa: '5708', city: 'Birrwil', canton: 'AG' },
  { npa: '5736', city: 'Burg AG', canton: 'AG' },
  { npa: '5724', city: 'Dürrenäsch', canton: 'AG' },
  { npa: '5728', city: 'Gontenschwil', canton: 'AG' },
  { npa: '5043', city: 'Holziken', canton: 'AG' },
  { npa: '5733', city: 'Leimbach AG', canton: 'AG' },
  { npa: '5725', city: 'Leutwil', canton: 'AG' },
  { npa: '5737', city: 'Menziken', canton: 'AG' },
  { npa: '5727', city: 'Oberkulm', canton: 'AG' },
  { npa: '5734', city: 'Reinach AG', canton: 'AG' },
  { npa: '5044', city: 'Schlossrued', canton: 'AG' },
  { npa: '5046', city: 'Schmiedrued', canton: 'AG' },
  { npa: '5046', city: 'Walde AG', canton: 'AG' },
  { npa: '5040', city: 'Schöftland', canton: 'AG' },
  { npa: '5723', city: 'Teufenthal AG', canton: 'AG' },
  { npa: '5726', city: 'Unterkulm', canton: 'AG' },
  { npa: '5732', city: 'Zetzwil', canton: 'AG' },
  { npa: '5074', city: 'Eiken', canton: 'AG' },
  { npa: '5070', city: 'Frick', canton: 'AG' },
  { npa: '5272', city: 'Gansingen', canton: 'AG' },
  { npa: '5073', city: 'Gipf-Oberfrick', canton: 'AG' },
  { npa: '5027', city: 'Herznach', canton: 'AG' },
  { npa: '5082', city: 'Kaisten', canton: 'AG' },
  { npa: '5083', city: 'Ittenthal', canton: 'AG' },
  { npa: '5080', city: 'Laufenburg', canton: 'AG' },
  { npa: '5084', city: 'Rheinsulz', canton: 'AG' },
  { npa: '5085', city: 'Sulz AG', canton: 'AG' },
  { npa: '4333', city: 'Münchwilen AG', canton: 'AG' },
  { npa: '5062', city: 'Oberhof', canton: 'AG' },
  { npa: '5072', city: 'Oeschgen', canton: 'AG' },
  { npa: '5326', city: 'Schwaderloch', canton: 'AG' },
  { npa: '4334', city: 'Sisseln AG', canton: 'AG' },
  { npa: '5028', city: 'Ueken', canton: 'AG' },
  { npa: '5064', city: 'Wittnau', canton: 'AG' },
  { npa: '5063', city: 'Wölflinswil', canton: 'AG' },
  { npa: '5079', city: 'Zeihen', canton: 'AG' },
  { npa: '5273', city: 'Oberhofen AG', canton: 'AG' },
  { npa: '5274', city: 'Mettau', canton: 'AG' },
  { npa: '5275', city: 'Etzgen', canton: 'AG' },
  { npa: '5276', city: 'Wil AG', canton: 'AG' },
  { npa: '5277', city: 'Hottwil', canton: 'AG' },
  { npa: '5075', city: 'Hornussen', canton: 'AG' },
  { npa: '5076', city: 'Bözen', canton: 'AG' },
  { npa: '5077', city: 'Elfingen', canton: 'AG' },
  { npa: '5078', city: 'Effingen', canton: 'AG' },
  { npa: '5600', city: 'Ammerswil AG', canton: 'AG' },
  { npa: '5706', city: 'Boniswil', canton: 'AG' },
  { npa: '5505', city: 'Brunegg', canton: 'AG' },
  { npa: '5606', city: 'Dintikon', canton: 'AG' },
  { npa: '5704', city: 'Egliswil', canton: 'AG' },
  { npa: '5615', city: 'Fahrwangen', canton: 'AG' },
  { npa: '5705', city: 'Hallwil', canton: 'AG' },
  { npa: '5604', city: 'Hendschiken', canton: 'AG' },
  { npa: '5113', city: 'Holderbank AG', canton: 'AG' },
  { npa: '5502', city: 'Hunzenschwil', canton: 'AG' },
  { npa: '5600', city: 'Lenzburg', canton: 'AG' },
  { npa: '5616', city: 'Meisterschwanden', canton: 'AG' },
  { npa: '5617', city: 'Tennwil', canton: 'AG' },
  { npa: '5103', city: 'Wildegg', canton: 'AG' },
  { npa: '5103', city: 'Möriken AG', canton: 'AG' },
  { npa: '5702', city: 'Niederlenz', canton: 'AG' },
  { npa: '5504', city: 'Othmarsingen', canton: 'AG' },
  { npa: '5102', city: 'Rupperswil', canton: 'AG' },
  { npa: '5503', city: 'Schafisheim', canton: 'AG' },
  { npa: '5707', city: 'Seengen', canton: 'AG' },
  { npa: '5703', city: 'Seon', canton: 'AG' },
  { npa: '5603', city: 'Staufen', canton: 'AG' },
  { npa: '5646', city: 'Abtwil AG', canton: 'AG' },
  { npa: '5628', city: 'Aristau', canton: 'AG' },
  { npa: '5644', city: 'Auw', canton: 'AG' },
  { npa: '5637', city: 'Beinwil (Freiamt)', canton: 'AG' },
  { npa: '5627', city: 'Besenbüren', canton: 'AG' },
  { npa: '5618', city: 'Bettwil', canton: 'AG' },
  { npa: '5623', city: 'Boswil', canton: 'AG' },
  { npa: '5624', city: 'Bünzen', canton: 'AG' },
  { npa: '5624', city: 'Waldhäusern AG', canton: 'AG' },
  { npa: '5632', city: 'Buttwil', canton: 'AG' },
  { npa: '6042', city: 'Dietwil', canton: 'AG' },
  { npa: '5637', city: 'Geltwil', canton: 'AG' },
  { npa: '5625', city: 'Kallern', canton: 'AG' },
  { npa: '5634', city: 'Merenschwand', canton: 'AG' },
  { npa: '5636', city: 'Benzenschwil', canton: 'AG' },
  { npa: '5642', city: 'Mühlau', canton: 'AG' },
  { npa: '5630', city: 'Muri AG', canton: 'AG' },
  { npa: '5647', city: 'Oberrüti', canton: 'AG' },
  { npa: '8919', city: 'Rottenschwil', canton: 'AG' },
  { npa: '5643', city: 'Sins', canton: 'AG' },
  { npa: '5643', city: 'Sins', canton: 'AG' },
  { npa: '5643', city: 'Alikon', canton: 'AG' },
  { npa: '5643', city: 'Meienberg', canton: 'AG' },
  { npa: '5645', city: 'Aettenschwil', canton: 'AG' },
  { npa: '5645', city: 'Fenkrieden', canton: 'AG' },
  { npa: '5622', city: 'Waltenschwil', canton: 'AG' },
  { npa: '4316', city: 'Hellikon', canton: 'AG' },
  { npa: '4303', city: 'Kaiseraugst', canton: 'AG' },
  { npa: '4312', city: 'Magden', canton: 'AG' },
  { npa: '4313', city: 'Möhlin', canton: 'AG' },
  { npa: '4322', city: 'Mumpf', canton: 'AG' },
  { npa: '4324', city: 'Obermumpf', canton: 'AG' },
  { npa: '4305', city: 'Olsberg', canton: 'AG' },
  { npa: '4310', city: 'Rheinfelden', canton: 'AG' },
  { npa: '4325', city: 'Schupfart', canton: 'AG' },
  { npa: '4332', city: 'Stein AG', canton: 'AG' },
  { npa: '4323', city: 'Wallbach', canton: 'AG' },
  { npa: '4317', city: 'Wegenstetten', canton: 'AG' },
  { npa: '4314', city: 'Zeiningen', canton: 'AG' },
  { npa: '4315', city: 'Zuzgen', canton: 'AG' },
  { npa: '4663', city: 'Aarburg', canton: 'AG' },
  { npa: '4814', city: 'Bottenwil', canton: 'AG' },
  { npa: '4805', city: 'Brittnau', canton: 'AG' },
  { npa: '5054', city: 'Kirchleerau', canton: 'AG' },
  { npa: '5742', city: 'Kölliken', canton: 'AG' },
  { npa: '5054', city: 'Moosleerau', canton: 'AG' },
  { npa: '4853', city: 'Murgenthal', canton: 'AG' },
  { npa: '4853', city: 'Riken AG', canton: 'AG' },
  { npa: '4856', city: 'Glashütten', canton: 'AG' },
  { npa: '4665', city: 'Oftringen', canton: 'AG' },
  { npa: '5056', city: 'Attelwil', canton: 'AG' },
  { npa: '5057', city: 'Reitnau', canton: 'AG' },
  { npa: '4852', city: 'Rothrist', canton: 'AG' },
  { npa: '5745', city: 'Safenwil', canton: 'AG' },
  { npa: '5053', city: 'Staffelbach', canton: 'AG' },
  { npa: '5053', city: 'Wittwil', canton: 'AG' },
  { npa: '4802', city: 'Strengelbach', canton: 'AG' },
  { npa: '4813', city: 'Uerkheim', canton: 'AG' },
  { npa: '4803', city: 'Vordemwald', canton: 'AG' },
  { npa: '5058', city: 'Wiliberg', canton: 'AG' },
  { npa: '4800', city: 'Zofingen', canton: 'AG' },
  { npa: '4812', city: 'Mühlethal', canton: 'AG' },
  { npa: '5314', city: 'Kleindöttingen', canton: 'AG' },
  { npa: '5315', city: 'Böttstein', canton: 'AG' },
  { npa: '5312', city: 'Döttingen', canton: 'AG' },
  { npa: '5304', city: 'Endingen', canton: 'AG' },
  { npa: '5305', city: 'Unterendingen', canton: 'AG' },
  { npa: '5467', city: 'Fisibach', canton: 'AG' },
  { npa: '5324', city: 'Full-Reuenthal', canton: 'AG' },
  { npa: '5313', city: 'Klingnau', canton: 'AG' },
  { npa: '5322', city: 'Koblenz', canton: 'AG' },
  { npa: '5325', city: 'Leibstadt', canton: 'AG' },
  { npa: '5426', city: 'Lengnau AG', canton: 'AG' },
  { npa: '5316', city: 'Leuggern', canton: 'AG' },
  { npa: '5317', city: 'Hettenschwil', canton: 'AG' },
  { npa: '5465', city: 'Mellikon', canton: 'AG' },
  { npa: '5425', city: 'Schneisingen', canton: 'AG' },
  { npa: '5462', city: 'Siglistorf', canton: 'AG' },
  { npa: '5306', city: 'Tegerfelden', canton: 'AG' },
  { npa: '5323', city: 'Rietheim', canton: 'AG' },
  { npa: '5330', city: 'Bad Zurzach', canton: 'AG' },
  { npa: '5332', city: 'Rekingen AG', canton: 'AG' },
  { npa: '5333', city: 'Baldingen', canton: 'AG' },
  { npa: '5334', city: 'Böbikon', canton: 'AG' },
  { npa: '5463', city: 'Wislikofen', canton: 'AG' },
  { npa: '5464', city: 'Rümikon AG', canton: 'AG' },
  { npa: '5466', city: 'Kaiserstuhl AG', canton: 'AG' },
  { npa: '9320', city: 'Arbon', canton: 'TG' },
  { npa: '9320', city: 'Frasnacht', canton: 'TG' },
  { npa: '9320', city: 'Stachen', canton: 'TG' },
  { npa: '8582', city: 'Dozwil', canton: 'TG' },
  { npa: '9314', city: 'Steinebrunn', canton: 'TG' },
  { npa: '9315', city: 'Neukirch (Egnach)', canton: 'TG' },
  { npa: '9315', city: 'Winden', canton: 'TG' },
  { npa: '9322', city: 'Egnach', canton: 'TG' },
  { npa: '8580', city: 'Hefenhofen', canton: 'TG' },
  { npa: '9326', city: 'Horn', canton: 'TG' },
  { npa: '8593', city: 'Kesswil', canton: 'TG' },
  { npa: '9306', city: 'Freidorf TG', canton: 'TG' },
  { npa: '9325', city: 'Roggwil TG', canton: 'TG' },
  { npa: '8590', city: 'Romanshorn', canton: 'TG' },
  { npa: '8599', city: 'Salmsach', canton: 'TG' },
  { npa: '8580', city: 'Sommeri', canton: 'TG' },
  { npa: '8592', city: 'Uttwil', canton: 'TG' },
  { npa: '8580', city: 'Amriswil', canton: 'TG' },
  { npa: '8580', city: 'Hagenwil b. Amriswil', canton: 'TG' },
  { npa: '8580', city: 'Biessenhofen', canton: 'TG' },
  { npa: '8581', city: 'Schocherswil', canton: 'TG' },
  { npa: '8587', city: 'Oberaach', canton: 'TG' },
  { npa: '9220', city: 'Bischofszell', canton: 'TG' },
  { npa: '9223', city: 'Schweizersholz', canton: 'TG' },
  { npa: '9223', city: 'Halden', canton: 'TG' },
  { npa: '8586', city: 'Erlen', canton: 'TG' },
  { npa: '8586', city: 'Kümmertshausen', canton: 'TG' },
  { npa: '8586', city: 'Riedt b. Erlen', canton: 'TG' },
  { npa: '8586', city: 'Buchackern', canton: 'TG' },
  { npa: '8586', city: 'Engishofen', canton: 'TG' },
  { npa: '8586', city: 'Ennetaach', canton: 'TG' },
  { npa: '9213', city: 'Hauptwil', canton: 'TG' },
  { npa: '9225', city: 'Wilen (Gottshaus)', canton: 'TG' },
  { npa: '9225', city: 'St. Pelagiberg', canton: 'TG' },
  { npa: '9216', city: 'Heldswil', canton: 'TG' },
  { npa: '9216', city: 'Hohentannen', canton: 'TG' },
  { npa: '9214', city: 'Kradolf', canton: 'TG' },
  { npa: '9215', city: 'Schönenberg an der Thur', canton: 'TG' },
  { npa: '9215', city: 'Buhwil', canton: 'TG' },
  { npa: '9217', city: 'Neukirch an der Thur', canton: 'TG' },
  { npa: '8583', city: 'Sulgen', canton: 'TG' },
  { npa: '8583', city: 'Götighofen', canton: 'TG' },
  { npa: '8583', city: 'Donzhausen', canton: 'TG' },
  { npa: '8588', city: 'Zihlschlacht', canton: 'TG' },
  { npa: '8589', city: 'Sitterdorf', canton: 'TG' },
  { npa: '8254', city: 'Basadingen', canton: 'TG' },
  { npa: '8255', city: 'Schlattingen', canton: 'TG' },
  { npa: '8253', city: 'Diessenhofen', canton: 'TG' },
  { npa: '8253', city: 'Willisdorf', canton: 'TG' },
  { npa: '8252', city: 'Schlatt TG', canton: 'TG' },
  { npa: '8355', city: 'Aadorf', canton: 'TG' },
  { npa: '8356', city: 'Ettenhausen TG', canton: 'TG' },
  { npa: '8357', city: 'Guntershausen b. Aadorf', canton: 'TG' },
  { npa: '8522', city: 'Häuslenen', canton: 'TG' },
  { npa: '8522', city: 'Aawangen', canton: 'TG' },
  { npa: '9547', city: 'Wittenwil', canton: 'TG' },
  { npa: '8552', city: 'Felben-Wellhausen', canton: 'TG' },
  { npa: '8500', city: 'Frauenfeld', canton: 'TG' },
  { npa: '8500', city: 'Gerlikon', canton: 'TG' },
  { npa: '8546', city: 'Islikon', canton: 'TG' },
  { npa: '8546', city: 'Kefikon TG', canton: 'TG' },
  { npa: '8547', city: 'Gachnang', canton: 'TG' },
  { npa: '8553', city: 'Harenwilen', canton: 'TG' },
  { npa: '8553', city: 'Mettendorf TG', canton: 'TG' },
  { npa: '8553', city: 'Hüttlingen', canton: 'TG' },
  { npa: '8553', city: 'Eschikofen', canton: 'TG' },
  { npa: '9548', city: 'Matzingen', canton: 'TG' },
  { npa: '8525', city: 'Niederneunforn', canton: 'TG' },
  { npa: '8526', city: 'Oberneunforn', canton: 'TG' },
  { npa: '9507', city: 'Stettfurt', canton: 'TG' },
  { npa: '8512', city: 'Thundorf', canton: 'TG' },
  { npa: '8512', city: 'Lustdorf', canton: 'TG' },
  { npa: '8512', city: 'Wetzikon TG', canton: 'TG' },
  { npa: '8524', city: 'Uesslingen', canton: 'TG' },
  { npa: '8524', city: 'Buch b. Frauenfeld', canton: 'TG' },
  { npa: '8532', city: 'Warth', canton: 'TG' },
  { npa: '8532', city: 'Weiningen TG', canton: 'TG' },
  { npa: '8595', city: 'Altnau', canton: 'TG' },
  { npa: '8598', city: 'Bottighofen', canton: 'TG' },
  { npa: '8272', city: 'Ermatingen', canton: 'TG' },
  { npa: '8273', city: 'Triboltingen', canton: 'TG' },
  { npa: '8274', city: 'Gottlieben', canton: 'TG' },
  { npa: '8594', city: 'Güttingen', canton: 'TG' },
  { npa: '8565', city: 'Hugelshofen', canton: 'TG' },
  { npa: '8566', city: 'Neuwilen', canton: 'TG' },
  { npa: '8566', city: 'Dotnacht', canton: 'TG' },
  { npa: '8566', city: 'Ellighausen', canton: 'TG' },
  { npa: '8566', city: 'Lippoldswilen', canton: 'TG' },
  { npa: '8573', city: 'Siegershausen', canton: 'TG' },
  { npa: '8573', city: 'Alterswilen', canton: 'TG' },
  { npa: '8573', city: 'Altishausen', canton: 'TG' },
  { npa: '8280', city: 'Kreuzlingen', canton: 'TG' },
  { npa: '8585', city: 'Langrickenbach', canton: 'TG' },
  { npa: '8585', city: 'Zuben', canton: 'TG' },
  { npa: '8585', city: 'Schönenbaumgarten', canton: 'TG' },
  { npa: '8585', city: 'Herrenhof', canton: 'TG' },
  { npa: '8574', city: 'Illighausen', canton: 'TG' },
  { npa: '8574', city: 'Oberhofen TG', canton: 'TG' },
  { npa: '8574', city: 'Lengwil', canton: 'TG' },
  { npa: '8574', city: 'Lengwil', canton: 'TG' },
  { npa: '8574', city: 'Dettighofen (Lengwil)', canton: 'TG' },
  { npa: '8596', city: 'Scherzingen', canton: 'TG' },
  { npa: '8596', city: 'Münsterlingen', canton: 'TG' },
  { npa: '8597', city: 'Landschlacht', canton: 'TG' },
  { npa: '8274', city: 'Tägerwilen', canton: 'TG' },
  { npa: '8564', city: 'Hefenhausen', canton: 'TG' },
  { npa: '8564', city: 'Engwilen', canton: 'TG' },
  { npa: '8564', city: 'Sonterswil', canton: 'TG' },
  { npa: '8564', city: 'Wäldi', canton: 'TG' },
  { npa: '8564', city: 'Lipperswil', canton: 'TG' },
  { npa: '8564', city: 'Hattenhausen', canton: 'TG' },
  { npa: '8564', city: 'Gunterswilen', canton: 'TG' },
  { npa: '9556', city: 'Affeltrangen', canton: 'TG' },
  { npa: '9556', city: 'Zezikon', canton: 'TG' },
  { npa: '9562', city: 'Märwil', canton: 'TG' },
  { npa: '9562', city: 'Buch b. Märwil', canton: 'TG' },
  { npa: '9553', city: 'Bettwiesen', canton: 'TG' },
  { npa: '8362', city: 'Balterswil', canton: 'TG' },
  { npa: '8363', city: 'Bichelsee', canton: 'TG' },
  { npa: '9502', city: 'Braunau', canton: 'TG' },
  { npa: '8360', city: 'Eschlikon TG', canton: 'TG' },
  { npa: '8360', city: 'Wallenwil', canton: 'TG' },
  { npa: '8374', city: 'Dussnang', canton: 'TG' },
  { npa: '8374', city: 'Oberwangen TG', canton: 'TG' },
  { npa: '8376', city: 'Fischingen', canton: 'TG' },
  { npa: '8376', city: 'Au TG', canton: 'TG' },
  { npa: '9506', city: 'Lommis', canton: 'TG' },
  { npa: '9508', city: 'Weingarten-Kalthäusern', canton: 'TG' },
  { npa: '9542', city: 'Münchwilen TG', canton: 'TG' },
  { npa: '9543', city: 'St. Margarethen TG', canton: 'TG' },
  { npa: '9532', city: 'Rickenbach b. Wil', canton: 'TG' },
  { npa: '8577', city: 'Schönholzerswilen', canton: 'TG' },
  { npa: '8370', city: 'Sirnach', canton: 'TG' },
  { npa: '8371', city: 'Busswil TG', canton: 'TG' },
  { npa: '8372', city: 'Wiezikon b. Sirnach', canton: 'TG' },
  { npa: '9573', city: 'Littenheid', canton: 'TG' },
  { npa: '9554', city: 'Tägerschen', canton: 'TG' },
  { npa: '9555', city: 'Tobel', canton: 'TG' },
  { npa: '9545', city: 'Wängi', canton: 'TG' },
  { npa: '9546', city: 'Tuttwil', canton: 'TG' },
  { npa: '9535', city: 'Wilen b. Wil', canton: 'TG' },
  { npa: '9514', city: 'Wuppenau', canton: 'TG' },
  { npa: '9515', city: 'Hosenruck', canton: 'TG' },
  { npa: '8267', city: 'Berlingen', canton: 'TG' },
  { npa: '8264', city: 'Eschenz', canton: 'TG' },
  { npa: '8506', city: 'Lanzenneunforn', canton: 'TG' },
  { npa: '8535', city: 'Herdern', canton: 'TG' },
  { npa: '8507', city: 'Hörhausen', canton: 'TG' },
  { npa: '8508', city: 'Homburg', canton: 'TG' },
  { npa: '8536', city: 'Hüttwilen', canton: 'TG' },
  { npa: '8537', city: 'Nussbaumen TG', canton: 'TG' },
  { npa: '8537', city: 'Uerschhausen', canton: 'TG' },
  { npa: '8265', city: 'Mammern', canton: 'TG' },
  { npa: '8555', city: 'Müllheim Dorf', canton: 'TG' },
  { npa: '8505', city: 'Pfyn', canton: 'TG' },
  { npa: '8505', city: 'Dettighofen', canton: 'TG' },
  { npa: '8558', city: 'Raperswilen', canton: 'TG' },
  { npa: '8268', city: 'Mannenbach-Salenstein', canton: 'TG' },
  { npa: '8268', city: 'Salenstein', canton: 'TG' },
  { npa: '8269', city: 'Fruthwilen', canton: 'TG' },
  { npa: '8266', city: 'Steckborn', canton: 'TG' },
  { npa: '8259', city: 'Kaltenbach', canton: 'TG' },
  { npa: '8259', city: 'Etzwilen', canton: 'TG' },
  { npa: '8259', city: 'Rheinklingen', canton: 'TG' },
  { npa: '8259', city: 'Wagenhausen', canton: 'TG' },
  { npa: '8514', city: 'Amlikon-Bissegg', canton: 'TG' },
  { npa: '8572', city: 'Berg TG', canton: 'TG' },
  { npa: '8572', city: 'Berg TG', canton: 'TG' },
  { npa: '8572', city: 'Andhausen', canton: 'TG' },
  { npa: '8572', city: 'Graltshausen', canton: 'TG' },
  { npa: '8572', city: 'Guntershausen b. Berg', canton: 'TG' },
  { npa: '8576', city: 'Mauren TG', canton: 'TG' },
  { npa: '8585', city: 'Mattwil', canton: 'TG' },
  { npa: '8585', city: 'Happerswil', canton: 'TG' },
  { npa: '8585', city: 'Birwinken', canton: 'TG' },
  { npa: '8585', city: 'Klarsreuti', canton: 'TG' },
  { npa: '8586', city: 'Andwil TG', canton: 'TG' },
  { npa: '8586', city: 'Buch b. Kümmertshausen', canton: 'TG' },
  { npa: '8575', city: 'Bürglen TG', canton: 'TG' },
  { npa: '8575', city: 'Istighofen', canton: 'TG' },
  { npa: '8584', city: 'Leimbach TG', canton: 'TG' },
  { npa: '8584', city: 'Opfershofen TG', canton: 'TG' },
  { npa: '9503', city: 'Stehrenberg', canton: 'TG' },
  { npa: '9503', city: 'Lanterswil', canton: 'TG' },
  { npa: '9504', city: 'Friltschen', canton: 'TG' },
  { npa: '9517', city: 'Mettlen', canton: 'TG' },
  { npa: '9565', city: 'Bussnang', canton: 'TG' },
  { npa: '9565', city: 'Oberbussnang', canton: 'TG' },
  { npa: '9565', city: 'Oppikon', canton: 'TG' },
  { npa: '9565', city: 'Schmidshof', canton: 'TG' },
  { npa: '9565', city: 'Rothenhausen', canton: 'TG' },
  { npa: '8560', city: 'Märstetten', canton: 'TG' },
  { npa: '8561', city: 'Ottoberg', canton: 'TG' },
  { npa: '8570', city: 'Weinfelden', canton: 'TG' },
  { npa: '8554', city: 'Müllheim-Wigoltingen', canton: 'TG' },
  { npa: '8554', city: 'Bonau', canton: 'TG' },
  { npa: '8556', city: 'Wigoltingen', canton: 'TG' },
  { npa: '8556', city: 'Engwang', canton: 'TG' },
  { npa: '8556', city: 'Illhart', canton: 'TG' },
  { npa: '8556', city: 'Lamperswil TG', canton: 'TG' },
  { npa: '8564', city: 'Wagerswil', canton: 'TG' },
  { npa: '6517', city: 'Arbedo', canton: 'TI' },
  { npa: '6532', city: 'Castione', canton: 'TI' },
  { npa: '6500', city: 'Bellinzona', canton: 'TI' },
  { npa: '6503', city: 'Bellinzona', canton: 'TI' },
  { npa: '6512', city: 'Giubiasco', canton: 'TI' },
  { npa: '6513', city: 'Monte Carasso', canton: 'TI' },
  { npa: '6514', city: 'Sementina', canton: 'TI' },
  { npa: '6515', city: 'Gudo', canton: 'TI' },
  { npa: '6518', city: 'Gorduno', canton: 'TI' },
  { npa: '6523', city: 'Preonzo', canton: 'TI' },
  { npa: '6524', city: 'Moleno', canton: 'TI' },
  { npa: '6525', city: 'Gnosca', canton: 'TI' },
  { npa: '6528', city: 'Camorino', canton: 'TI' },
  { npa: '6582', city: 'Pianezzo', canton: 'TI' },
  { npa: '6583', city: 'S. Antonio (Val Morobbia)', canton: 'TI' },
  { npa: '6584', city: 'Carena', canton: 'TI' },
  { npa: '6702', city: 'Claro', canton: 'TI' },
  { npa: '6593', city: 'Cadenazzo', canton: 'TI' },
  { npa: '6599', city: 'Robasacco', canton: 'TI' },
  { npa: '6810', city: 'Isone', canton: 'TI' },
  { npa: '6533', city: 'Lumino', canton: 'TI' },
  { npa: '6592', city: 'S. Antonino', canton: 'TI' },
  { npa: '6715', city: 'Dongio', canton: 'TI' },
  { npa: '6716', city: 'Acquarossa', canton: 'TI' },
  { npa: '6716', city: 'Leontica', canton: 'TI' },
  { npa: '6716', city: 'Lottigna', canton: 'TI' },
  { npa: '6721', city: 'Motto (Blenio)', canton: 'TI' },
  { npa: '6722', city: 'Corzoneso', canton: 'TI' },
  { npa: '6723', city: 'Prugiasco', canton: 'TI' },
  { npa: '6723', city: 'Castro', canton: 'TI' },
  { npa: '6723', city: 'Marolta', canton: 'TI' },
  { npa: '6724', city: 'Ponto Valentino', canton: 'TI' },
  { npa: '6724', city: 'Largario', canton: 'TI' },
  { npa: '6717', city: 'Dangio', canton: 'TI' },
  { npa: '6717', city: 'Torre', canton: 'TI' },
  { npa: '6718', city: 'Olivone', canton: 'TI' },
  { npa: '6718', city: 'Camperio', canton: 'TI' },
  { npa: '6719', city: 'Aquila', canton: 'TI' },
  { npa: '6719', city: 'Aquila', canton: 'TI' },
  { npa: '6720', city: 'Campo (Blenio)', canton: 'TI' },
  { npa: '6720', city: 'Ghirone', canton: 'TI' },
  { npa: '6713', city: 'Malvaglia', canton: 'TI' },
  { npa: '6714', city: 'Semione', canton: 'TI' },
  { npa: '6721', city: 'Ludiano', canton: 'TI' },
  { npa: '6780', city: 'Airolo', canton: 'TI' },
  { npa: '6780', city: 'Madrano', canton: 'TI' },
  { npa: '6781', city: 'Villa Bedretto', canton: 'TI' },
  { npa: '6781', city: 'Bedretto', canton: 'TI' },
  { npa: '6743', city: 'Bodio TI', canton: 'TI' },
  { npa: '6774', city: 'Dalpe', canton: 'TI' },
  { npa: '6746', city: 'Lavorgo', canton: 'TI' },
  { npa: '6746', city: 'Calonico', canton: 'TI' },
  { npa: '6746', city: 'Nivo', canton: 'TI' },
  { npa: '6747', city: 'Chironico', canton: 'TI' },
  { npa: '6748', city: 'Anzonico', canton: 'TI' },
  { npa: '6749', city: 'Sobrio', canton: 'TI' },
  { npa: '6749', city: 'Cavagnago', canton: 'TI' },
  { npa: '6760', city: 'Faido', canton: 'TI' },
  { npa: '6760', city: 'Molare', canton: 'TI' },
  { npa: '6760', city: 'Calpiogna', canton: 'TI' },
  { npa: '6760', city: 'Campello', canton: 'TI' },
  { npa: '6760', city: 'Carì', canton: 'TI' },
  { npa: '6760', city: 'Rossura', canton: 'TI' },
  { npa: '6763', city: 'Osco', canton: 'TI' },
  { npa: '6763', city: 'Mairengo', canton: 'TI' },
  { npa: '6764', city: 'Chiggiogna', canton: 'TI' },
  { npa: '6745', city: 'Giornico', canton: 'TI' },
  { npa: '6744', city: 'Personico', canton: 'TI' },
  { npa: '6742', city: 'Pollegio', canton: 'TI' },
  { npa: '6772', city: 'Rodi-Fiesso', canton: 'TI' },
  { npa: '6773', city: 'Prato (Leventina)', canton: 'TI' },
  { npa: '6775', city: 'Ambrì', canton: 'TI' },
  { npa: '6776', city: 'Piotta', canton: 'TI' },
  { npa: '6777', city: 'Quinto', canton: 'TI' },
  { npa: '6777', city: 'Varenzo', canton: 'TI' },
  { npa: '6612', city: 'Ascona', canton: 'TI' },
  { npa: '6645', city: 'Brione sopra Minusio', canton: 'TI' },
  { npa: '6614', city: 'Brissago', canton: 'TI' },
  { npa: '6614', city: 'Isole di Brissago', canton: 'TI' },
  { npa: '6596', city: 'Gordola', canton: 'TI' },
  { npa: '6595', city: 'Riazzino', canton: 'TI' },
  { npa: '6600', city: 'Locarno', canton: 'TI' },
  { npa: '6600', city: 'Locarno', canton: 'TI' },
  { npa: '6600', city: 'Solduno', canton: 'TI' },
  { npa: '6605', city: 'Locarno', canton: 'TI' },
  { npa: '6616', city: 'Losone', canton: 'TI' },
  { npa: '6618', city: 'Arcegno', canton: 'TI' },
  { npa: '6647', city: 'Mergoscia', canton: 'TI' },
  { npa: '6648', city: 'Minusio', canton: 'TI' },
  { npa: '6600', city: 'Muralto', canton: 'TI' },
  { npa: '6644', city: 'Orselina', canton: 'TI' },
  { npa: '6613', city: 'Porto Ronco', canton: 'TI' },
  { npa: '6622', city: 'Ronco sopra Ascona', canton: 'TI' },
  { npa: '6598', city: 'Tenero', canton: 'TI' },
  { npa: '6646', city: 'Contra', canton: 'TI' },
  { npa: '6611', city: 'Mosogno', canton: 'TI' },
  { npa: '6611', city: 'Gresso', canton: 'TI' },
  { npa: '6611', city: 'Crana', canton: 'TI' },
  { npa: '6661', city: 'Loco', canton: 'TI' },
  { npa: '6661', city: 'Auressio', canton: 'TI' },
  { npa: '6661', city: 'Berzona', canton: 'TI' },
  { npa: '6662', city: 'Russo', canton: 'TI' },
  { npa: '6663', city: 'Comologno', canton: 'TI' },
  { npa: '6663', city: 'Spruga', canton: 'TI' },
  { npa: '6664', city: 'Vergeletto', canton: 'TI' },
  { npa: '6516', city: 'Cugnasco', canton: 'TI' },
  { npa: '6597', city: 'Agarone', canton: 'TI' },
  { npa: '6982', city: 'Agno', canton: 'TI' },
  { npa: '6990', city: "Cassina d'Agno", canton: 'TI' },
  { npa: '6994', city: 'Aranno', canton: 'TI' },
  { npa: '6822', city: 'Arogno', canton: 'TI' },
  { npa: '6823', city: 'Pugerna', canton: 'TI' },
  { npa: '6999', city: 'Astano', canton: 'TI' },
  { npa: '6930', city: 'Bedano', canton: 'TI' },
  { npa: '6981', city: 'Bedigliora', canton: 'TI' },
  { npa: '6981', city: 'Bedigliora', canton: 'TI' },
  { npa: '6981', city: 'Banco', canton: 'TI' },
  { npa: '6981', city: 'Beride di Bedigliora', canton: 'TI' },
  { npa: '6934', city: 'Bioggio', canton: 'TI' },
  { npa: '6935', city: 'Bosco Luganese', canton: 'TI' },
  { npa: '6992', city: 'Cimo', canton: 'TI' },
  { npa: '6993', city: 'Iseo', canton: 'TI' },
  { npa: '6816', city: 'Bissone', canton: 'TI' },
  { npa: '6827', city: 'Brusino Arsizio', canton: 'TI' },
  { npa: '6867', city: 'Serpiano', canton: 'TI' },
  { npa: '6936', city: 'Cademario', canton: 'TI' },
  { npa: '6814', city: 'Cadempino', canton: 'TI' },
  { npa: '6952', city: 'Canobbio', canton: 'TI' },
  { npa: '6987', city: 'Caslano', canton: 'TI' },
  { npa: '6949', city: 'Comano', canton: 'TI' },
  { npa: '6944', city: 'Cureglia', canton: 'TI' },
  { npa: '6981', city: 'Bombinasco', canton: 'TI' },
  { npa: '6986', city: 'Curio', canton: 'TI' },
  { npa: '6916', city: 'Grancia', canton: 'TI' },
  { npa: '6929', city: 'Gravesano', canton: 'TI' },
  { npa: '6814', city: 'Lamone', canton: 'TI' },
  { npa: '6900', city: 'Lugano', canton: 'TI' },
  { npa: '6912', city: 'Pazzallo', canton: 'TI' },
  { npa: '6913', city: 'Carabbia', canton: 'TI' },
  { npa: '6914', city: 'Carona', canton: 'TI' },
  { npa: '6915', city: 'Pambio-Noranco', canton: 'TI' },
  { npa: '6917', city: 'Barbengo', canton: 'TI' },
  { npa: '6918', city: 'Figino', canton: 'TI' },
  { npa: '6932', city: 'Breganzona', canton: 'TI' },
  { npa: '6951', city: 'Insone', canton: 'TI' },
  { npa: '6951', city: 'Scareglia', canton: 'TI' },
  { npa: '6951', city: 'Colla', canton: 'TI' },
  { npa: '6951', city: 'Bogno', canton: 'TI' },
  { npa: '6951', city: 'Cozzo', canton: 'TI' },
  { npa: '6951', city: 'Signôra', canton: 'TI' },
  { npa: '6959', city: 'Maglio di Colla', canton: 'TI' },
  { npa: '6959', city: 'Certara', canton: 'TI' },
  { npa: '6959', city: 'Curtina', canton: 'TI' },
  { npa: '6959', city: 'Cimadera', canton: 'TI' },
  { npa: '6959', city: 'Piandera Paese', canton: 'TI' },
  { npa: '6959', city: 'Piandera Paese', canton: 'TI' },
  { npa: '6962', city: 'Viganello', canton: 'TI' },
  { npa: '6963', city: 'Pregassona', canton: 'TI' },
  { npa: '6963', city: 'Cureggia', canton: 'TI' },
  { npa: '6964', city: 'Davesco-Soragno', canton: 'TI' },
  { npa: '6965', city: 'Cadro', canton: 'TI' },
  { npa: '6966', city: 'Villa Luganese', canton: 'TI' },
  { npa: '6967', city: 'Dino', canton: 'TI' },
  { npa: '6968', city: 'Sonvico', canton: 'TI' },
  { npa: '6974', city: 'Aldesago', canton: 'TI' },
  { npa: '6976', city: 'Castagnola', canton: 'TI' },
  { npa: '6977', city: 'Ruvigliana', canton: 'TI' },
  { npa: '6978', city: 'Gandria', canton: 'TI' },
  { npa: '6979', city: 'Brè sopra Lugano', canton: 'TI' },
  { npa: '6983', city: 'Magliaso', canton: 'TI' },
  { npa: '6928', city: 'Manno', canton: 'TI' },
  { npa: '6817', city: 'Maroggia', canton: 'TI' },
  { npa: '6900', city: 'Massagno', canton: 'TI' },
  { npa: '6818', city: 'Melano', canton: 'TI' },
  { npa: '6815', city: 'Melide', canton: 'TI' },
  { npa: '6805', city: 'Mezzovico', canton: 'TI' },
  { npa: '6805', city: 'Mezzovico', canton: 'TI' },
  { npa: '6986', city: 'Miglieglia', canton: 'TI' },
  { npa: '6922', city: 'Morcote', canton: 'TI' },
  { npa: '6933', city: 'Muzzano', canton: 'TI' },
  { npa: '6991', city: 'Neggio', canton: 'TI' },
  { npa: '6986', city: 'Novaggio', canton: 'TI' },
  { npa: '6945', city: 'Origlio', canton: 'TI' },
  { npa: '6900', city: 'Paradiso', canton: 'TI' },
  { npa: '6946', city: 'Ponte Capriasca', canton: 'TI' },
  { npa: '6946', city: 'Ponte Capriasca', canton: 'TI' },
  { npa: '6948', city: 'Porza', canton: 'TI' },
  { npa: '6984', city: 'Pura', canton: 'TI' },
  { npa: '6821', city: 'Rovio', canton: 'TI' },
  { npa: '6825', city: 'Capolago', canton: 'TI' },
  { npa: '6942', city: 'Savosa', canton: 'TI' },
  { npa: '6924', city: 'Sorengo', canton: 'TI' },
  { npa: '6807', city: 'Taverne', canton: 'TI' },
  { npa: '6947', city: 'Vaglio', canton: 'TI' },
  { npa: '6950', city: 'Tesserete', canton: 'TI' },
  { npa: '6953', city: 'Lugaggia', canton: 'TI' },
  { npa: '6954', city: 'Sala Capriasca', canton: 'TI' },
  { npa: '6954', city: 'Bigorio', canton: 'TI' },
  { npa: '6955', city: 'Cagiallo', canton: 'TI' },
  { npa: '6955', city: 'Oggio', canton: 'TI' },
  { npa: '6956', city: 'Lopagno', canton: 'TI' },
  { npa: '6957', city: 'Roveredo TI', canton: 'TI' },
  { npa: '6958', city: 'Bidogno', canton: 'TI' },
  { npa: '6958', city: 'Corticiasca', canton: 'TI' },
  { npa: '6958', city: 'Corticiasca', canton: 'TI' },
  { npa: '6960', city: 'Odogno', canton: 'TI' },
  { npa: '6807', city: 'Taverne', canton: 'TI' },
  { npa: '6808', city: 'Torricella', canton: 'TI' },
  { npa: '6992', city: 'Vernate', canton: 'TI' },
  { npa: '6943', city: 'Vezia', canton: 'TI' },
  { npa: '6921', city: 'Vico Morcote', canton: 'TI' },
  { npa: '6919', city: 'Carabietta', canton: 'TI' },
  { npa: '6925', city: 'Gentilino', canton: 'TI' },
  { npa: '6926', city: 'Montagnola', canton: 'TI' },
  { npa: '6927', city: 'Agra', canton: 'TI' },
  { npa: '6937', city: 'Breno', canton: 'TI' },
  { npa: '6938', city: 'Vezio', canton: 'TI' },
  { npa: '6938', city: 'Fescoggia', canton: 'TI' },
  { npa: '6939', city: 'Mugena', canton: 'TI' },
  { npa: '6939', city: 'Arosio', canton: 'TI' },
  { npa: '6802', city: 'Rivera', canton: 'TI' },
  { npa: '6803', city: 'Camignolo', canton: 'TI' },
  { npa: '6804', city: 'Bironico', canton: 'TI' },
  { npa: '6806', city: 'Sigirino', canton: 'TI' },
  { npa: '6809', city: 'Medeglia', canton: 'TI' },
  { npa: '6980', city: 'Castelrotto', canton: 'TI' },
  { npa: '6988', city: 'Ponte Tresa', canton: 'TI' },
  { npa: '6989', city: 'Purasca', canton: 'TI' },
  { npa: '6995', city: 'Madonna del Piano', canton: 'TI' },
  { npa: '6997', city: 'Sessa', canton: 'TI' },
  { npa: '6998', city: 'Monteggio', canton: 'TI' },
  { npa: '6828', city: 'Balerna', canton: 'TI' },
  { npa: '6873', city: 'Corteglia', canton: 'TI' },
  { npa: '6874', city: 'Castel San Pietro', canton: 'TI' },
  { npa: '6875', city: 'Monte', canton: 'TI' },
  { npa: '6875', city: 'Casima', canton: 'TI' },
  { npa: '6875', city: 'Campora', canton: 'TI' },
  { npa: '6830', city: 'Chiasso', canton: 'TI' },
  { npa: '6832', city: 'Pedrinate', canton: 'TI' },
  { npa: '6832', city: 'Seseglio', canton: 'TI' },
  { npa: '6877', city: 'Coldrerio', canton: 'TI' },
  { npa: '6825', city: 'Capolago', canton: 'TI' },
  { npa: '6850', city: 'Mendrisio', canton: 'TI' },
  { npa: '6852', city: 'Genestrerio', canton: 'TI' },
  { npa: '6853', city: 'Ligornetto', canton: 'TI' },
  { npa: '6862', city: 'Rancate', canton: 'TI' },
  { npa: '6863', city: 'Besazio', canton: 'TI' },
  { npa: '6864', city: 'Arzo', canton: 'TI' },
  { npa: '6865', city: 'Tremona', canton: 'TI' },
  { npa: '6866', city: 'Meride', canton: 'TI' },
  { npa: '6872', city: 'Salorino', canton: 'TI' },
  { npa: '6872', city: 'Somazzo', canton: 'TI' },
  { npa: '6834', city: 'Morbio Inferiore', canton: 'TI' },
  { npa: '6883', city: 'Novazzano', canton: 'TI' },
  { npa: '6826', city: 'Riva San Vitale', canton: 'TI' },
  { npa: '6854', city: 'S. Pietro', canton: 'TI' },
  { npa: '6855', city: 'Stabio', canton: 'TI' },
  { npa: '6833', city: 'Vacallo', canton: 'TI' },
  { npa: '6835', city: 'Morbio Superiore', canton: 'TI' },
  { npa: '6837', city: 'Caneggio', canton: 'TI' },
  { npa: '6837', city: 'Bruzella', canton: 'TI' },
  { npa: '6838', city: 'Muggio', canton: 'TI' },
  { npa: '6838', city: 'Cabbio', canton: 'TI' },
  { npa: '6839', city: 'Sagno', canton: 'TI' },
  { npa: '6710', city: 'Biasca', canton: 'TI' },
  { npa: '6526', city: 'Prosito', canton: 'TI' },
  { npa: '6527', city: 'Lodrino', canton: 'TI' },
  { npa: '6703', city: 'Osogna', canton: 'TI' },
  { npa: '6705', city: 'Cresciano', canton: 'TI' },
  { npa: '6707', city: 'Iragna', canton: 'TI' },
  { npa: '6685', city: 'Bosco/Gurin', canton: 'TI' },
  { npa: '6683', city: 'Niva (Vallemaggia)', canton: 'TI' },
  { npa: '6684', city: 'Campo (Vallemaggia)', canton: 'TI' },
  { npa: '6684', city: 'Cimalmotto', canton: 'TI' },
  { npa: '6683', city: 'Cerentino', canton: 'TI' },
  { npa: '6675', city: 'Cevio', canton: 'TI' },
  { npa: '6676', city: 'Bignasco', canton: 'TI' },
  { npa: '6690', city: 'Cavergno', canton: 'TI' },
  { npa: '6690', city: 'S. Carlo (Val Bavona)', canton: 'TI' },
  { npa: '6682', city: 'Linescio', canton: 'TI' },
  { npa: '6673', city: 'Maggia', canton: 'TI' },
  { npa: '6674', city: 'Someo', canton: 'TI' },
  { npa: '6674', city: 'Riveo', canton: 'TI' },
  { npa: '6677', city: 'Aurigeno', canton: 'TI' },
  { npa: '6677', city: 'Moghegno', canton: 'TI' },
  { npa: '6678', city: 'Giumaglio', canton: 'TI' },
  { npa: '6678', city: 'Lodano', canton: 'TI' },
  { npa: '6678', city: 'Coglio', canton: 'TI' },
  { npa: '6692', city: 'Menzonio', canton: 'TI' },
  { npa: '6692', city: 'Brontallo', canton: 'TI' },
  { npa: '6693', city: 'Broglio', canton: 'TI' },
  { npa: '6694', city: 'Prato-Sornico', canton: 'TI' },
  { npa: '6695', city: 'Peccia', canton: 'TI' },
  { npa: '6695', city: 'Piano di Peccia', canton: 'TI' },
  { npa: '6696', city: 'Fusio', canton: 'TI' },
  { npa: '6670', city: 'Avegno', canton: 'TI' },
  { npa: '6672', city: 'Gordevio', canton: 'TI' },
  { npa: '6809', city: 'Medeglia', canton: 'TI' },
  { npa: '6652', city: 'Tegna', canton: 'TI' },
  { npa: '6653', city: 'Verscio', canton: 'TI' },
  { npa: '6654', city: 'Cavigliano', canton: 'TI' },
  { npa: '6655', city: 'Intragna', canton: 'TI' },
  { npa: '6655', city: 'Verdasio', canton: 'TI' },
  { npa: '6655', city: 'Rasa', canton: 'TI' },
  { npa: '6656', city: 'Golino', canton: 'TI' },
  { npa: '6657', city: 'Palagnedra', canton: 'TI' },
  { npa: '6658', city: 'Borgnone', canton: 'TI' },
  { npa: '6659', city: 'Camedo', canton: 'TI' },
  { npa: '6659', city: 'Moneto', canton: 'TI' },
  { npa: '6571', city: 'Indemini', canton: 'TI' },
  { npa: '6572', city: 'Quartino', canton: 'TI' },
  { npa: '6573', city: 'Magadino', canton: 'TI' },
  { npa: '6574', city: 'Vira (Gambarogno)', canton: 'TI' },
  { npa: '6575', city: 'S. Nazzaro', canton: 'TI' },
  { npa: '6575', city: 'Vairano', canton: 'TI' },
  { npa: '6576', city: 'Gerra (Gambarogno)', canton: 'TI' },
  { npa: '6577', city: 'Ranzo', canton: 'TI' },
  { npa: '6578', city: 'Caviano', canton: 'TI' },
  { npa: '6579', city: 'Piazzogna', canton: 'TI' },
  { npa: '6594', city: 'Contone', canton: 'TI' },
  { npa: '6631', city: 'Corippo', canton: 'TI' },
  { npa: '6632', city: 'Vogorno', canton: 'TI' },
  { npa: '6633', city: 'Lavertezzo', canton: 'TI' },
  { npa: '6634', city: 'Brione (Verzasca)', canton: 'TI' },
  { npa: '6635', city: 'Gerra (Verzasca)', canton: 'TI' },
  { npa: '6636', city: 'Frasco', canton: 'TI' },
  { npa: '6637', city: 'Sonogno', canton: 'TI' },
  { npa: '1860', city: 'Aigle', canton: 'VD' },
  { npa: '1880', city: 'Bex', canton: 'VD' },
  { npa: '1880', city: 'Frenières-sur-Bex', canton: 'VD' },
  { npa: '1880', city: 'Fenalet-sur-Bex', canton: 'VD' },
  { npa: '1880', city: 'Les Plans-sur-Bex', canton: 'VD' },
  { npa: '1880', city: 'Les Posses-sur-Bex', canton: 'VD' },
  { npa: '1846', city: 'Chessel', canton: 'VD' },
  { npa: '1856', city: 'Corbeyrier', canton: 'VD' },
  { npa: '1882', city: 'Gryon', canton: 'VD' },
  { npa: '1892', city: 'Lavey-Village', canton: 'VD' },
  { npa: '1892', city: 'Lavey-les-Bains', canton: 'VD' },
  { npa: '1892', city: 'Morcles', canton: 'VD' },
  { npa: '1854', city: 'Leysin', canton: 'VD' },
  { npa: '1845', city: 'Noville', canton: 'VD' },
  { npa: '1867', city: 'Ollon VD', canton: 'VD' },
  { npa: '1867', city: 'St-Triphon', canton: 'VD' },
  { npa: '1867', city: 'Panex', canton: 'VD' },
  { npa: '1884', city: 'Villars-sur-Ollon', canton: 'VD' },
  { npa: '1884', city: 'Arveyes', canton: 'VD' },
  { npa: '1884', city: 'Huémoz', canton: 'VD' },
  { npa: '1885', city: 'Chesières', canton: 'VD' },
  { npa: '1862', city: 'Les Mosses', canton: 'VD' },
  { npa: '1862', city: 'La Comballaz', canton: 'VD' },
  { npa: '1863', city: 'Le Sépey', canton: 'VD' },
  { npa: '1866', city: 'La Forclaz VD', canton: 'VD' },
  { npa: '1864', city: "Vers-l'Eglise", canton: 'VD' },
  { npa: '1865', city: 'Les Diablerets', canton: 'VD' },
  { npa: '1847', city: 'Rennaz', canton: 'VD' },
  { npa: '1852', city: 'Roche VD', canton: 'VD' },
  { npa: '1844', city: 'Villeneuve VD', canton: 'VD' },
  { npa: '1853', city: 'Yvorne', canton: 'VD' },
  { npa: '1170', city: 'Aubonne', canton: 'VD' },
  { npa: '1174', city: 'Montherod', canton: 'VD' },
  { npa: '1174', city: 'Pizy', canton: 'VD' },
  { npa: '1144', city: 'Ballens', canton: 'VD' },
  { npa: '1149', city: 'Berolle', canton: 'VD' },
  { npa: '1145', city: 'Bière', canton: 'VD' },
  { npa: '1172', city: 'Bougy-Villars', canton: 'VD' },
  { npa: '1173', city: 'Féchy', canton: 'VD' },
  { npa: '1188', city: 'Gimel', canton: 'VD' },
  { npa: '1261', city: 'Longirod', canton: 'VD' },
  { npa: '1261', city: 'Marchissy', canton: 'VD' },
  { npa: '1146', city: 'Mollens VD', canton: 'VD' },
  { npa: '1188', city: 'St-George', canton: 'VD' },
  { npa: '1176', city: 'St-Livres', canton: 'VD' },
  { npa: '1187', city: 'St-Oyens', canton: 'VD' },
  { npa: '1189', city: 'Saubraz', canton: 'VD' },
  { npa: '1580', city: 'Avenches', canton: 'VD' },
  { npa: '1580', city: 'Oleyres', canton: 'VD' },
  { npa: '1580', city: 'Donatyre', canton: 'VD' },
  { npa: '1588', city: 'Cudrefin', canton: 'VD' },
  { npa: '1595', city: 'Faoug', canton: 'VD' },
  { npa: '1584', city: 'Villars-le-Grand', canton: 'VD' },
  { npa: '1585', city: 'Salavaux', canton: 'VD' },
  { npa: '1585', city: 'Bellerive VD', canton: 'VD' },
  { npa: '1585', city: 'Cotterd', canton: 'VD' },
  { npa: '1586', city: 'Vallamand', canton: 'VD' },
  { npa: '1587', city: 'Montmagny', canton: 'VD' },
  { npa: '1587', city: 'Constantine', canton: 'VD' },
  { npa: '1589', city: 'Chabrey', canton: 'VD' },
  { npa: '1787', city: 'Mur (Vully) VD', canton: 'VD' },
  { npa: '1042', city: 'Bettens', canton: 'VD' },
  { npa: '1035', city: 'Bournens', canton: 'VD' },
  { npa: '1034', city: 'Boussens', canton: 'VD' },
  { npa: '1308', city: 'La Chaux (Cossonay)', canton: 'VD' },
  { npa: '1148', city: 'Chavannes-le-Veyron', canton: 'VD' },
  { npa: '1316', city: 'Chevilly', canton: 'VD' },
  { npa: '1304', city: 'Cossonay-Ville', canton: 'VD' },
  { npa: '1304', city: 'Allens', canton: 'VD' },
  { npa: '1148', city: 'Cuarnens', canton: 'VD' },
  { npa: '1306', city: 'Daillens', canton: 'VD' },
  { npa: '1304', city: 'Dizy', canton: 'VD' },
  { npa: '1312', city: 'Eclépens', canton: 'VD' },
  { npa: '1313', city: 'Ferreyres', canton: 'VD' },
  { npa: '1124', city: 'Gollion', canton: 'VD' },
  { npa: '1117', city: 'Grancy', canton: 'VD' },
  { npa: '1148', city: "L'Isle", canton: 'VD' },
  { npa: '1148', city: 'Villars-Bozon', canton: 'VD' },
  { npa: '1148', city: 'La Coudre', canton: 'VD' },
  { npa: '1307', city: 'Lussery-Villars', canton: 'VD' },
  { npa: '1148', city: 'Mauraz', canton: 'VD' },
  { npa: '1031', city: 'Mex VD', canton: 'VD' },
  { npa: '1148', city: 'Moiry VD', canton: 'VD' },
  { npa: '1148', city: 'Mont-la-Ville', canton: 'VD' },
  { npa: '1147', city: 'Montricher', canton: 'VD' },
  { npa: '1317', city: 'Orny', canton: 'VD' },
  { npa: '1305', city: 'Penthalaz', canton: 'VD' },
  { npa: '1303', city: 'Penthaz', canton: 'VD' },
  { npa: '1318', city: 'Pompaples', canton: 'VD' },
  { npa: '1315', city: 'La Sarraz', canton: 'VD' },
  { npa: '1304', city: 'Senarclens', canton: 'VD' },
  { npa: '1036', city: 'Sullens', canton: 'VD' },
  { npa: '1302', city: 'Vufflens-la-Ville', canton: 'VD' },
  { npa: '1042', city: 'Assens', canton: 'VD' },
  { npa: '1042', city: 'Bioley-Orjulaz', canton: 'VD' },
  { npa: '1038', city: 'Bercher', canton: 'VD' },
  { npa: '1041', city: 'Bottens', canton: 'VD' },
  { npa: '1053', city: 'Bretigny-sur-Morrens', canton: 'VD' },
  { npa: '1053', city: 'Cugy VD', canton: 'VD' },
  { npa: '1040', city: 'Echallens', canton: 'VD' },
  { npa: '1417', city: 'Essertines-sur-Yverdon', canton: 'VD' },
  { npa: '1417', city: 'Epautheyres', canton: 'VD' },
  { npa: '1037', city: 'Etagnières', canton: 'VD' },
  { npa: '1044', city: 'Fey', canton: 'VD' },
  { npa: '1055', city: 'Froideville', canton: 'VD' },
  { npa: '1054', city: 'Morrens VD', canton: 'VD' },
  { npa: '1377', city: 'Oulens-sous-Echallens', canton: 'VD' },
  { npa: '1416', city: 'Pailly', canton: 'VD' },
  { npa: '1375', city: 'Penthéréaz', canton: 'VD' },
  { npa: '1041', city: 'Poliez-Pittet', canton: 'VD' },
  { npa: '1046', city: 'Rueyres', canton: 'VD' },
  { npa: '1040', city: 'St-Barthélemy VD', canton: 'VD' },
  { npa: '1040', city: 'Villars-le-Terroir', canton: 'VD' },
  { npa: '1418', city: 'Vuarrens', canton: 'VD' },
  { npa: '1041', city: 'Dommartin', canton: 'VD' },
  { npa: '1041', city: 'Naz', canton: 'VD' },
  { npa: '1041', city: 'Poliez-le-Grand', canton: 'VD' },
  { npa: '1043', city: 'Sugnens', canton: 'VD' },
  { npa: '1376', city: 'Goumoens-la-Ville', canton: 'VD' },
  { npa: '1376', city: 'Eclagnens', canton: 'VD' },
  { npa: '1376', city: 'Goumoens-le-Jux', canton: 'VD' },
  { npa: '1427', city: 'Bonvillars', canton: 'VD' },
  { npa: '1452', city: 'Les Rasses', canton: 'VD' },
  { npa: '1453', city: 'Bullet', canton: 'VD' },
  { npa: '1424', city: 'Champagne', canton: 'VD' },
  { npa: '1426', city: 'Concise', canton: 'VD' },
  { npa: '1426', city: 'Corcelles-près-Concise', canton: 'VD' },
  { npa: '1420', city: 'Fiez', canton: 'VD' },
  { npa: '1421', city: 'Fontaines-sur-Grandson', canton: 'VD' },
  { npa: '1429', city: 'Giez', canton: 'VD' },
  { npa: '1421', city: 'Grandevent', canton: 'VD' },
  { npa: '1422', city: 'Grandson', canton: 'VD' },
  { npa: '1453', city: 'Mauborget', canton: 'VD' },
  { npa: '1428', city: 'Mutrux', canton: 'VD' },
  { npa: '1431', city: 'Novalles', canton: 'VD' },
  { npa: '1425', city: 'Onnens VD', canton: 'VD' },
  { npa: '1428', city: 'Provence', canton: 'VD' },
  { npa: '1450', city: 'Ste-Croix', canton: 'VD' },
  { npa: '1450', city: 'La Sagne (Ste-Croix)', canton: 'VD' },
  { npa: '1450', city: 'Le Château-de-Ste-Croix', canton: 'VD' },
  { npa: '1454', city: "L'Auberson", canton: 'VD' },
  { npa: '1454', city: 'La Vraconnaz', canton: 'VD' },
  { npa: '1423', city: 'Villars-Burquin', canton: 'VD' },
  { npa: '1423', city: 'Fontanezier', canton: 'VD' },
  { npa: '1423', city: 'Romairon', canton: 'VD' },
  { npa: '1423', city: 'Vaugondry', canton: 'VD' },
  { npa: '1092', city: 'Belmont-sur-Lausanne', canton: 'VD' },
  { npa: '1033', city: 'Cheseaux-sur-Lausanne', canton: 'VD' },
  { npa: '1023', city: 'Crissier', canton: 'VD' },
  { npa: '1066', city: 'Epalinges', canton: 'VD' },
  { npa: '1008', city: 'Jouxtens-Mézery', canton: 'VD' },
  { npa: '1000', city: 'Lausanne 25', canton: 'VD' },
  { npa: '1000', city: 'Lausanne 26', canton: 'VD' },
  { npa: '1000', city: 'Lausanne 27', canton: 'VD' },
  { npa: '1003', city: 'Lausanne', canton: 'VD' },
  { npa: '1004', city: 'Lausanne', canton: 'VD' },
  { npa: '1005', city: 'Lausanne', canton: 'VD' },
  { npa: '1006', city: 'Lausanne', canton: 'VD' },
  { npa: '1007', city: 'Lausanne', canton: 'VD' },
  { npa: '1010', city: 'Lausanne', canton: 'VD' },
  { npa: '1011', city: 'Lausanne', canton: 'VD' },
  { npa: '1012', city: 'Lausanne', canton: 'VD' },
  { npa: '1015', city: 'Lausanne', canton: 'VD' },
  { npa: '1018', city: 'Lausanne', canton: 'VD' },
  { npa: '1052', city: 'Le Mont-sur-Lausanne', canton: 'VD' },
  { npa: '1094', city: 'Paudex', canton: 'VD' },
  { npa: '1008', city: 'Prilly', canton: 'VD' },
  { npa: '1009', city: 'Pully', canton: 'VD' },
  { npa: '1068', city: 'Les Monts-de-Pully', canton: 'VD' },
  { npa: '1020', city: 'Renens VD', canton: 'VD' },
  { npa: '1032', city: 'Romanel-sur-Lausanne', canton: 'VD' },
  { npa: '1071', city: 'Chexbres', canton: 'VD' },
  { npa: '1072', city: 'Forel (Lavaux)', canton: 'VD' },
  { npa: '1090', city: 'La Croix (Lutry)', canton: 'VD' },
  { npa: '1093', city: 'La Conversion', canton: 'VD' },
  { npa: '1095', city: 'Lutry', canton: 'VD' },
  { npa: '1070', city: 'Puidoux', canton: 'VD' },
  { npa: '1071', city: 'Rivaz', canton: 'VD' },
  { npa: '1071', city: 'St-Saphorin (Lavaux)', canton: 'VD' },
  { npa: '1073', city: 'Savigny', canton: 'VD' },
  { npa: '1073', city: 'Mollie-Margot', canton: 'VD' },
  { npa: '1091', city: 'Grandvaux', canton: 'VD' },
  { npa: '1091', city: 'Aran', canton: 'VD' },
  { npa: '1091', city: 'Chenaux', canton: 'VD' },
  { npa: '1096', city: 'Cully', canton: 'VD' },
  { npa: '1096', city: 'Villette (Lavaux)', canton: 'VD' },
  { npa: '1097', city: 'Riex', canton: 'VD' },
  { npa: '1098', city: 'Epesses', canton: 'VD' },
  { npa: '1123', city: 'Aclens', canton: 'VD' },
  { npa: '1121', city: 'Bremblens', canton: 'VD' },
  { npa: '1164', city: 'Buchillon', canton: 'VD' },
  { npa: '1030', city: 'Bussigny', canton: 'VD' },
  { npa: '1022', city: 'Chavannes-près-Renens', canton: 'VD' },
  { npa: '1134', city: 'Chigny', canton: 'VD' },
  { npa: '1127', city: 'Clarmont', canton: 'VD' },
  { npa: '1135', city: 'Denens', canton: 'VD' },
  { npa: '1026', city: 'Denges', canton: 'VD' },
  { npa: '1026', city: 'Echandens', canton: 'VD' },
  { npa: '1112', city: 'Echichens', canton: 'VD' },
  { npa: '1113', city: 'St-Saphorin-sur-Morges', canton: 'VD' },
  { npa: '1114', city: 'Colombier VD', canton: 'VD' },
  { npa: '1125', city: 'Monnaz', canton: 'VD' },
  { npa: '1024', city: 'Ecublens VD', canton: 'VD' },
  { npa: '1163', city: 'Etoy', canton: 'VD' },
  { npa: '1175', city: 'Lavigny', canton: 'VD' },
  { npa: '1027', city: 'Lonay', canton: 'VD' },
  { npa: '1132', city: 'Lully VD', canton: 'VD' },
  { npa: '1167', city: 'Lussy-sur-Morges', canton: 'VD' },
  { npa: '1110', city: 'Morges', canton: 'VD' },
  { npa: '1028', city: 'Préverenges', canton: 'VD' },
  { npa: '1122', city: 'Romanel-sur-Morges', canton: 'VD' },
  { npa: '1162', city: 'St-Prex', canton: 'VD' },
  { npa: '1025', city: 'St-Sulpice VD', canton: 'VD' },
  { npa: '1131', city: 'Tolochenaz', canton: 'VD' },
  { npa: '1126', city: 'Vaux-sur-Morges', canton: 'VD' },
  { npa: '1029', city: 'Villars-Ste-Croix', canton: 'VD' },
  { npa: '1168', city: 'Villars-sous-Yens', canton: 'VD' },
  { npa: '1134', city: 'Vufflens-le-Château', canton: 'VD' },
  { npa: '1115', city: 'Vullierens', canton: 'VD' },
  { npa: '1169', city: 'Yens', canton: 'VD' },
  { npa: '1116', city: 'Cottens VD', canton: 'VD' },
  { npa: '1128', city: 'Reverolle', canton: 'VD' },
  { npa: '1136', city: 'Bussy-Chardonney', canton: 'VD' },
  { npa: '1141', city: 'Sévery', canton: 'VD' },
  { npa: '1142', city: 'Pampigny', canton: 'VD' },
  { npa: '1143', city: 'Apples', canton: 'VD' },
  { npa: '1063', city: 'Boulens', canton: 'VD' },
  { npa: '1514', city: 'Bussy-sur-Moudon', canton: 'VD' },
  { npa: '1512', city: 'Chavannes-sur-Moudon', canton: 'VD' },
  { npa: '1521', city: 'Curtilles', canton: 'VD' },
  { npa: '1682', city: 'Dompierre VD', canton: 'VD' },
  { npa: '1513', city: 'Hermenches', canton: 'VD' },
  { npa: '1682', city: 'Lovatens', canton: 'VD' },
  { npa: '1522', city: 'Lucens', canton: 'VD' },
  { npa: '1522', city: 'Oulens-sur-Lucens', canton: 'VD' },
  { npa: '1526', city: 'Forel-sur-Lucens', canton: 'VD' },
  { npa: '1526', city: 'Cremin', canton: 'VD' },
  { npa: '1683', city: 'Brenles', canton: 'VD' },
  { npa: '1683', city: 'Chesalles-sur-Moudon', canton: 'VD' },
  { npa: '1683', city: 'Sarzens', canton: 'VD' },
  { npa: '1510', city: 'Moudon', canton: 'VD' },
  { npa: '1045', city: 'Ogens', canton: 'VD' },
  { npa: '1682', city: 'Prévonloup', canton: 'VD' },
  { npa: '1513', city: 'Rossenges', canton: 'VD' },
  { npa: '1510', city: 'Syens', canton: 'VD' },
  { npa: '1515', city: 'Villars-le-Comte', canton: 'VD' },
  { npa: '1509', city: 'Vucherens', canton: 'VD' },
  { npa: '1063', city: 'Chapelle-sur-Moudon', canton: 'VD' },
  { npa: '1063', city: 'Martherenges', canton: 'VD' },
  { npa: '1063', city: 'Peyres-Possens', canton: 'VD' },
  { npa: '1409', city: 'Chanéaz', canton: 'VD' },
  { npa: '1410', city: 'Thierrens', canton: 'VD' },
  { npa: '1410', city: 'Correvon', canton: 'VD' },
  { npa: '1410', city: 'Denezy', canton: 'VD' },
  { npa: '1410', city: 'St-Cierges', canton: 'VD' },
  { npa: '1515', city: 'Neyruz-sur-Moudon', canton: 'VD' },
  { npa: '1277', city: 'Arnex-sur-Nyon', canton: 'VD' },
  { npa: '1273', city: 'Arzier-Le Muids', canton: 'VD' },
  { npa: '1269', city: 'Bassins', canton: 'VD' },
  { npa: '1268', city: 'Begnins', canton: 'VD' },
  { npa: '1279', city: 'Bogis-Bossey', canton: 'VD' },
  { npa: '1277', city: 'Borex', canton: 'VD' },
  { npa: '1279', city: 'Chavannes-de-Bogis', canton: 'VD' },
  { npa: '1290', city: 'Chavannes-des-Bois', canton: 'VD' },
  { npa: '1275', city: 'Chéserex', canton: 'VD' },
  { npa: '1267', city: 'Coinsins', canton: 'VD' },
  { npa: '1291', city: 'Commugny', canton: 'VD' },
  { npa: '1296', city: 'Coppet', canton: 'VD' },
  { npa: '1299', city: 'Crans VD', canton: 'VD' },
  { npa: '1263', city: 'Crassier', canton: 'VD' },
  { npa: '1266', city: 'Duillier', canton: 'VD' },
  { npa: '1262', city: 'Eysins', canton: 'VD' },
  { npa: '1297', city: 'Founex', canton: 'VD' },
  { npa: '1272', city: 'Genolier', canton: 'VD' },
  { npa: '1276', city: 'Gingins', canton: 'VD' },
  { npa: '1271', city: 'Givrins', canton: 'VD' },
  { npa: '1196', city: 'Gland', canton: 'VD' },
  { npa: '1274', city: 'Grens', canton: 'VD' },
  { npa: '1295', city: 'Mies', canton: 'VD' },
  { npa: '1260', city: 'Nyon', canton: 'VD' },
  { npa: '1197', city: 'Prangins', canton: 'VD' },
  { npa: '1278', city: 'La Rippe', canton: 'VD' },
  { npa: '1264', city: 'St-Cergue', canton: 'VD' },
  { npa: '1265', city: 'La Cure', canton: 'VD' },
  { npa: '1274', city: 'Signy', canton: 'VD' },
  { npa: '1295', city: 'Tannay', canton: 'VD' },
  { npa: '1270', city: 'Trélex', canton: 'VD' },
  { npa: '1261', city: 'Le Vaud', canton: 'VD' },
  { npa: '1267', city: 'Vich', canton: 'VD' },
  { npa: '1355', city: "L'Abergement", canton: 'VD' },
  { npa: '1352', city: 'Agiez', canton: 'VD' },
  { npa: '1321', city: 'Arnex-sur-Orbe', canton: 'VD' },
  { npa: '1338', city: 'Ballaigues', canton: 'VD' },
  { npa: '1446', city: 'Baulmes', canton: 'VD' },
  { npa: '1372', city: 'Bavois', canton: 'VD' },
  { npa: '1353', city: 'Bofflens', canton: 'VD' },
  { npa: '1329', city: 'Bretonnières', canton: 'VD' },
  { npa: '1373', city: 'Chavornay', canton: 'VD' },
  { npa: '1374', city: 'Corcelles-sur-Chavornay', canton: 'VD' },
  { npa: '1435', city: 'Essert-Pittet', canton: 'VD' },
  { npa: '1356', city: 'Les Clées', canton: 'VD' },
  { npa: '1356', city: 'La Russille', canton: 'VD' },
  { npa: '1322', city: 'Croy', canton: 'VD' },
  { npa: '1326', city: 'Juriens', canton: 'VD' },
  { npa: '1357', city: 'Lignerolle', canton: 'VD' },
  { npa: '1354', city: 'Montcherand', canton: 'VD' },
  { npa: '1350', city: 'Orbe', canton: 'VD' },
  { npa: '1148', city: 'La Praz', canton: 'VD' },
  { npa: '1324', city: 'Premier', canton: 'VD' },
  { npa: '1439', city: 'Rances', canton: 'VD' },
  { npa: '1323', city: 'Romainmôtier', canton: 'VD' },
  { npa: '1355', city: 'Sergey', canton: 'VD' },
  { npa: '1358', city: 'Valeyres-sous-Rances', canton: 'VD' },
  { npa: '1337', city: 'Vallorbe', canton: 'VD' },
  { npa: '1325', city: 'Vaulion', canton: 'VD' },
  { npa: '1445', city: 'Vuiteboeuf', canton: 'VD' },
  { npa: '1082', city: 'Corcelles-le-Jorat', canton: 'VD' },
  { npa: '1613', city: 'Maracon', canton: 'VD' },
  { npa: '1081', city: 'Montpreveyres', canton: 'VD' },
  { npa: '1088', city: 'Ropraz', canton: 'VD' },
  { npa: '1077', city: 'Servion', canton: 'VD' },
  { npa: '1080', city: 'Les Cullayes', canton: 'VD' },
  { npa: '1085', city: 'Vulliens', canton: 'VD' },
  { npa: '1041', city: 'Montaubion-Chardonney', canton: 'VD' },
  { npa: '1058', city: 'Villars-Tiercelin', canton: 'VD' },
  { npa: '1059', city: 'Peney-le-Jorat', canton: 'VD' },
  { npa: '1061', city: 'Villars-Mendraz', canton: 'VD' },
  { npa: '1062', city: 'Sottens', canton: 'VD' },
  { npa: '1078', city: 'Essertes', canton: 'VD' },
  { npa: '1607', city: 'Palézieux', canton: 'VD' },
  { npa: '1607', city: 'Palézieux-Village', canton: 'VD' },
  { npa: '1607', city: 'Les Tavernes', canton: 'VD' },
  { npa: '1607', city: 'Les Thioleyres', canton: 'VD' },
  { npa: '1608', city: 'Oron-le-Châtel', canton: 'VD' },
  { npa: '1608', city: 'Bussigny-sur-Oron', canton: 'VD' },
  { npa: '1608', city: 'Chesalles-sur-Oron', canton: 'VD' },
  { npa: '1610', city: 'Oron-la-Ville', canton: 'VD' },
  { npa: '1610', city: 'Châtillens', canton: 'VD' },
  { npa: '1610', city: 'Vuibroye', canton: 'VD' },
  { npa: '1612', city: 'Ecoteaux', canton: 'VD' },
  { npa: '1076', city: 'Ferlens VD', canton: 'VD' },
  { npa: '1083', city: 'Mézières VD', canton: 'VD' },
  { npa: '1084', city: 'Carrouge VD', canton: 'VD' },
  { npa: '1537', city: 'Champtauroz', canton: 'VD' },
  { npa: '1545', city: 'Chevroux', canton: 'VD' },
  { npa: '1562', city: 'Corcelles-près-Payerne', canton: 'VD' },
  { npa: '1543', city: 'Grandcour', canton: 'VD' },
  { npa: '1525', city: 'Henniez', canton: 'VD' },
  { npa: '1565', city: 'Missy', canton: 'VD' },
  { npa: '1530', city: 'Payerne', canton: 'VD' },
  { npa: '1551', city: 'Vers-chez-Perrin', canton: 'VD' },
  { npa: '1552', city: 'Trey', canton: 'VD' },
  { npa: '1538', city: 'Treytorrens (Payerne)', canton: 'VD' },
  { npa: '1554', city: 'Sédeilles', canton: 'VD' },
  { npa: '1554', city: 'Rossens VD', canton: 'VD' },
  { npa: '1555', city: 'Villarzel', canton: 'VD' },
  { npa: '1523', city: 'Granges-près-Marnand', canton: 'VD' },
  { npa: '1524', city: 'Marnand', canton: 'VD' },
  { npa: '1525', city: 'Seigneux', canton: 'VD' },
  { npa: '1534', city: 'Sassel', canton: 'VD' },
  { npa: '1535', city: 'Combremont-le-Grand', canton: 'VD' },
  { npa: '1536', city: 'Combremont-le-Petit', canton: 'VD' },
  { npa: '1682', city: 'Villars-Bramard', canton: 'VD' },
  { npa: '1682', city: 'Cerniaz VD', canton: 'VD' },
  { npa: '1660', city: "Château-d'Oex", canton: 'VD' },
  { npa: '1660', city: 'Les Moulins', canton: 'VD' },
  { npa: '1660', city: "L'Etivaz", canton: 'VD' },
  { npa: '1660', city: 'La Lécherette', canton: 'VD' },
  { npa: '1658', city: 'Rossinière', canton: 'VD' },
  { npa: '1658', city: 'La Tine', canton: 'VD' },
  { npa: '1659', city: 'Rougemont', canton: 'VD' },
  { npa: '1659', city: 'Flendruz', canton: 'VD' },
  { npa: '1165', city: 'Allaman', canton: 'VD' },
  { npa: '1195', city: 'Bursinel', canton: 'VD' },
  { npa: '1183', city: 'Bursins', canton: 'VD' },
  { npa: '1268', city: 'Burtigny', canton: 'VD' },
  { npa: '1195', city: 'Dully', canton: 'VD' },
  { npa: '1186', city: 'Essertines-sur-Rolle', canton: 'VD' },
  { npa: '1182', city: 'Gilly', canton: 'VD' },
  { npa: '1184', city: 'Luins', canton: 'VD' },
  { npa: '1185', city: 'Mont-sur-Rolle', canton: 'VD' },
  { npa: '1166', city: 'Perroy', canton: 'VD' },
  { npa: '1180', city: 'Rolle', canton: 'VD' },
  { npa: '1180', city: 'Tartegnin', canton: 'VD' },
  { npa: '1184', city: 'Vinzel', canton: 'VD' },
  { npa: '1342', city: 'Le Pont', canton: 'VD' },
  { npa: '1344', city: "L'Abbaye", canton: 'VD' },
  { npa: '1346', city: 'Les Bioux', canton: 'VD' },
  { npa: '1341', city: "L'Orient", canton: 'VD' },
  { npa: '1347', city: 'Le Sentier', canton: 'VD' },
  { npa: '1347', city: 'Le Solliat', canton: 'VD' },
  { npa: '1348', city: 'Le Brassus', canton: 'VD' },
  { npa: '1343', city: 'Les Charbonnières', canton: 'VD' },
  { npa: '1345', city: 'Le Lieu', canton: 'VD' },
  { npa: '1345', city: 'Le Séchey', canton: 'VD' },
  { npa: '1801', city: 'Le Mont-Pèlerin', canton: 'VD' },
  { npa: '1803', city: 'Chardonne', canton: 'VD' },
  { npa: '1802', city: 'Corseaux', canton: 'VD' },
  { npa: '1804', city: 'Corsier-sur-Vevey', canton: 'VD' },
  { npa: '1808', city: 'Les Monts-de-Corsier', canton: 'VD' },
  { npa: '1809', city: 'Fenil-sur-Corsier', canton: 'VD' },
  { npa: '1805', city: 'Jongny', canton: 'VD' },
  { npa: '1815', city: 'Clarens', canton: 'VD' },
  { npa: '1816', city: 'Chailly-Montreux', canton: 'VD' },
  { npa: '1817', city: 'Brent', canton: 'VD' },
  { npa: '1820', city: 'Montreux', canton: 'VD' },
  { npa: '1820', city: 'Territet', canton: 'VD' },
  { npa: '1822', city: 'Chernex', canton: 'VD' },
  { npa: '1823', city: 'Glion', canton: 'VD' },
  { npa: '1824', city: 'Caux', canton: 'VD' },
  { npa: '1832', city: 'Chamby', canton: 'VD' },
  { npa: '1832', city: 'Villard-sur-Chamby', canton: 'VD' },
  { npa: '1833', city: 'Les Avants', canton: 'VD' },
  { npa: '1814', city: 'La Tour-de-Peilz', canton: 'VD' },
  { npa: '1800', city: 'Vevey', canton: 'VD' },
  { npa: '1820', city: 'Veytaux', canton: 'VD' },
  { npa: '1806', city: 'St-Légier-La Chiésaz', canton: 'VD' },
  { npa: '1807', city: 'Blonay', canton: 'VD' },
  { npa: '1432', city: 'Belmont-sur-Yverdon', canton: 'VD' },
  { npa: '1407', city: 'Bioley-Magnoux', canton: 'VD' },
  { npa: '1436', city: 'Chamblon', canton: 'VD' },
  { npa: '1443', city: 'Champvent', canton: 'VD' },
  { npa: '1443', city: 'Essert-sous-Champvent', canton: 'VD' },
  { npa: '1443', city: 'Villars-sous-Champvent', canton: 'VD' },
  { npa: '1464', city: 'Chavannes-le-Chêne', canton: 'VD' },
  { npa: '1464', city: 'Chêne-Pâquier', canton: 'VD' },
  { npa: '1400', city: 'Cheseaux-Noréaz', canton: 'VD' },
  { npa: '1406', city: 'Cronay', canton: 'VD' },
  { npa: '1404', city: 'Cuarny', canton: 'VD' },
  { npa: '1415', city: 'Démoret', canton: 'VD' },
  { npa: '1407', city: 'Donneloye', canton: 'VD' },
  { npa: '1407', city: 'Gossens', canton: 'VD' },
  { npa: '1407', city: 'Mézery-près-Donneloye', canton: 'VD' },
  { npa: '1408', city: 'Prahins', canton: 'VD' },
  { npa: '1434', city: 'Ependes VD', canton: 'VD' },
  { npa: '1438', city: 'Mathod', canton: 'VD' },
  { npa: '1415', city: 'Molondin', canton: 'VD' },
  { npa: '1442', city: 'Montagny-près-Yverdon', canton: 'VD' },
  { npa: '1047', city: 'Oppens', canton: 'VD' },
  { npa: '1430', city: 'Orges', canton: 'VD' },
  { npa: '1413', city: 'Orzens', canton: 'VD' },
  { npa: '1405', city: 'Pomy', canton: 'VD' },
  { npa: '1463', city: 'Rovray', canton: 'VD' },
  { npa: '1433', city: 'Suchy', canton: 'VD' },
  { npa: '1437', city: 'Suscévaz', canton: 'VD' },
  { npa: '1436', city: 'Treycovagnes', canton: 'VD' },
  { npa: '1412', city: 'Ursins', canton: 'VD' },
  { npa: '1441', city: 'Valeyres-sous-Montagny', canton: 'VD' },
  { npa: '1412', city: 'Valeyres-sous-Ursins', canton: 'VD' },
  { npa: '1404', city: 'Villars-Epeney', canton: 'VD' },
  { npa: '1431', city: 'Vugelles-La Mothe', canton: 'VD' },
  { npa: '1400', city: 'Yverdon-les-Bains', canton: 'VD' },
  { npa: '1432', city: 'Gressy', canton: 'VD' },
  { npa: '1462', city: 'Yvonand', canton: 'VD' },
  { npa: '3900', city: 'Brig', canton: 'VS' },
  { npa: '3900', city: 'Gamsen', canton: 'VS' },
  { npa: '3900', city: 'Brigerbad', canton: 'VS' },
  { npa: '3902', city: 'Glis', canton: 'VS' },
  { npa: '3939', city: 'Eggerberg', canton: 'VS' },
  { npa: '3903', city: 'Mund', canton: 'VS' },
  { npa: '3903', city: 'Birgisch', canton: 'VS' },
  { npa: '3904', city: 'Naters', canton: 'VS' },
  { npa: '3914', city: 'Blatten b. Naters', canton: 'VS' },
  { npa: '3914', city: 'Belalp', canton: 'VS' },
  { npa: '3901', city: 'Rothwald', canton: 'VS' },
  { npa: '3911', city: 'Ried-Brig', canton: 'VS' },
  { npa: '3907', city: 'Simplon Dorf', canton: 'VS' },
  { npa: '3907', city: 'Simplon Hospiz', canton: 'VS' },
  { npa: '3907', city: 'Gabi (Simplon)', canton: 'VS' },
  { npa: '3912', city: 'Termen', canton: 'VS' },
  { npa: '3913', city: 'Rosswald', canton: 'VS' },
  { npa: '3907', city: 'Gondo', canton: 'VS' },
  { npa: '1957', city: 'Ardon', canton: 'VS' },
  { npa: '1955', city: 'Chamoson', canton: 'VS' },
  { npa: '1955', city: 'Mayens-de-Chamoson', canton: 'VS' },
  { npa: '1955', city: 'Les Vérines (Chamoson)', canton: 'VS' },
  { npa: '1955', city: 'Némiaz (Chamoson)', canton: 'VS' },
  { npa: '1955', city: 'Grugnay (Chamoson)', canton: 'VS' },
  { npa: '1955', city: 'St-Pierre-de-Clages', canton: 'VS' },
  { npa: '1964', city: 'Conthey', canton: 'VS' },
  { npa: '1975', city: 'St-Séverin', canton: 'VS' },
  { npa: '1976', city: 'Erde', canton: 'VS' },
  { npa: '1976', city: 'Aven', canton: 'VS' },
  { npa: '1976', city: 'Daillon', canton: 'VS' },
  { npa: '1993', city: 'Clèbes (Nendaz)', canton: 'VS' },
  { npa: '1994', city: 'Aproz (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Basse-Nendaz', canton: 'VS' },
  { npa: '1996', city: 'Fey (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Bieudron (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Condémines (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Saclentse', canton: 'VS' },
  { npa: '1996', city: 'Baar (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Beuson (Nendaz)', canton: 'VS' },
  { npa: '1996', city: 'Brignon (Nendaz)', canton: 'VS' },
  { npa: '1997', city: 'Haute-Nendaz', canton: 'VS' },
  { npa: '1997', city: 'Siviez (Nendaz)', canton: 'VS' },
  { npa: '1997', city: 'Sornard (Nendaz)', canton: 'VS' },
  { npa: '1963', city: 'Vétroz', canton: 'VS' },
  { npa: '1946', city: 'Bourg-St-Pierre', canton: 'VS' },
  { npa: '1945', city: 'Liddes', canton: 'VS' },
  { npa: '1945', city: 'Fontaine Dessus (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Fontaine Dessous (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Dranse (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Chandonne (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Rive Haute (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Fornex (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Les Moulins VS (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Vichères (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Palasuit (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Chez Petit (Liddes)', canton: 'VS' },
  { npa: '1945', city: 'Petit Vichères (Liddes)', canton: 'VS' },
  { npa: '1937', city: 'Orsières', canton: 'VS' },
  { npa: '1938', city: 'Champex-Lac', canton: 'VS' },
  { npa: '1943', city: 'Praz-de-Fort', canton: 'VS' },
  { npa: '1944', city: 'La Fouly VS', canton: 'VS' },
  { npa: '1933', city: 'Sembrancher', canton: 'VS' },
  { npa: '1933', city: 'Chamoille (Sembrancher)', canton: 'VS' },
  { npa: '1933', city: 'La Garde (Sembrancher)', canton: 'VS' },
  { npa: '1927', city: 'Chemin', canton: 'VS' },
  { npa: '1933', city: 'Vens (Sembrancher)', canton: 'VS' },
  { npa: '1934', city: 'Le Châble VS', canton: 'VS' },
  { npa: '1934', city: 'Bruson', canton: 'VS' },
  { npa: '1936', city: 'Verbier', canton: 'VS' },
  { npa: '1941', city: 'Vollèges', canton: 'VS' },
  { npa: '1941', city: 'Cries (Vollèges)', canton: 'VS' },
  { npa: '1942', city: 'Levron', canton: 'VS' },
  { npa: '1947', city: 'Versegères', canton: 'VS' },
  { npa: '1947', city: 'Champsec', canton: 'VS' },
  { npa: '1948', city: 'Lourtier', canton: 'VS' },
  { npa: '1948', city: 'Fionnay', canton: 'VS' },
  { npa: '1948', city: 'Sarreyer', canton: 'VS' },
  { npa: '3997', city: 'Bellwald', canton: 'VS' },
  { npa: '3996', city: 'Binn', canton: 'VS' },
  { npa: '3995', city: 'Ernen', canton: 'VS' },
  { npa: '3995', city: 'Ausserbinn', canton: 'VS' },
  { npa: '3995', city: 'Mühlebach (Goms)', canton: 'VS' },
  { npa: '3995', city: 'Steinhaus', canton: 'VS' },
  { npa: '3984', city: 'Fiesch', canton: 'VS' },
  { npa: '3801', city: 'Jungfraujoch', canton: 'VS' },
  { npa: '3984', city: 'Fieschertal', canton: 'VS' },
  { npa: '3994', city: 'Lax', canton: 'VS' },
  { npa: '3988', city: 'Ulrichen', canton: 'VS' },
  { npa: '3988', city: 'Obergesteln', canton: 'VS' },
  { npa: '3999', city: 'Oberwald', canton: 'VS' },
  { npa: '3985', city: 'Münster VS', canton: 'VS' },
  { npa: '3985', city: 'Geschinen', canton: 'VS' },
  { npa: '3989', city: 'Biel VS', canton: 'VS' },
  { npa: '3989', city: 'Ritzingen', canton: 'VS' },
  { npa: '3989', city: 'Selkingen', canton: 'VS' },
  { npa: '3989', city: 'Niederwald', canton: 'VS' },
  { npa: '3989', city: 'Blitzingen', canton: 'VS' },
  { npa: '3998', city: 'Reckingen VS', canton: 'VS' },
  { npa: '3998', city: 'Gluringen', canton: 'VS' },
  { npa: '1966', city: 'Fortunau (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Luc (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'St-Romain (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Saxonne (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Villa (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'La Place (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Botyre (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Blignou (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Argnou (Ayent)', canton: 'VS' },
  { npa: '1966', city: 'Signèse (Ayent)', canton: 'VS' },
  { npa: '1972', city: 'Anzère', canton: 'VS' },
  { npa: '1983', city: 'Evolène', canton: 'VS' },
  { npa: '1983', city: 'Lanna', canton: 'VS' },
  { npa: '1984', city: 'Les Haudères', canton: 'VS' },
  { npa: '1984', city: 'La Tour VS', canton: 'VS' },
  { npa: '1985', city: 'La Sage', canton: 'VS' },
  { npa: '1985', city: 'La Forclaz VS', canton: 'VS' },
  { npa: '1985', city: 'Villa (Evolène)', canton: 'VS' },
  { npa: '1986', city: 'Arolla', canton: 'VS' },
  { npa: '1982', city: 'Euseigne', canton: 'VS' },
  { npa: '1987', city: 'Hérémence', canton: 'VS' },
  { npa: '1969', city: 'St-Martin VS', canton: 'VS' },
  { npa: '1969', city: 'Liez (St-Martin)', canton: 'VS' },
  { npa: '1969', city: 'Trogne (St-Martin)', canton: 'VS' },
  { npa: '1969', city: 'Suen (St-Martin)', canton: 'VS' },
  { npa: '1969', city: 'Eison (St-Martin)', canton: 'VS' },
  { npa: '1981', city: 'Vex', canton: 'VS' },
  { npa: '1988', city: 'Thyon', canton: 'VS' },
  { npa: '1988', city: 'Les Collons', canton: 'VS' },
  { npa: '1992', city: 'Les Mayens-de-Sion', canton: 'VS' },
  { npa: '1961', city: 'Vernamiège', canton: 'VS' },
  { npa: '1968', city: 'Mase', canton: 'VS' },
  { npa: '1973', city: 'Nax', canton: 'VS' },
  { npa: '3951', city: 'Agarn', canton: 'VS' },
  { npa: '3955', city: 'Albinen', canton: 'VS' },
  { npa: '3947', city: 'Ergisch', canton: 'VS' },
  { npa: '3953', city: 'Inden', canton: 'VS' },
  { npa: '3952', city: 'Susten', canton: 'VS' },
  { npa: '3953', city: 'Leuk Stadt', canton: 'VS' },
  { npa: '3957', city: 'Erschmatt', canton: 'VS' },
  { npa: '3954', city: 'Leukerbad', canton: 'VS' },
  { npa: '3948', city: 'Oberems', canton: 'VS' },
  { npa: '3970', city: 'Salgesch', canton: 'VS' },
  { npa: '3953', city: 'Varen', canton: 'VS' },
  { npa: '3956', city: 'Guttet-Feschel', canton: 'VS' },
  { npa: '3945', city: 'Gampel', canton: 'VS' },
  { npa: '3945', city: 'Niedergampel', canton: 'VS' },
  { npa: '3957', city: 'Bratsch', canton: 'VS' },
  { npa: '3946', city: 'Turtmann', canton: 'VS' },
  { npa: '3946', city: 'Gruben', canton: 'VS' },
  { npa: '3948', city: 'Unterems', canton: 'VS' },
  { npa: '1932', city: 'Bovernier', canton: 'VS' },
  { npa: '1932', city: 'Les Valettes (Bovernier)', canton: 'VS' },
  { npa: '1926', city: 'Fully', canton: 'VS' },
  { npa: '1914', city: 'Isérables', canton: 'VS' },
  { npa: '1911', city: 'Ovronnaz', canton: 'VS' },
  { npa: '1912', city: 'Leytron', canton: 'VS' },
  { npa: '1912', city: 'Produit (Leytron)', canton: 'VS' },
  { npa: '1912', city: 'Montagnon (Leytron)', canton: 'VS' },
  { npa: '1912', city: 'Dugny (Leytron)', canton: 'VS' },
  { npa: '1906', city: 'Charrat', canton: 'VS' },
  { npa: '1920', city: 'Martigny', canton: 'VS' },
  { npa: '1921', city: 'Martigny-Croix', canton: 'VS' },
  { npa: '1928', city: 'Ravoire', canton: 'VS' },
  { npa: '1908', city: 'Riddes', canton: 'VS' },
  { npa: '1914', city: 'Auddes-sur-Riddes', canton: 'VS' },
  { npa: '1918', city: 'La Tzoumaz', canton: 'VS' },
  { npa: '1913', city: 'Saillon', canton: 'VS' },
  { npa: '1907', city: 'Saxon', canton: 'VS' },
  { npa: '1929', city: 'Trient', canton: 'VS' },
  { npa: '1874', city: 'Champéry', canton: 'VS' },
  { npa: '1868', city: 'Collombey', canton: 'VS' },
  { npa: '1868', city: 'Collombey', canton: 'VS' },
  { npa: '1893', city: 'Muraz (Collombey)', canton: 'VS' },
  { npa: '1870', city: 'Monthey', canton: 'VS' },
  { npa: '1870', city: 'Monthey', canton: 'VS' },
  { npa: '1871', city: 'Choëx', canton: 'VS' },
  { npa: '1871', city: 'Les Giettes', canton: 'VS' },
  { npa: '1897', city: 'Bouveret', canton: 'VS' },
  { npa: '1897', city: 'Les Evouettes', canton: 'VS' },
  { npa: '1898', city: 'St-Gingolph', canton: 'VS' },
  { npa: '1872', city: 'Troistorrents', canton: 'VS' },
  { npa: '1875', city: 'Morgins', canton: 'VS' },
  { npa: '1873', city: "Val-d'Illiez", canton: 'VS' },
  { npa: '1873', city: 'Champoussin', canton: 'VS' },
  { npa: '1873', city: 'Les Crosets', canton: 'VS' },
  { npa: '1895', city: 'Vionnaz', canton: 'VS' },
  { npa: '1899', city: 'Torgon', canton: 'VS' },
  { npa: '1896', city: 'Vouvry', canton: 'VS' },
  { npa: '1896', city: 'Miex', canton: 'VS' },
  { npa: '3983', city: 'Bister', canton: 'VS' },
  { npa: '3982', city: 'Bitsch', canton: 'VS' },
  { npa: '3993', city: 'Grengiols', canton: 'VS' },
  { npa: '3983', city: 'Goppisberg', canton: 'VS' },
  { npa: '3983', city: 'Greich', canton: 'VS' },
  { npa: '3986', city: 'Ried-Mörel', canton: 'VS' },
  { npa: '3987', city: 'Riederalp', canton: 'VS' },
  { npa: '3938', city: 'Ausserberg', canton: 'VS' },
  { npa: '3919', city: 'Blatten (Lötschen)', canton: 'VS' },
  { npa: '3935', city: 'Bürchen', canton: 'VS' },
  { npa: '3943', city: 'Eischoll', canton: 'VS' },
  { npa: '3916', city: 'Ferden', canton: 'VS' },
  { npa: '3917', city: 'Goppenstein', canton: 'VS' },
  { npa: '3917', city: 'Kippel', canton: 'VS' },
  { npa: '3942', city: 'Niedergesteln', canton: 'VS' },
  { npa: '3942', city: 'Raron', canton: 'VS' },
  { npa: '3942', city: 'St. German', canton: 'VS' },
  { npa: '3944', city: 'Unterbäch VS', canton: 'VS' },
  { npa: '3918', city: 'Wiler (Lötschen)', canton: 'VS' },
  { npa: '3983', city: 'Mörel', canton: 'VS' },
  { npa: '3983', city: 'Filet', canton: 'VS' },
  { npa: '3940', city: 'Steg VS', canton: 'VS' },
  { npa: '3949', city: 'Hohtenn', canton: 'VS' },
  { npa: '3991', city: 'Betten', canton: 'VS' },
  { npa: '3992', city: 'Bettmeralp', canton: 'VS' },
  { npa: '3994', city: 'Martisberg', canton: 'VS' },
  { npa: '1903', city: 'Collonges', canton: 'VS' },
  { npa: '1905', city: 'Dorénaz', canton: 'VS' },
  { npa: '1902', city: 'Evionnaz', canton: 'VS' },
  { npa: '1925', city: 'Finhaut', canton: 'VS' },
  { npa: '1925', city: 'Le Châtelard VS', canton: 'VS' },
  { npa: '1869', city: 'Massongex', canton: 'VS' },
  { npa: '1890', city: 'St-Maurice', canton: 'VS' },
  { npa: '1890', city: 'Mex VS', canton: 'VS' },
  { npa: '1922', city: 'Salvan', canton: 'VS' },
  { npa: '1922', city: 'Les Granges (Salvan)', canton: 'VS' },
  { npa: '1923', city: 'Les Marécottes', canton: 'VS' },
  { npa: '1923', city: 'Le Trétien', canton: 'VS' },
  { npa: '1904', city: 'Vernayaz', canton: 'VS' },
  { npa: '1891', city: 'Vérossaz', canton: 'VS' },
  { npa: '3966', city: 'Chalais', canton: 'VS' },
  { npa: '3966', city: 'Réchy', canton: 'VS' },
  { npa: '3967', city: 'Vercorin', canton: 'VS' },
  { npa: '3965', city: 'Chippis', canton: 'VS' },
  { npa: '3979', city: 'Grône', canton: 'VS' },
  { npa: '1977', city: 'Icogne', canton: 'VS' },
  { npa: '1978', city: 'Lens', canton: 'VS' },
  { npa: '3978', city: 'Flanthey', canton: 'VS' },
  { npa: '1958', city: 'St-Léonard', canton: 'VS' },
  { npa: '3960', city: 'Sierre', canton: 'VS' },
  { npa: '3960', city: 'Muraz (Sierre)', canton: 'VS' },
  { npa: '3976', city: 'Noës', canton: 'VS' },
  { npa: '3977', city: 'Granges VS', canton: 'VS' },
  { npa: '3960', city: 'Niouc', canton: 'VS' },
  { npa: '3961', city: 'Vissoie', canton: 'VS' },
  { npa: '3961', city: 'St-Luc', canton: 'VS' },
  { npa: '3961', city: 'Chandolin', canton: 'VS' },
  { npa: '3961', city: 'Ayer', canton: 'VS' },
  { npa: '3961', city: 'Zinal', canton: 'VS' },
  { npa: '3961', city: 'Grimentz', canton: 'VS' },
  { npa: '3961', city: 'Mission', canton: 'VS' },
  { npa: '3961', city: 'St-Jean VS', canton: 'VS' },
  { npa: '3960', city: 'Corin-de-la-Crête', canton: 'VS' },
  { npa: '3960', city: 'Loc', canton: 'VS' },
  { npa: '3963', city: 'Crans-Montana', canton: 'VS' },
  { npa: '3963', city: 'Montana', canton: 'VS' },
  { npa: '3963', city: 'Aminona', canton: 'VS' },
  { npa: '3971', city: 'Chermignon', canton: 'VS' },
  { npa: '3971', city: "Chermignon-d'en-Bas", canton: 'VS' },
  { npa: '3971', city: 'Ollon VS', canton: 'VS' },
  { npa: '3974', city: 'Mollens VS', canton: 'VS' },
  { npa: '3975', city: 'Randogne', canton: 'VS' },
  { npa: '3976', city: 'Champzabé', canton: 'VS' },
  { npa: '3968', city: 'Veyras', canton: 'VS' },
  { npa: '3972', city: 'Miège', canton: 'VS' },
  { npa: '3973', city: 'Venthône', canton: 'VS' },
  { npa: '1974', city: 'Arbaz', canton: 'VS' },
  { npa: '1971', city: 'Grimisuat', canton: 'VS' },
  { npa: '1971', city: 'Champlan (Grimisuat)', canton: 'VS' },
  { npa: '1965', city: 'Savièse', canton: 'VS' },
  { npa: '1950', city: 'Sion', canton: 'VS' },
  { npa: '1958', city: 'Uvrier', canton: 'VS' },
  { npa: '1962', city: 'Pont-de-la-Morge (Sion)', canton: 'VS' },
  { npa: '1967', city: 'Bramois', canton: 'VS' },
  { npa: '1991', city: 'Salins', canton: 'VS' },
  { npa: '1991', city: 'Arvillard (Salins)', canton: 'VS' },
  { npa: '1991', city: 'Pravidondaz (Salins)', canton: 'VS' },
  { npa: '1991', city: 'Turin (Salins)', canton: 'VS' },
  { npa: '1991', city: 'Misériez (Salins)', canton: 'VS' },
  { npa: '1992', city: 'Les Agettes', canton: 'VS' },
  { npa: '1992', city: 'La Vernaz (Les Agettes)', canton: 'VS' },
  { npa: '1992', city: "Crête-à-l'Oeil (Les Agettes)", canton: 'VS' },
  { npa: '1993', city: 'Veysonnaz', canton: 'VS' },
  { npa: '3937', city: 'Baltschieder', canton: 'VS' },
  { npa: '3937', city: 'Baltschieder', canton: 'VS' },
  { npa: '3922', city: 'Eisten', canton: 'VS' },
  { npa: '3922', city: 'Kalpetran', canton: 'VS' },
  { npa: '3926', city: 'Embd', canton: 'VS' },
  { npa: '3925', city: 'Grächen', canton: 'VS' },
  { npa: '3931', city: 'Lalden', canton: 'VS' },
  { npa: '3928', city: 'Randa', canton: 'VS' },
  { npa: '3905', city: 'Saas-Almagell', canton: 'VS' },
  { npa: '3908', city: 'Saas-Balen', canton: 'VS' },
  { npa: '3906', city: 'Saas-Fee', canton: 'VS' },
  { npa: '3910', city: 'Saas-Grund', canton: 'VS' },
  { npa: '3924', city: 'St. Niklaus VS', canton: 'VS' },
  { npa: '3927', city: 'Herbriggen', canton: 'VS' },
  { npa: '3922', city: 'Stalden VS', canton: 'VS' },
  { npa: '3933', city: 'Staldenried', canton: 'VS' },
  { npa: '3929', city: 'Täsch', canton: 'VS' },
  { npa: '3923', city: 'Törbel', canton: 'VS' },
  { npa: '3930', city: 'Visp', canton: 'VS' },
  { npa: '3930', city: 'Eyholz', canton: 'VS' },
  { npa: '3932', city: 'Visperterminen', canton: 'VS' },
  { npa: '3934', city: 'Zeneggen', canton: 'VS' },
  { npa: '3920', city: 'Zermatt', canton: 'VS' },
  { npa: '2015', city: 'Areuse', canton: 'NE' },
  { npa: '2017', city: 'Boudry', canton: 'NE' },
  { npa: '2016', city: 'Cortaillod', canton: 'NE' },
  { npa: '2019', city: 'Rochefort', canton: 'NE' },
  { npa: '2019', city: 'Chambrelien', canton: 'NE' },
  { npa: '2037', city: 'Montezillon', canton: 'NE' },
  { npa: '2149', city: 'Champ-du-Moulin', canton: 'NE' },
  { npa: '2149', city: 'Fretereules', canton: 'NE' },
  { npa: '2149', city: 'Brot-Dessous', canton: 'NE' },
  { npa: '2012', city: 'Auvernier', canton: 'NE' },
  { npa: '2013', city: 'Colombier NE', canton: 'NE' },
  { npa: '2014', city: 'Bôle', canton: 'NE' },
  { npa: '2022', city: 'Bevaix', canton: 'NE' },
  { npa: '2023', city: 'Gorgier', canton: 'NE' },
  { npa: '2024', city: 'St-Aubin-Sauges', canton: 'NE' },
  { npa: '2025', city: 'Chez-le-Bart', canton: 'NE' },
  { npa: '2027', city: 'Montalchez', canton: 'NE' },
  { npa: '2027', city: 'Fresens', canton: 'NE' },
  { npa: '2028', city: 'Vaumarcus', canton: 'NE' },
  { npa: '2300', city: 'La Chaux-de-Fonds', canton: 'NE' },
  { npa: '2300', city: 'La Cibourg', canton: 'NE' },
  { npa: '2322', city: 'Le Crêt-du-Locle', canton: 'NE' },
  { npa: '2333', city: 'La Cibourg', canton: 'NE' },
  { npa: '2616', city: 'La Cibourg', canton: 'NE' },
  { npa: '2325', city: 'Les Planchettes', canton: 'NE' },
  { npa: '2314', city: 'La Sagne NE', canton: 'NE' },
  { npa: '2406', city: 'La Brévine', canton: 'NE' },
  { npa: '2406', city: 'Les Taillères', canton: 'NE' },
  { npa: '2406', city: 'La Châtagne', canton: 'NE' },
  { npa: '2406', city: 'Le Brouillet', canton: 'NE' },
  { npa: '2318', city: 'Brot-Plamboz', canton: 'NE' },
  { npa: '2414', city: 'Le Cerneux-Péquignot', canton: 'NE' },
  { npa: '2405', city: 'La Chaux-du-Milieu', canton: 'NE' },
  { npa: '2400', city: 'Le Locle', canton: 'NE' },
  { npa: '2400', city: 'Le Prévoux', canton: 'NE' },
  { npa: '2416', city: 'Les Brenets', canton: 'NE' },
  { npa: '2316', city: 'Les Ponts-de-Martel', canton: 'NE' },
  { npa: '2316', city: 'Petit-Martel', canton: 'NE' },
  { npa: '2087', city: 'Cornaux NE', canton: 'NE' },
  { npa: '2088', city: 'Cressier NE', canton: 'NE' },
  { npa: '2073', city: 'Enges', canton: 'NE' },
  { npa: '2068', city: 'Hauterive NE', canton: 'NE' },
  { npa: '2525', city: 'Le Landeron', canton: 'NE' },
  { npa: '2523', city: 'Lignières', canton: 'NE' },
  { npa: '2000', city: 'Neuchâtel', canton: 'NE' },
  { npa: '2034', city: 'Peseux', canton: 'NE' },
  { npa: '2035', city: 'Corcelles NE', canton: 'NE' },
  { npa: '2036', city: 'Cormondrèche', canton: 'NE' },
  { npa: '2042', city: 'Valangin', canton: 'NE' },
  { npa: '2067', city: 'Chaumont', canton: 'NE' },
  { npa: '2072', city: 'St-Blaise', canton: 'NE' },
  { npa: '2074', city: 'Marin-Epagnier', canton: 'NE' },
  { npa: '2075', city: 'Thielle', canton: 'NE' },
  { npa: '2075', city: 'Wavre', canton: 'NE' },
  { npa: '2037', city: 'Montmollin', canton: 'NE' },
  { npa: '2043', city: 'Boudevilliers', canton: 'NE' },
  { npa: '2046', city: 'Fontaines NE', canton: 'NE' },
  { npa: '2052', city: 'Fontainemelon', canton: 'NE' },
  { npa: '2052', city: 'La Vue-des-Alpes', canton: 'NE' },
  { npa: '2053', city: 'Cernier', canton: 'NE' },
  { npa: '2054', city: 'Chézard-St-Martin', canton: 'NE' },
  { npa: '2054', city: 'Les Vieux-Prés', canton: 'NE' },
  { npa: '2056', city: 'Dombresson', canton: 'NE' },
  { npa: '2057', city: 'Villiers', canton: 'NE' },
  { npa: '2058', city: 'Le Pâquier NE', canton: 'NE' },
  { npa: '2063', city: 'Vilars NE', canton: 'NE' },
  { npa: '2063', city: 'Engollon', canton: 'NE' },
  { npa: '2063', city: 'Fenin', canton: 'NE' },
  { npa: '2063', city: 'Saules', canton: 'NE' },
  { npa: '2065', city: 'Savagnier', canton: 'NE' },
  { npa: '2206', city: 'Les Geneveys-sur-Coffrane', canton: 'NE' },
  { npa: '2207', city: 'Coffrane', canton: 'NE' },
  { npa: '2208', city: 'Les Hauts-Geneveys', canton: 'NE' },
  { npa: '2117', city: 'La Côte-aux-Fées', canton: 'NE' },
  { npa: '2126', city: 'Les Verrières', canton: 'NE' },
  { npa: '2103', city: 'Noiraigue', canton: 'NE' },
  { npa: '2105', city: 'Travers', canton: 'NE' },
  { npa: '2108', city: 'Couvet', canton: 'NE' },
  { npa: '2112', city: 'Môtiers NE', canton: 'NE' },
  { npa: '2113', city: 'Boveresse', canton: 'NE' },
  { npa: '2114', city: 'Fleurier', canton: 'NE' },
  { npa: '2115', city: 'Buttes', canton: 'NE' },
  { npa: '2116', city: 'Mont-de-Buttes', canton: 'NE' },
  { npa: '2123', city: 'St-Sulpice NE', canton: 'NE' },
  { npa: '2124', city: 'Les Sagnettes', canton: 'NE' },
  { npa: '2127', city: 'Les Bayards', canton: 'NE' },
  { npa: '1288', city: 'Aire-la-Ville', canton: 'GE' },
  { npa: '1247', city: 'Anières', canton: 'GE' },
  { npa: '1237', city: 'Avully', canton: 'GE' },
  { npa: '1285', city: 'Athenaz (Avusy)', canton: 'GE' },
  { npa: '1257', city: 'La Croix-de-Rozon', canton: 'GE' },
  { npa: '1293', city: 'Bellevue', canton: 'GE' },
  { npa: '1233', city: 'Bernex', canton: 'GE' },
  { npa: '1227', city: 'Carouge GE', canton: 'GE' },
  { npa: '1236', city: 'Cartigny', canton: 'GE' },
  { npa: '1298', city: 'Céligny', canton: 'GE' },
  { npa: '1298', city: 'Céligny', canton: 'GE' },
  { npa: '1284', city: 'Chancy', canton: 'GE' },
  { npa: '1224', city: 'Chêne-Bougeries', canton: 'GE' },
  { npa: '1231', city: 'Conches', canton: 'GE' },
  { npa: '1225', city: 'Chêne-Bourg', canton: 'GE' },
  { npa: '1244', city: 'Choulex', canton: 'GE' },
  { npa: '1239', city: 'Collex', canton: 'GE' },
  { npa: '1222', city: 'Vésenaz', canton: 'GE' },
  { npa: '1245', city: 'Collonge-Bellerive', canton: 'GE' },
  { npa: '1223', city: 'Cologny', canton: 'GE' },
  { npa: '1232', city: 'Confignon', canton: 'GE' },
  { npa: '1246', city: 'Corsier GE', canton: 'GE' },
  { npa: '1283', city: 'La Plaine', canton: 'GE' },
  { npa: '1283', city: 'Dardagny', canton: 'GE' },
  { npa: '1201', city: 'Genève', canton: 'GE' },
  { npa: '1202', city: 'Genève', canton: 'GE' },
  { npa: '1203', city: 'Genève', canton: 'GE' },
  { npa: '1204', city: 'Genève', canton: 'GE' },
  { npa: '1205', city: 'Genève', canton: 'GE' },
  { npa: '1206', city: 'Genève', canton: 'GE' },
  { npa: '1207', city: 'Genève', canton: 'GE' },
  { npa: '1208', city: 'Genève', canton: 'GE' },
  { npa: '1209', city: 'Genève', canton: 'GE' },
  { npa: '1227', city: 'Les Acacias', canton: 'GE' },
  { npa: '1294', city: 'Genthod', canton: 'GE' },
  { npa: '1215', city: 'Genève', canton: 'GE' },
  { npa: '1218', city: 'Le Grand-Saconnex', canton: 'GE' },
  { npa: '1251', city: 'Gy', canton: 'GE' },
  { npa: '1248', city: 'Hermance', canton: 'GE' },
  { npa: '1254', city: 'Jussy', canton: 'GE' },
  { npa: '1287', city: 'Laconnex', canton: 'GE' },
  { npa: '1212', city: 'Grand-Lancy', canton: 'GE' },
  { npa: '1213', city: 'Petit-Lancy', canton: 'GE' },
  { npa: '1252', city: 'Meinier', canton: 'GE' },
  { npa: '1216', city: 'Cointrin', canton: 'GE' },
  { npa: '1217', city: 'Meyrin', canton: 'GE' },
  { npa: '1213', city: 'Onex', canton: 'GE' },
  { npa: '1258', city: 'Perly', canton: 'GE' },
  { npa: '1228', city: 'Plan-les-Ouates', canton: 'GE' },
  { npa: '1292', city: 'Chambésy', canton: 'GE' },
  { npa: '1243', city: 'Presinge', canton: 'GE' },
  { npa: '1241', city: 'Puplinge', canton: 'GE' },
  { npa: '1281', city: 'Russin', canton: 'GE' },
  { npa: '1242', city: 'Satigny', canton: 'GE' },
  { npa: '1286', city: 'Soral', canton: 'GE' },
  { npa: '1226', city: 'Thônex', canton: 'GE' },
  { npa: '1256', city: 'Troinex', canton: 'GE' },
  { npa: '1253', city: 'Vandoeuvres', canton: 'GE' },
  { npa: '1214', city: 'Vernier', canton: 'GE' },
  { npa: '1219', city: 'Le Lignon', canton: 'GE' },
  { npa: '1219', city: 'Aïre', canton: 'GE' },
  { npa: '1219', city: 'Châtelaine', canton: 'GE' },
  { npa: '1220', city: 'Les Avanchets', canton: 'GE' },
  { npa: '1290', city: 'Versoix', canton: 'GE' },
  { npa: '1234', city: 'Vessy', canton: 'GE' },
  { npa: '1255', city: 'Veyrier', canton: 'GE' },
  { npa: '2856', city: 'Boécourt', canton: 'JU' },
  { npa: '2857', city: 'Montavon', canton: 'JU' },
  { npa: '2803', city: 'Bourrignon', canton: 'JU' },
  { npa: '2843', city: 'Châtillon JU', canton: 'JU' },
  { npa: '2825', city: 'Courchapoix', canton: 'JU' },
  { npa: '2830', city: 'Courrendlin', canton: 'JU' },
  { npa: '2830', city: 'Vellerat', canton: 'JU' },
  { npa: '2832', city: 'Rebeuvelier', canton: 'JU' },
  { npa: '2822', city: 'Courroux', canton: 'JU' },
  { npa: '2823', city: 'Courcelon', canton: 'JU' },
  { npa: '2852', city: 'Courtételle', canton: 'JU' },
  { npa: '2800', city: 'Delémont', canton: 'JU' },
  { npa: '2802', city: 'Develier', canton: 'JU' },
  { npa: '2813', city: 'Ederswiler', canton: 'JU' },
  { npa: '2827', city: 'Mervelier', canton: 'JU' },
  { npa: '2806', city: 'Mettembert', canton: 'JU' },
  { npa: '2812', city: 'Movelier', canton: 'JU' },
  { npa: '2807', city: 'Pleigne', canton: 'JU' },
  { npa: '2807', city: 'Lucelle', canton: 'JU' },
  { npa: '2842', city: 'Rossemaison', canton: 'JU' },
  { npa: '2873', city: 'Saulcy', canton: 'JU' },
  { npa: '2805', city: 'Soyhières', canton: 'JU' },
  { npa: '2853', city: 'Courfaivre', canton: 'JU' },
  { npa: '2854', city: 'Bassecourt', canton: 'JU' },
  { npa: '2855', city: 'Glovelier', canton: 'JU' },
  { npa: '2863', city: 'Undervelier', canton: 'JU' },
  { npa: '2864', city: 'Soulce', canton: 'JU' },
  { npa: '2824', city: 'Vicques', canton: 'JU' },
  { npa: '2826', city: 'Corban', canton: 'JU' },
  { npa: '2828', city: 'Montsevelier', canton: 'JU' },
  { npa: '2829', city: 'Vermes', canton: 'JU' },
  { npa: '2360', city: 'Le Bémont JU', canton: 'JU' },
  { npa: '2336', city: 'Les Bois', canton: 'JU' },
  { npa: '2345', city: 'Les Breuleux', canton: 'JU' },
  { npa: '2345', city: 'La Chaux-des-Breuleux', canton: 'JU' },
  { npa: '2363', city: 'Les Enfers', canton: 'JU' },
  { npa: '2714', city: 'Les Genevez JU', canton: 'JU' },
  { npa: '2714', city: 'Le Prédame', canton: 'JU' },
  { npa: '2718', city: 'Lajoux JU', canton: 'JU' },
  { npa: '2718', city: 'Fornet-Dessus', canton: 'JU' },
  { npa: '2362', city: 'Montfaucon', canton: 'JU' },
  { npa: '2362', city: 'Montfavergier', canton: 'JU' },
  { npa: '2338', city: 'Les Emibois', canton: 'JU' },
  { npa: '2338', city: 'Muriaux', canton: 'JU' },
  { npa: '2345', city: 'Le Cerneux-Veusil', canton: 'JU' },
  { npa: '2340', city: 'Le Noirmont', canton: 'JU' },
  { npa: '2350', city: 'Saignelégier', canton: 'JU' },
  { npa: '2353', city: 'Les Pommerats', canton: 'JU' },
  { npa: '2354', city: 'Goumois', canton: 'JU' },
  { npa: '2364', city: 'St-Brais', canton: 'JU' },
  { npa: '2882', city: 'St-Ursanne', canton: 'JU' },
  { npa: '2887', city: 'Soubey', canton: 'JU' },
  { npa: '2942', city: 'Alle', canton: 'JU' },
  { npa: '2935', city: 'Beurnevésin', canton: 'JU' },
  { npa: '2926', city: 'Boncourt', canton: 'JU' },
  { npa: '2944', city: 'Bonfol', canton: 'JU' },
  { npa: '2915', city: 'Bure', canton: 'JU' },
  { npa: '2932', city: 'Coeuve', canton: 'JU' },
  { npa: '2952', city: 'Cornol', canton: 'JU' },
  { npa: '2922', city: 'Courchavon', canton: 'JU' },
  { npa: '2950', city: 'Courgenay', canton: 'JU' },
  { npa: '2950', city: 'Courtemautruy', canton: 'JU' },
  { npa: '2905', city: 'Courtedoux', canton: 'JU' },
  { npa: '2933', city: 'Damphreux', canton: 'JU' },
  { npa: '2916', city: 'Fahy', canton: 'JU' },
  { npa: '2902', city: 'Fontenais', canton: 'JU' },
  { npa: '2903', city: 'Villars-sur-Fontenais', canton: 'JU' },
  { npa: '2904', city: 'Bressaucourt', canton: 'JU' },
  { npa: '2908', city: 'Grandfontaine', canton: 'JU' },
  { npa: '2933', city: 'Lugnez', canton: 'JU' },
  { npa: '2900', city: 'Porrentruy', canton: 'JU' },
  { npa: '2943', city: 'Vendlincourt', canton: 'JU' },
  { npa: '2923', city: 'Courtemaîche', canton: 'JU' },
  { npa: '2924', city: 'Montignez', canton: 'JU' },
  { npa: '2925', city: 'Buix', canton: 'JU' },
  { npa: '2882', city: 'St-Ursanne', canton: 'JU' },
  { npa: '2883', city: 'Montmelon', canton: 'JU' },
  { npa: '2884', city: 'Montenol', canton: 'JU' },
  { npa: '2885', city: 'Epauvillers', canton: 'JU' },
  { npa: '2886', city: 'Epiquerez', canton: 'JU' },
  { npa: '2888', city: 'Seleute', canton: 'JU' },
  { npa: '2889', city: 'Ocourt', canton: 'JU' },
  { npa: '2906', city: 'Chevenez', canton: 'JU' },
  { npa: '2907', city: 'Rocourt', canton: 'JU' },
  { npa: '2912', city: 'Réclère', canton: 'JU' },
  { npa: '2912', city: "Roche-d'Or", canton: 'JU' },
  { npa: '2914', city: 'Damvant', canton: 'JU' },
  { npa: '2946', city: 'Miécourt', canton: 'JU' },
  { npa: '2947', city: 'Charmoille', canton: 'JU' },
  { npa: '2953', city: 'Fregiécourt', canton: 'JU' },
  { npa: '2953', city: 'Pleujouse', canton: 'JU' },
  { npa: '2954', city: 'Asuel', canton: 'JU' },
  { npa: '9490', city: 'Vaduz', canton: 'LI' },
  { npa: '9495', city: 'Triesen', canton: 'LI' },
  { npa: '9496', city: 'Balzers', canton: 'LI' },
  { npa: '9497', city: 'Triesenberg', canton: 'LI' },
  { npa: '9494', city: 'Schaan', canton: 'LI' },
  { npa: '9498', city: 'Planken', canton: 'LI' },
  { npa: '9485', city: 'Nendeln', canton: 'LI' },
  { npa: '9492', city: 'Eschen', canton: 'LI' },
  { npa: '9486', city: 'Schaanwald', canton: 'LI' },
  { npa: '9493', city: 'Mauren FL', canton: 'LI' },
  { npa: '9487', city: 'Gamprin-Bendern', canton: 'LI' },
  { npa: '9491', city: 'Ruggell', canton: 'LI' },
  { npa: '9488', city: 'Schellenberg', canton: 'LI' },
  { npa: '9999', city: 'Thunersee', canton: 'BE' },
  { npa: '9999', city: 'Brienzersee', canton: 'BE' },
  { npa: '9999', city: 'Bielersee', canton: 'BE' },
];

export default npa;
