import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function LineTypeCell({ description }) {
  const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    // textAlign: 'center',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  }));

  const theme = useTheme();
  const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Card
      sx={{
        height: '100%',
        display: 'fley',
        // alignItems: 'center',
        // justifyContent: 'center',
        color: isDesktopMode ? null : '#ffffff',
        backgroundColor: isDesktopMode ? '#ddd' : '#666',
      }}
    >
      <CardContent>
        <Item>
          <Typography variant="h5" gutterBottom component="div" sx={{ fontStyle: 'italic', fontWeight: 600 }}>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Typography>
        </Item>
      </CardContent>
    </Card>
  );
}
